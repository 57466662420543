// Font
$font-body: 'Open Sans', Arial, Verdana, sans-serif;
$font-heading: 'Oswald', 'Open Sans', Arial, Verdana, sans-serif;

// Color
$white: #ffffff;
$green: #25c389;
$blue: #16242f;
$blue-light: #1691bd;
$yellow: #ffb901;
$red: #de6262;
$pink: #f5699a;
$purple: #76389b;
$organe: #f4943e;
$link: #279fdc;
$hover: #dd2900;

$hotline: #ff2e00;

$line1-origin: #fd4682;
$line2-origin: #fde642;
$line3-origin: #54b9fd;
$linea-origin: #1a181d;

$line1-hnadp: #ff7400;
$line2-hnadp: #62ee44;
$line3-hnadp: #8d472a;
$linea-hnadp: #fff;

$line1: $line2-hnadp;
$line2: $line3-hnadp;
$line3: $line1-hnadp;
$linea: $linea-hnadp;

// Size
$font-size: 16px;
$icon-size: 32px;
$favicon-size: 16px;

@mixin font-size($size) {
	font-size: $size + px;
	font-size: ($size / 10) + rem;
}

// Layout
$gutter: 16px;
$column: $gutter * 3;
$cwidth: ($column + $gutter) * 12 - $gutter;
@charset "utf-8";

html {
  //font-size: 10px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: $font-body;
  font-size: 100%;
  line-height: 1.4;
  color: $blue;
}

h1, h2, h3, h4, h4, h6 {
  font-family: $font-heading;
}

a, a:visited {
  color: $link;
  text-decoration: none;
}

a:hover, a:active {
  color: $hover;
  text-decoration: none;
}

.rollover:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.inner {
  max-width: 1260px;
  padding: 0 10px;
  margin: 0 auto;
}

.cf:after {
  content: "";
  clear: both;
  display: block;
}

pull-left {
  float: left;
}

pull-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.pc {
  display: none;
}

@media screen and (min-width: 769px) {

  .pc {
    display: block;
  }
  .sp {
    display: none;
  }

}

.vc_col-logo > {
  .vc_col-sm-2 {
    width: 10%;
    @media screen and (max-width: 768px) {
      width: 10%;
    }
  }
}

.fullwidthbanner-container .fullwidthabanner {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
}

.sc_item_title {
  .logo-line {
    position: relative;
    display: block;
    width: 300px;
    height: 20px;
    margin: 5px auto 0;
    background: transparent url(../img/logo-line.png) no-repeat center;
    background-size: auto 100%;

    &:after, &:before {
      position: absolute;
      content: "";
      height: 2px;
      width: 120px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #1a181d;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    @media screen and (max-width: 459px) {
      width: 275px;
    }
  }
}

.scheme_default .sc_layouts_logo .logo_text, .scheme_default .trx_addons_scroll_to_top {
  color: $linea;
}

.scheme_default .post_featured.hover_shop .icons a {
  color: $linea;
}

.breadcrumbs {
  text-transform: capitalize;

  .breadcrumbs_item {
    text-transform: capitalize;
  }
}

.posts_container.columns_wrap {
  & > .content {
    @media screen and (max-width: 768px) {
      padding-right: 30px;
    }
  }
}

#our-features-box {
  .feature-box {
    text-align: center;
    margin-bottom: 20px;

    .feature-box__ttl {
      font-family: Oswald, "Open Sans", Arial, Verdana, sans-serif;
      font-size: 1.38em;
      font-weight: 500;
      line-height: 1.87;
      color: #1a181d;
      margin: 5px 0;
    }

    .feature-box__content {
      font-size: 1em;
    }
  }
}
body {
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  line-height: 26px;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0px;
}

p, ul, ol, dl, blockquote, address {
  margin-top: 0em;
  margin-bottom: 1.53em;
}

h1, .front_page_section_caption {
  font-size: 3.529em;
  font-weight: 700;
  font-style: normal;
  line-height: 1.15em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0px;
  margin-top: 9.3rem;
  margin-bottom: 3.2rem;
}

h2 {
  font-size: 2.824em;
  font-weight: 700;
  font-style: normal;
  line-height: 1.17em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0px;
  margin-top: 9.4rem;
  margin-bottom: 3.35rem;
}

h3 {
  font-size: 2.118em;
  font-weight: 700;
  font-style: normal;
  line-height: 1.26em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0px;
  margin-top: 9.35rem;
  margin-bottom: 2.4rem;
}

h4 {
  font-size: 1.765em;
  font-weight: 700;
  font-style: normal;
  line-height: 1.2em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0px;
  margin-top: 9.6rem;
  margin-bottom: 1.9rem;
}

h5 {
  font-size: 1.412em;
  font-weight: 700;
  font-style: normal;
  line-height: 1.21em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0px;
  margin-top: 9.7rem;
  margin-bottom: 1.8rem;
}

h6 {
  font-size: 1.059em;
  font-weight: 600;
  font-style: normal;
  line-height: 1.44em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0px;
  margin-top: 9.85rem;
  margin-bottom: 1.05rem;
}

input {
  &[type="text"], &[type="number"], &[type="email"], &[type="tel"], &[type="search"], &[type="password"] {
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
  }
}

textarea {
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0px;
  &.wp-editor-area {
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
  }
}

.select_container, select, .select_container select {
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0px;
}

button {
  font-size: 0.941em;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.8px;
}

input {
  &[type="button"], &[type="reset"], &[type="submit"] {
    font-size: 0.941em;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.8px;
  }
}

.theme_button, .gallery_preview_show .post_readmore, .post_item .more-link, div.esg-filter-wrapper .esg-filterbutton > span, .mptt-navigation-tabs li a, .alpha_color_tabs .alpha_color_tabs_titles li a {
  font-size: 0.941em;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.8px;
}

.top_panel .slider_engine_revo .slide_title, blockquote, mark, ins, .logo_text, .post_price.price, .theme_scroll_down {
}

.post_meta {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.3px;
  margin-top: 0.4em;
}

.post-date, .rss-date .post_date, .post_meta_item, .post_counters_item {
}

.comments_list_wrap {
  .comment_date, .comment_time, .comment_counters {
  }
}

.top_panel .slider_engine_revo .slide_subtitle, .logo_slogan, fieldset legend, figure figcaption {
}

.wp-caption {
  .wp-caption-text, .wp-caption-dd {
  }
}

.wp-caption-overlay .wp-caption {
  .wp-caption-text, .wp-caption-dd {
  }
}

.format-audio .post_featured .post_audio_author, .trx_addons_audio_player .audio_author, .post_item_single .post_content .post_meta, .author_bio .author_link {
}

.comments_list_wrap {
  .comment_posted, .comment_reply {
  }
}

.search_wrap .search_results {
  .post_meta_item, .post_counters_item {
  }
}

.logo_text {
  font-size: 2.118em;
  font-weight: 700;
  font-style: normal;
  line-height: 1.25em;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0px;
}

.logo_footer_text {
}

.menu_main_nav_area {
  font-size: 17px;
  line-height: 1.5em;
}

.menu_main_nav > li {
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.8px;
  > a {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.8px;
  }
  ul {
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.8px;
    > li {
      font-size: 17px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.5em;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 0.8px;
      > a {
        font-size: 17px;
        font-weight: 600;
        font-style: normal;
        line-height: 1.5em;
        text-decoration: none;
        text-transform: none;
        letter-spacing: 0.8px;
      }
    }
  }
}

.menu_mobile .menu_mobile_nav_area > ul > li {
  > a, li > a {
  }
}

.sc_layouts_row {
  input[type="text"] {
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5em;
  }
  .sc_button {
    font-size: 0.941em;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.8px;
  }
}

.sc_layouts_menu_nav > li {
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  > a {
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.8px;
  }
}

.sc_layouts_menu_popup .sc_layouts_menu_nav > li {
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.8px;
  > a {
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.8px;
  }
}

.sc_layouts_menu_nav > li ul {
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.8px;
  > li {
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.8px;
    > a {
      font-size: 17px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.5em;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 0.8px;
    }
  }
}

.sc_skills_pie.sc_skills_compact_off .sc_skills_item_title, .sc_dishes_compact .sc_services_item_title, .sc_services_iconed .sc_services_item_title, .toc_menu_item .toc_menu_description, .sc_recent_news .post_item .post_footer .post_counters .post_counters_item, .sc_item_subtitle.sc_item_title_style_shadow, .sc_icons_item_title, .sc_price_item_title, .sc_price_item_price {
}

.sc_courses_default {
  .sc_courses_item_price, .trx_addons_hover_content .trx_addons_hover_links a {
  }
}

.sc_promo_modern .sc_promo_link2 span + span, .sc_skills_counter .sc_skills_total, .sc_skills_pie.sc_skills_compact_off .sc_skills_total, .slider_container .slide_info.slide_info_large .slide_title, .slider_style_modern .slider_controls_label span + span, .slider_pagination_wrap, .sc_slider_controller_info, .sc_item_subtitle, .sc_recent_news .post_item .post_meta, .sc_action_item_description, .sc_price_item_description, .sc_price_item_details, .sc_courses_default .sc_courses_item_date, .courses_single .courses_page_meta, .sc_promo_modern .sc_promo_link2 span, .sc_skills_counter .sc_skills_item_title, .slider_style_modern .slider_controls_label span {
}

.slider_titles_outside_wrap {
  .slide_cats, .slide_subtitle {
  }
}

.sc_team .sc_team_item_subtitle, .sc_dishes .sc_dishes_item_subtitle, .sc_services .sc_services_item_subtitle, .team_member_page .team_member_brief_info_text, .sc_testimonials_item_author_title, .sc_testimonials_item_content:before {
}

.sc_button, .sc_button_simple, .sc_form button {
  font-size: 0.941em;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.8px;
}

.sc_promo_modern .sc_promo_link2 {
}

.properties_search_form .properties_search_basic, .cars_search_form .cars_search_basic, .sc_promo_modern .sc_promo_link2 {
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.sc_slider_controls {
  .slider_controls_wrap > a, .slider_progress, .slider_progress_bar {
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
  }
}

.slider_container.slider_controls_side .slider_controls_wrap > a, .slider_outer_controls_side .slider_controls_wrap > a, .slider_outer_controls_top .slider_controls_wrap > a, .slider_outer_controls_bottom .slider_controls_wrap > a {
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.sc_button, .sc_form button, .sc_matches_item_pair .sc_matches_item_player .post_featured > img {
  -webkit-border-radius: 35px;
  -ms-border-radius: 35px;
  border-radius: 35px;
}

.trx_addons_scroll_to_top, .socials_wrap .social_item .social_icon {
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.sc_matches_other {
  .sc_matches_item_logo1 img, .sc_matches_item_logo2 img {
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }
}

.sc_points_table .sc_points_table_logo img {
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.gyges {
  .tp-tab-date, .tp-tab-title {
  }
}

.sc_team_short .sc_team_item_title, .format-audio .post_featured .post_audio_title, .trx_addons_audio_player .audio_caption {
}

blockquote > {
  a, p > a, cite, p > cite {
  }
}

body .mejs-container *, .sc_testimonials_item_content, .sc_services_light .sc_services_item_title, .sc_item_subtitle, .sc_price_item_price_value em, .sc_skills_pie.sc_skills_compact_off .sc_skills_total, .sc_edd_details .downloads_page_tags .downloads_page_data > a, .widget_product_tag_cloud a, .widget_tag_cloud a, .widget_calendar caption {
}

blockquote {
  p, &:before {
  }
}

.trx_addons_dropcap {
}

.mc4wp-form .mc4wp-form-fields input {
  &[type="email"], &[type="submit"] {
    -webkit-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
  }
}

.woocommerce {
  .checkout table.shop_table .product-name .variation, .shop_table.order_details td.product-name .variation, div.product .woocommerce-variation-price span.price, ul.products li.product .post_header {
  }
}

.woocommerce-page ul.products li.product .post_header, .single-product div.product .woocommerce-tabs .wc-tabs li a {
}

.woocommerce {
  .shop_table th, span.onsale, div.product .summary .stock, #reviews #comments ol.commentlist li .comment-text p.meta strong {
  }
}

.woocommerce-page #reviews #comments ol.commentlist li .comment-text p.meta strong, .woocommerce table.cart td.product-name a, .woocommerce-page table.cart td.product-name a, .woocommerce #content table.cart td.product-name a, .woocommerce-page #content table.cart td.product-name a {
}

.woocommerce {
  .checkout table.shop_table .product-name, .shop_table.order_details td.product-name, .order_details li strong {
  }
}

.woocommerce-MyAccount-navigation, .woocommerce-MyAccount-content .woocommerce-Address-title a {
}

.woocommerce {
  ul.products li.product .button, div.product form.cart .button, .woocommerce-message .button, #review_form #respond p.form-submit input[type="submit"] {
    font-size: 0.941em;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.8px;
  }
}

.woocommerce-page #review_form #respond p.form-submit input[type="submit"] {
  font-size: 0.941em;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.8px;
}

.woocommerce {
  table.my_account_orders .order-actions .button, .button {
    font-size: 0.941em;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.8px;
  }
}

.woocommerce-page .button {
  font-size: 0.941em;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.8px;
}

.woocommerce {
  a.button, button.button {
    font-size: 0.941em;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.8px;
  }
  input {
    &.button.woocommerce #respond input#submit, &[type="button"] {
      font-size: 0.941em;
      font-weight: 600;
      font-style: normal;
      line-height: 1.5em;
      text-decoration: none;
      text-transform: none;
      letter-spacing: 0.8px;
    }
  }
}

.woocommerce-page input[type="button"], .woocommerce input[type="submit"], .woocommerce-page input[type="submit"] {
  font-size: 0.941em;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.8px;
}

.woocommerce {
  ul.products li.product .post_header .post_tags {
  }
  div.product {
    .product_meta span > {
      a, span {
      }
    }
    form.cart .reset_variations {
    }
  }
  #reviews #comments ol.commentlist li .comment-text p.meta time {
  }
}

.woocommerce-page #reviews #comments ol.commentlist li .comment-text p.meta time {
}

.woocommerce .button, .woocommerce-page .button {
  -webkit-border-radius: 35px;
  -ms-border-radius: 35px;
  border-radius: 35px;
}

.woocommerce {
  a.button, button.button {
    -webkit-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
  }
  input {
    &.button.woocommerce #respond input#submit, &[type="button"] {
      -webkit-border-radius: 35px;
      -ms-border-radius: 35px;
      border-radius: 35px;
    }
  }
}

.woocommerce-page input[type="button"], .woocommerce input[type="submit"], .woocommerce-page input[type="submit"] {
  -webkit-border-radius: 35px;
  -ms-border-radius: 35px;
  border-radius: 35px;
}

.woocommerce {
  .woocommerce-message .button, ul.products li.product .button, div.product form.cart .button, #review_form #respond p.form-submit input[type="submit"] {
    -webkit-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
  }
}

.woocommerce-page #review_form #respond p.form-submit input[type="submit"], .woocommerce table.my_account_orders .order-actions .button, .yith-woocompare-widget a.clear-all, .single-product div.product .woocommerce-tabs .wc-tabs li a, .widget.WOOCS_SELECTOR .woocommerce-currency-switcher-form .chosen-container-single .chosen-single {
  -webkit-border-radius: 35px;
  -ms-border-radius: 35px;
  border-radius: 35px;
}

.woocommerce div.product form.cart div.quantity span.q_inc, .woocommerce-page div.product form.cart div.quantity span.q_inc, .woocommerce .shop_table.cart div.quantity span.q_inc, .woocommerce-page .shop_table.cart div.quantity span.q_inc {
  -webkit-border-radius: 0 35px 0 0;
  -ms-border-radius: 0 35px 0 0;
  border-radius: 0 35px 0 0;
}

.woocommerce div.product form.cart div.quantity span.q_dec, .woocommerce-page div.product form.cart div.quantity span.q_dec, .woocommerce .shop_table.cart div.quantity span.q_dec, .woocommerce-page .shop_table.cart div.quantity span.q_dec {
  -webkit-border-radius: 0 0 35px 0;
  -ms-border-radius: 0 0 35px 0;
  border-radius: 0 0 35px 0;
}

.vc_tta.vc_tta-accordion .vc_tta-panel-title .vc_tta-title-text, body.wpb-js-composer .vc_tta.vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab > a, .vc_progress_bar.vc_progress_bar_narrow .vc_single_bar .vc_label .vc_label_units {
}

button {
  -webkit-border-radius: 35px;
  -ms-border-radius: 35px;
  border-radius: 35px;
}

input {
  &[type="button"], &[type="reset"], &[type="submit"] {
    -webkit-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
  }
}

.theme_button, .post_item .post_item .more-link, .gallery_preview_show .post_readmore {
  -webkit-border-radius: 35px;
  -ms-border-radius: 35px;
  border-radius: 35px;
}

input {
  &[type="text"], &[type="number"], &[type="email"], &[type="tel"], &[type="password"], &[type="search"] {
    -webkit-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
  }
}

select, .select_container, textarea, .widget_search .search-field, .woocommerce.widget_product_search .search_field, .widget_display_search #bbp_search, #bbpress-forums #bbp-search-form #bbp_search {
  -webkit-border-radius: 35px;
  -ms-border-radius: 35px;
  border-radius: 35px;
}

.comments_wrap .comments_field {
  input, textarea {
    -webkit-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
  }
}

.select2-container.select2-container--default span {
  &.select2-choice, &.select2-selection {
    -webkit-border-radius: 35px;
    -ms-border-radius: 35px;
    border-radius: 35px;
  }
}

.select_container:before {
  -webkit-border-radius: 0 35px 35px 0;
  -ms-border-radius: 0 35px 35px 0;
  border-radius: 0 35px 35px 0;
}

textarea.wp-editor-area {
  -webkit-border-radius: 0 0 35px 35px;
  -ms-border-radius: 0 0 35px 35px;
  border-radius: 0 0 35px 35px;
}

/* Radius 50% or 0 */

.widget li {
  a > img, span > img {
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }
}

.scheme_default {
  body {
    background-color: #fff;
  }
  color: #737a80;
  h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, li a {
    color: #1a181d;
  }
  [class*="color_style_"] {
    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, li a {
      color: #1a181d;
    }
  }
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, li a:hover {
    color: $line3;
  }
  .color_style_link2 {
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, li a:hover {
      color: $line1;
    }
  }
  .color_style_link3 {
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, li a:hover {
      color: $line3;
    }
  }
  .color_style_dark {
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, li a:hover {
      color: $line2;
    }
  }
  b, strong {
    color: #1a181d;
  }
  s, strike, del {
    color: #8b8f95;
  }
  code {
    color: #333;
    background-color: #f8f8f8;
    border-color: #e5e5e5;
    a {
      color: $line3;
      &:hover {
        color: #72cfd5;
      }
    }
  }
  a {
    color: #1a181d;
    &:hover {
      color: $line3;
    }
  }
  .color_style_link2 a {
    color: $line1;
    &:hover {
      color: $line3;
    }
  }
  .color_style_link3 a {
    color: $line3;
    &:hover {
      color: $line2;
    }
  }
  .color_style_dark a {
    color: #1a181d;
    &:hover {
      color: $line2;
    }
  }
  blockquote {
    color: #fff;
    background-color: #4eb8ff;
    &:before {
      color: #fff;
    }
    a {
      color: #fff;
      &:hover {
        color: #fe7259;
      }
    }
  }
  table {
    th {
      border-color: rgba(255, 255, 255, 0.2);
      + th {
        border-color: rgba(255, 255, 255, 0.2);
      }
    }
    td + th {
      border-color: rgba(255, 255, 255, 0.2);
    }
    tr:last-child td, td, th + td, td + td {
      color: #737a80;
      border-color: #e5e5e5;
    }
    th {
      color: #fff;
      background-color: $line3;
      border-color: rgba(255, 255, 255, 0.2);
      b, strong, a:hover {
        color: #fff;
      }
    }
    > tbody > tr {
      &:nth-child(2n+1) > td {
        background-color: #f8f8f8;
      }
      &:nth-child(2n) > td {
        background-color: #fff;
      }
    }
  }
  hr {
    border-color: #e5e5e5;
  }
  figure figcaption {
    color: #737a80;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .wp-caption {
    .wp-caption-text, .wp-caption-dd {
      color: #737a80;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
  .wp-caption-overlay .wp-caption {
    .wp-caption-text, .wp-caption-dd {
      color: #737a80;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
  ul > li:before {
    color: $line2;
  }
  .widget_search form:after, .woocommerce.widget_product_search form:after, .widget_display_search form:after, #bbpress-forums #bbp-search-form:after {
    color: #9a9d9f;
  }
  .widget_search form:hover:after, .woocommerce.widget_product_search form:hover:after, .widget_display_search form:hover:after, #bbpress-forums #bbp-search-form:hover:after {
    color: #15212c;
  }
  fieldset {
    border-color: #e5e5e5;
    legend {
      color: #1a181d;
      background-color: #fff;
    }
  }
  input {
    &[type="text"], &[type="number"], &[type="email"], &[type="tel"], &[type="search"], &[type="password"] {
      color: #9a9d9f;
      border-color: #f4f6fa;
      background-color: #f4f6fa;
    }
  }
  .select_container {
    color: #9a9d9f;
    border-color: #f4f6fa;
    background-color: #f4f6fa;
  }
  .select2-container.select2-container--default {
    span {
      &.select2-choice, &.select2-selection {
        color: #9a9d9f;
        border-color: #f4f6fa;
        background-color: #f4f6fa;
      }
    }
    .select2-selection--single .select2-selection__rendered, .select2-selection--multiple {
      color: #9a9d9f;
      border-color: #f4f6fa;
      background-color: #f4f6fa;
    }
  }
  textarea {
    color: #9a9d9f;
    border-color: #f4f6fa;
    background-color: #f4f6fa;
    &.wp-editor-area {
      color: #9a9d9f;
      border-color: #f4f6fa;
      background-color: #f4f6fa;
    }
  }
  #buddypress {
    .dir-search input {
      &[type="search"], &[type="text"] {
        color: #9a9d9f;
        border-color: #f4f6fa;
        background-color: #f4f6fa;
      }
    }
    .groups-members-search input {
      &[type="search"], &[type="text"] {
        color: #9a9d9f;
        border-color: #f4f6fa;
        background-color: #f4f6fa;
      }
    }
    .standard-form {
      input {
        &[type="color"], &[type="date"], &[type="datetime-local"], &[type="datetime"], &[type="email"], &[type="month"], &[type="number"], &[type="password"], &[type="range"], &[type="search"], &[type="tel"], &[type="text"], &[type="time"], &[type="url"], &[type="week"] {
          color: #9a9d9f;
          border-color: #f4f6fa;
          background-color: #f4f6fa;
        }
      }
      select, textarea {
        color: #9a9d9f;
        border-color: #f4f6fa;
        background-color: #f4f6fa;
      }
    }
    form#whats-new-form textarea {
      color: #9a9d9f;
      border-color: #f4f6fa;
      background-color: #f4f6fa;
    }
  }
  #booked-page-form {
    input {
      &[type="email"], &[type="text"], &[type="password"] {
        color: #9a9d9f;
        border-color: #f4f6fa;
        background-color: #f4f6fa;
      }
    }
    textarea {
      color: #9a9d9f;
      border-color: #f4f6fa;
      background-color: #f4f6fa;
    }
  }
  .booked-upload-wrap {
    color: #9a9d9f;
    border-color: #f4f6fa;
    background-color: #f4f6fa;
    input {
      color: #9a9d9f;
      border-color: #f4f6fa;
      background-color: #f4f6fa;
    }
  }
  input {
    &[type="text"]:focus, &[type="number"]:focus, &[type="email"]:focus, &[type="tel"]:focus, &[type="search"]:focus, &[type="password"]:focus {
      color: #15212c;
      border-color: #d9dce1;
      background-color: #fff;
    }
  }
  .select_container:hover {
    color: #15212c;
    border-color: #d9dce1;
    background-color: #fff;
  }
  select option {
    &:hover, &:focus {
      color: #15212c;
      border-color: #d9dce1;
      background-color: #fff;
    }
  }
  .select2-container {
    &.select2-container--default span.select2-choice:hover, &.select2-container--focus span.select2-choice, &.select2-container--open span.select2-choice, &.select2-container--focus span.select2-selection--single .select2-selection__rendered, &.select2-container--open span.select2-selection--single .select2-selection__rendered, &.select2-container--default span.select2-selection--multiple:hover, &.select2-container--focus span.select2-selection--multiple, &.select2-container--open span.select2-selection--multiple {
      color: #15212c;
      border-color: #d9dce1;
      background-color: #fff;
    }
  }
  textarea {
    &:focus, &.wp-editor-area:focus {
      color: #15212c;
      border-color: #d9dce1;
      background-color: #fff;
    }
  }
  #buddypress {
    .dir-search input {
      &[type="search"]:focus, &[type="text"]:focus {
        color: #15212c;
        border-color: #d9dce1;
        background-color: #fff;
      }
    }
    .groups-members-search input {
      &[type="search"]:focus, &[type="text"]:focus {
        color: #15212c;
        border-color: #d9dce1;
        background-color: #fff;
      }
    }
    .standard-form {
      input {
        &[type="color"]:focus, &[type="date"]:focus, &[type="datetime-local"]:focus, &[type="datetime"]:focus, &[type="email"]:focus, &[type="month"]:focus, &[type="number"]:focus, &[type="password"]:focus, &[type="range"]:focus, &[type="search"]:focus, &[type="tel"]:focus, &[type="text"]:focus, &[type="time"]:focus, &[type="url"]:focus, &[type="week"]:focus {
          color: #15212c;
          border-color: #d9dce1;
          background-color: #fff;
        }
      }
      select:focus, textarea:focus {
        color: #15212c;
        border-color: #d9dce1;
        background-color: #fff;
      }
    }
    form#whats-new-form textarea:focus {
      color: #15212c;
      border-color: #d9dce1;
      background-color: #fff;
    }
  }
  #booked-page-form {
    input {
      &[type="email"]:focus, &[type="text"]:focus, &[type="password"]:focus {
        color: #15212c;
        border-color: #d9dce1;
        background-color: #fff;
      }
    }
    textarea:focus {
      color: #15212c;
      border-color: #d9dce1;
      background-color: #fff;
    }
  }
  .booked-upload-wrap {
    &:hover, input:focus {
      color: #15212c;
      border-color: #d9dce1;
      background-color: #fff;
    }
  }
  input[placeholder]::-webkit-input-placeholder, textarea[placeholder]::-webkit-input-placeholder, input[placeholder]::-moz-placeholder, textarea[placeholder]::-moz-placeholder, input[placeholder]:-ms-input-placeholder, textarea[placeholder]:-ms-input-placeholder, input[placeholder]::placeholder, textarea[placeholder]::placeholder {
    color: #9a9d9f;
  }
  .select_container {
    &:before {
      color: #9a9d9f;
      background-color: #f4f6fa;
    }
    &:focus:before, &:hover:before {
      color: #15212c;
      background-color: #fff;
    }
    &:after {
      color: #9a9d9f;
    }
    &:focus:after, &:hover:after {
      color: #15212c;
    }
    select {
      color: #9a9d9f;
      background: #f4f6fa !important;
      &:focus {
        color: #15212c;
        background-color: #fff !important;
      }
    }
  }
  .select2-dropdown {
    color: #15212c;
    border-color: #d9dce1;
    background: #fff;
  }
  .select2-container {
    &.select2-container--focus span.select2-selection, &.select2-container--open span.select2-selection {
      color: #15212c;
      border-color: #d9dce1;
      background: #fff;
    }
    .select2-results__option {
      color: #15212c;
      background: #fff;
    }
  }
  .select2-dropdown .select2-highlighted, .select2-container .select2-results__option--highlighted[aria-selected] {
    color: #fff;
    background: $line2;
  }
  input {
    &[type="radio"] + label:before, &[type="checkbox"] + label:before {
      border-color: #f4f6fa;
      background-color: #f4f6fa;
    }
  }
  .sc_button_simple {
    &:not(.sc_button_bg_image) {
      color: $line3;
      &:before, &:after {
        color: $line3;
      }
      &.sc_button_with_icon {
        color: #1a181d !important;
      }
      &:hover {
        color: #1a181d !important;
        &:before, &:after {
          color: #1a181d !important;
        }
      }
    }
    &.color_style_link2:not(.sc_button_bg_image) {
      color: $line1;
      &:before, &:after {
        color: $line1;
      }
    }
  }
  .color_style_link2 .sc_button_simple:not(.sc_button_bg_image) {
    color: $line1;
    &:before, &:after {
      color: $line1;
    }
  }
  .sc_button_simple.color_style_link2:not(.sc_button_bg_image):hover {
    color: $line3;
    &:before, &:after {
      color: $line3;
    }
  }
  .color_style_link2 .sc_button_simple:not(.sc_button_bg_image):hover {
    color: $line3;
    &:before, &:after {
      color: $line3;
    }
  }
  .sc_button_simple.color_style_link3:not(.sc_button_bg_image) {
    color: $line3;
    &:before, &:after {
      color: $line3;
    }
  }
  .color_style_link3 .sc_button_simple:not(.sc_button_bg_image) {
    color: $line3;
    &:before, &:after {
      color: $line3;
    }
  }
  .sc_button_simple.color_style_link3:not(.sc_button_bg_image):hover {
    color: $line2;
    &:before, &:after {
      color: $line2;
    }
  }
  .color_style_link3 .sc_button_simple:not(.sc_button_bg_image):hover {
    color: $line2;
    &:before, &:after {
      color: $line2;
    }
  }
  .sc_button_simple.color_style_dark:not(.sc_button_bg_image) {
    color: #1a181d;
    &:before, &:after {
      color: #1a181d;
    }
  }
  .color_style_dark .sc_button_simple:not(.sc_button_bg_image) {
    color: #1a181d;
    &:before, &:after {
      color: #1a181d;
    }
  }
  .sc_button_simple.color_style_dark:not(.sc_button_bg_image):hover {
    color: $line2;
    &:before, &:after {
      color: $line2;
    }
  }
  .color_style_dark .sc_button_simple:not(.sc_button_bg_image):hover {
    color: $line2;
    &:before, &:after {
      color: $line2;
    }
  }
  .sc_button_bordered {
    &:not(.sc_button_bg_image) {
      color: $line2;
      border-color: $line2;
      &:hover {
        color: $line3 !important;
        border-color: $line3 !important;
      }
    }
    &.color_style_link2:not(.sc_button_bg_image) {
      color: $line1;
      border-color: $line1;
      &:hover {
        color: $line3 !important;
        border-color: $line3 !important;
      }
    }
    &.color_style_link3:not(.sc_button_bg_image) {
      color: $line3;
      border-color: $line3;
      &:hover {
        color: $line2 !important;
        border-color: $line2 !important;
      }
    }
    &.color_style_dark:not(.sc_button_bg_image) {
      color: #1a181d;
      border-color: #1a181d;
      &:hover {
        color: $line2 !important;
        border-color: $line2 !important;
      }
    }
  }
  button {
    color: #1a181d;
    background-color: $line2;
  }
  input {
    &[type="reset"], &[type="submit"], &[type="button"] {
      color: #1a181d;
      background-color: $line2;
    }
  }
  .post_item .more-link, .comments_wrap .form-submit input[type="submit"] {
    color: #1a181d;
    background-color: $line2;
  }
  #buddypress {
    .comment-reply-link, .generic-button a, a.button, button {
      color: #1a181d;
      background-color: $line2;
    }
    input {
      &[type="button"], &[type="reset"], &[type="submit"] {
        color: #1a181d;
        background-color: $line2;
      }
    }
    ul.button-nav li a {
      color: #1a181d;
      background-color: $line2;
    }
  }
  a.bp-title-button, .booked-calendar-wrap .booked-appt-list .timeslot .timeslot-people button {
    color: #1a181d;
    background-color: $line2;
  }
  body {
    #booked-profile-page {
      .booked-profile-appt-list .appt-block .booked-cal-buttons .google-cal-button > a, input[type="submit"], button {
        color: #1a181d;
        background-color: $line2;
      }
    }
    .booked-list-view {
      input[type="submit"], button {
        color: #1a181d;
        background-color: $line2;
      }
    }
    table.booked-calendar {
      input[type="submit"], button {
        color: #1a181d;
        background-color: $line2;
      }
    }
    .booked-modal {
      input[type="submit"], button {
        color: #1a181d;
        background-color: $line2;
      }
    }
  }
  .sc_button_default, .sc_button:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image), .socials_share:not(.socials_type_drop) .social_icon {
    color: $linea;
    background-color: $line2;
  }
  #tribe-bar-form {
    .tribe-bar-submit input[type="submit"], &.tribe-bar-mini .tribe-bar-submit input[type="submit"] {
      color: $linea;
      background-color: $line2;
    }
  }
  #tribe-bar-views {
    li.tribe-bar-views-option a, .tribe-bar-views-list .tribe-bar-views-option.tribe-bar-active a {
      color: $linea;
      background-color: $line2;
    }
  }
  #tribe-events .tribe-events-button, .tribe-events-button, .tribe-events-cal-links a, .tribe-events-sub-nav li a, .edd_download_purchase_form .button, #edd-purchase-button, .edd-submit.button, .widget_edd_cart_widget .edd_checkout a, .sc_edd_details .downloads_page_tags .downloads_page_data > a {
    color: $linea;
    background-color: $line2;
  }
  .woocommerce {
    #respond input#submit, .button {
      color: $linea;
      background-color: $line2;
    }
  }
  .woocommerce-page .button, .woocommerce a.button, .woocommerce-page a.button, .woocommerce button.button, .woocommerce-page button.button, .woocommerce input.button, .woocommerce-page input.button, .woocommerce input[type="button"], .woocommerce-page input[type="button"], .woocommerce input[type="submit"], .woocommerce-page input[type="submit"] {
    color: $linea;
    background-color: $line2;
  }
  .woocommerce {
    #respond input#submit.alt, a.button.alt, button.button.alt, input.button.alt {
      color: $linea;
      background-color: $line2;
    }
  }
  .theme_button {
    color: #fff !important;
    background-color: $line2 !important;
    &.color_style_link2 {
      background-color: $line1 !important;
    }
    &.color_style_link3 {
      background-color: $line3 !important;
    }
    &.color_style_dark {
      color: #fff !important;
      background-color: #1a181d !important;
    }
  }
  .sc_price_item_link {
    color: #fff;
    background-color: #fff;
  }
  .sc_button_default.color_style_link2, .sc_button.color_style_link2:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image) {
    background-color: $line1;
  }
  .sc_button_default.color_style_link3, .sc_button.color_style_link3:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image) {
    background-color: $line3;
    color: #fff;
  }
  .sc_button_default.color_style_dark, .sc_button.color_style_dark:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image) {
    color: #fff;
    background-color: #1a181d;
  }
  .search_wrap .search_submit:before {
    color: #9a9d9f;
  }
  button {
    &:hover, &:focus {
      color: #fff;
      background-color: $line3;
    }
  }
  input {
    &[type="submit"] {
      &:hover, &:focus {
        color: #fff;
        background-color: $line3;
      }
    }
    &[type="reset"] {
      &:hover, &:focus {
        color: #fff;
        background-color: $line3;
      }
    }
    &[type="button"] {
      &:hover, &:focus {
        color: #fff;
        background-color: $line3;
      }
    }
  }
  .post_item .more-link:hover {
    color: #fff;
    background-color: $line3;
  }
  .comments_wrap .form-submit input[type="submit"] {
    &:hover, &:focus {
      color: #fff;
      background-color: $line3;
    }
  }
  #buddypress {
    .comment-reply-link:hover, .generic-button a:hover, a.button:hover, button:hover {
      color: #fff;
      background-color: $line3;
    }
    input {
      &[type="button"]:hover, &[type="reset"]:hover, &[type="submit"]:hover {
        color: #fff;
        background-color: $line3;
      }
    }
    ul.button-nav li a:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  a.bp-title-button:hover, .booked-calendar-wrap .booked-appt-list .timeslot .timeslot-people button:hover {
    color: #fff;
    background-color: $line3;
  }
  body {
    #booked-profile-page {
      .booked-profile-appt-list .appt-block .booked-cal-buttons .google-cal-button > a:hover, input[type="submit"]:hover, button:hover {
        color: #fff;
        background-color: $line3;
      }
    }
    .booked-list-view {
      input[type="submit"]:hover, button:hover {
        color: #fff;
        background-color: $line3;
      }
    }
    table.booked-calendar {
      input[type="submit"]:hover, button:hover {
        color: #fff;
        background-color: $line3;
      }
    }
    .booked-modal {
      input[type="submit"]:hover, button:hover {
        color: #fff;
        background-color: $line3;
      }
    }
  }
  .sc_button_default:hover, .sc_button:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover, .socials_share:not(.socials_type_drop) .social_icon:hover {
    color: #fff;
    background-color: $line3;
  }
  #tribe-bar-form {
    .tribe-bar-submit input[type="submit"] {
      &:hover, &:focus {
        color: #fff;
        background-color: $line3;
      }
    }
    &.tribe-bar-mini .tribe-bar-submit input[type="submit"] {
      &:hover, &:focus {
        color: #fff;
        background-color: $line3;
      }
    }
  }
  #tribe-bar-views {
    li.tribe-bar-views-option a:hover, .tribe-bar-views-list .tribe-bar-views-option.tribe-bar-active a:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  #tribe-events .tribe-events-button:hover, .tribe-events-button:hover, .tribe-events-cal-links a:hover, .tribe-events-sub-nav li a:hover {
    color: #fff;
    background-color: $line3;
  }
  .edd_download_purchase_form .button {
    &:hover, &:active, &:focus {
      color: #fff;
      background-color: $line3;
    }
  }
  #edd-purchase-button {
    &:hover, &:active, &:focus {
      color: #fff;
      background-color: $line3;
    }
  }
  .edd-submit.button {
    &:hover, &:active, &:focus {
      color: #fff;
      background-color: $line3;
    }
  }
  .widget_edd_cart_widget .edd_checkout a:hover, .sc_edd_details .downloads_page_tags .downloads_page_data > a:hover {
    color: #fff;
    background-color: $line3;
  }
  .woocommerce {
    #respond input#submit:hover, .button:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  .woocommerce-page .button:hover, .woocommerce a.button:hover, .woocommerce-page a.button:hover, .woocommerce button.button:hover, .woocommerce-page button.button:hover, .woocommerce input.button:hover, .woocommerce-page input.button:hover, .woocommerce input[type="button"]:hover, .woocommerce-page input[type="button"]:hover, .woocommerce input[type="submit"]:hover, .woocommerce-page input[type="submit"]:hover {
    color: #fff;
    background-color: $line3;
  }
  .woocommerce {
    #respond input#submit.alt:hover, a.button.alt:hover, button.button.alt:hover, input.button.alt:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  .theme_button {
    &:hover, &:focus {
      color: $linea !important;
      background-color: #fff04f !important;
    }
    &.color_style_link2:hover {
      background-color: $line3 !important;
    }
    &.color_style_link3:hover {
      background-color: $line2 !important;
    }
    &.color_style_dark:hover {
      color: $linea !important;
      background-color: $line2 !important;
    }
  }
  .sc_price_item:hover .sc_price_item_link, .sc_price_item_link:hover {
    color: #1d1d1d;
    background-color: #fe7259;
  }
  .sc_button_default.color_style_link2:hover, .sc_button.color_style_link2:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover {
    background-color: $line3;
  }
  .sc_button_default.color_style_link3:hover, .sc_button.color_style_link3:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover {
    background-color: $line2;
    color: $linea !important;
  }
  .sc_button_default.color_style_dark:hover, .sc_button.color_style_dark:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover {
    color: $linea;
    background-color: $line2;
  }
  .search_wrap .search_submit:hover:before {
    color: #15212c;
  }
  .woocommerce {
    .woocommerce-message .button, .woocommerce-error .button, .woocommerce-info .button {
      color: #1d1d1d;
      background-color: $line2;
    }
  }
  .widget {
    &.woocommerce {
      .button, a.button, button.button {
        color: #1d1d1d;
        background-color: $line2;
      }
      input {
        &.button, &[type="button"], &[type="submit"] {
          color: #1d1d1d;
          background-color: $line2;
        }
      }
    }
    &.WOOCS_CONVERTER .button, &.yith-woocompare-widget a.button {
      color: #1d1d1d;
      background-color: $line2;
    }
  }
  .widget_product_search .search_button {
    color: #1d1d1d;
    background-color: $line2;
  }
  .mc4wp-form input[type="submit"] {
    &:hover, &:focus {
      color: #1d1d1d !important;
      background-color: $line2;
    }
    color: #fff !important;
    background-color: $line3;
  }
  .woocommerce {
    .woocommerce-message .button:hover, .woocommerce-error .button:hover, .woocommerce-info .button:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  .widget {
    &.woocommerce {
      .button:hover, a.button:hover, button.button:hover {
        color: #fff;
        background-color: $line3;
      }
      input {
        &.button:hover {
          color: #fff;
          background-color: $line3;
        }
        &[type="button"] {
          &:hover, &:focus {
            color: #fff;
            background-color: $line3;
          }
        }
        &[type="submit"] {
          &:hover, &:focus {
            color: #fff;
            background-color: $line3;
          }
        }
      }
    }
    &.WOOCS_CONVERTER .button:hover, &.yith-woocompare-widget a.button:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  .widget_product_search .search_button:hover {
    color: #fff;
    background-color: $line3;
  }
  .wp-editor-container input[type="button"] {
    background-color: #f8f8f8;
    border-color: #e5e5e5;
    color: #1d1d1d;
    -webkit-box-shadow: 0 1px 0 0 #dadada;
    -ms-box-shadow: 0 1px 0 0 #dadada;
    box-shadow: 0 1px 0 0 #dadada;
    &:hover, &:focus {
      background-color: #e6e8eb;
      border-color: #dadada;
      color: $line3;
    }
  }
  .sticky {
    border-color: #e5e5e5;
    .label_sticky {
      border-top-color: $line2;
    }
  }
  .sc_layouts_row, &.sc_layouts_row {
    color: #737a80;
    background-color: #fff;
  }
  .sc_layouts_row_delimiter, &.sc_layouts_row_delimiter, .footer_wrap .sc_layouts_row_delimiter {
    border-color: #e5e5e5;
  }
}

.footer_wrap .scheme_default {
  &.vc_row .sc_layouts_row_delimiter, &.sc_layouts_row_delimiter {
    border-color: #e5e5e5;
  }
}

.scheme_default {
  &.footer_wrap {
    .sc_layouts_row_delimiter {
      border-color: #e5e5e5;
    }
    .sc_layouts_row_type_normal .sc_layouts_item a, .textwidget, .widget ul li a {
      color: #333;
    }
    .sc_layouts_row_type_normal .sc_layouts_item a:hover {
      color: $line3;
    }
  }
  .footer_wrap .widget ul li a:hover {
    color: $line3;
  }
  .sc_layouts_item_icon {
    color: #8b8f95;
  }
  .sc_layouts_item_details_line1 {
    color: $line2;
  }
  .sc_layouts_item_details_line2 {
    color: $hotline;
  }
  span.trx_addons_login_menu {
    color: #333;
    background-color: #f8f8f8;
    border-color: #e5e5e5;
    &:after {
      color: #333;
      background-color: #f8f8f8;
      border-color: #e5e5e5;
    }
    .trx_addons_login_menu_delimiter {
      border-color: #e5e5e5;
    }
    .trx_addons_login_menu_item {
      color: #333;
      &:hover {
        color: #1d1d1d;
        background-color: #e6e8eb;
      }
    }
  }
  .sc_layouts_row_fixed_on {
    background-color: #fff;
  }
  .sc_layouts_row.sc_layouts_row_type_narrow, &.sc_layouts_row.sc_layouts_row_type_narrow {
    color: #333;
    background-color: #f8f8f8;
  }
  .sc_layouts_row_type_narrow .sc_layouts_item, &.sc_layouts_row_type_narrow .sc_layouts_item, .sc_layouts_row_type_narrow .sc_layouts_item a, &.sc_layouts_row_type_narrow .sc_layouts_item a {
    color: #333;
  }
  .sc_layouts_row_type_narrow .sc_layouts_item a:hover, &.sc_layouts_row_type_narrow .sc_layouts_item a:hover {
    color: #1d1d1d;
    .sc_layouts_item_icon {
      color: #1d1d1d;
    }
  }
  .sc_layouts_row_type_narrow .sc_layouts_item_icon, &.sc_layouts_row_type_narrow .sc_layouts_item_icon {
    color: $line3;
  }
  .sc_layouts_row_type_narrow {
    .sc_layouts_item_details_line1, .sc_layouts_item_details_line2 {
      color: #333;
    }
  }
  &.sc_layouts_row_type_narrow {
    .sc_layouts_item_details_line1, .sc_layouts_item_details_line2 {
      color: #333;
    }
  }
  .sc_layouts_row_type_narrow .socials_wrap .social_item .social_icon, &.sc_layouts_row_type_narrow .socials_wrap .social_item .social_icon {
    background-color: transparent;
    color: $line3;
  }
  .sc_layouts_row_type_narrow .socials_wrap .social_item:hover .social_icon, &.sc_layouts_row_type_narrow .socials_wrap .social_item:hover .social_icon {
    background-color: transparent;
    color: #72cfd5;
  }
  .sc_layouts_row_type_narrow .sc_button, &.sc_layouts_row_type_narrow .sc_button {
    background-color: transparent;
    border-color: $line3;
    color: $line3;
  }
  .sc_layouts_row_type_narrow .sc_button:hover, &.sc_layouts_row_type_narrow .sc_button:hover {
    background-color: transparent;
    border-color: #72cfd5;
    color: #72cfd5 !important;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_link2, &.sc_layouts_row_type_narrow .sc_button.color_style_link2 {
    border-color: #8be77c;
    color: #8be77c;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_link2:hover, &.sc_layouts_row_type_narrow .sc_button.color_style_link2:hover {
    border-color: #80d572;
    color: #80d572 !important;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_link3, &.sc_layouts_row_type_narrow .sc_button.color_style_link3 {
    border-color: #eec432;
    color: #eec432;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_link3:hover, &.sc_layouts_row_type_narrow .sc_button.color_style_link2:hover {
    border-color: #ddb837;
    color: #ddb837 !important;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_dark, &.sc_layouts_row_type_narrow .sc_button.color_style_dark {
    border-color: #1d1d1d;
    color: #1d1d1d;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_dark:hover, &.sc_layouts_row_type_narrow .sc_button.color_style_dark:hover {
    border-color: $line3;
    color: $line3 !important;
  }
  .sc_layouts_row_type_narrow .search_wrap .search_submit, &.sc_layouts_row_type_narrow .search_wrap .search_submit {
    background-color: transparent;
    color: $line3;
  }
  .sc_layouts_row_type_narrow .search_wrap .search_field, &.sc_layouts_row_type_narrow .search_wrap .search_field, .sc_layouts_row_type_narrow .search_wrap .search_field::-webkit-input-placeholder, &.sc_layouts_row_type_narrow .search_wrap .search_field::-webkit-input-placeholder, .sc_layouts_row_type_narrow .search_wrap .search_field::-moz-placeholder, &.sc_layouts_row_type_narrow .search_wrap .search_field::-moz-placeholder, .sc_layouts_row_type_narrow .search_wrap .search_field:-ms-input-placeholder, &.sc_layouts_row_type_narrow .search_wrap .search_field:-ms-input-placeholder {
    color: #333;
  }
  .sc_layouts_row_type_narrow .search_wrap .search_field:focus, &.sc_layouts_row_type_narrow .search_wrap .search_field:focus {
    color: #1d1d1d;
  }
  .sc_layouts_row_type_compact .sc_layouts_item, &.sc_layouts_row_type_compact .sc_layouts_item, .sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button), &.sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button) {
    color: #737a80;
  }
  .sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button):hover, &.sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button):hover, .sc_layouts_row_type_compact .sc_layouts_item a:hover .sc_layouts_item_icon, &.sc_layouts_row_type_compact .sc_layouts_item a:hover .sc_layouts_item_icon {
    color: #1a181d;
  }
  .sc_layouts_row_type_compact .sc_layouts_item_icon, &.sc_layouts_row_type_compact .sc_layouts_item_icon {
    color: $line2;
  }
  .sc_layouts_row_type_compact {
    .sc_layouts_item_details_line1, .sc_layouts_item_details_line2 {
      color: #737a80;
    }
  }
  &.sc_layouts_row_type_compact {
    .sc_layouts_item_details_line1, .sc_layouts_item_details_line2 {
      color: #737a80;
    }
  }
  .sc_layouts_row_type_compact .socials_wrap .social_item .social_icon, &.sc_layouts_row_type_compact .socials_wrap .social_item .social_icon {
    background-color: transparent;
    color: #1a181d;
  }
  .sc_layouts_row_type_compact .socials_wrap .social_item:hover .social_icon, &.sc_layouts_row_type_compact .socials_wrap .social_item:hover .social_icon {
    background-color: transparent;
    color: $line3;
  }
  .sc_layouts_row_type_compact .search_wrap .search_submit, &.sc_layouts_row_type_compact .search_wrap .search_submit {
    background-color: transparent;
    color: #1a181d;
  }
  .sc_layouts_row_type_compact .search_wrap .search_submit:hover, &.sc_layouts_row_type_compact .search_wrap .search_submit:hover {
    background-color: transparent;
    color: $line3;
  }
  .sc_layouts_row_type_compact .search_wrap.search_style_normal .search_submit, &.sc_layouts_row_type_compact .search_wrap.search_style_normal .search_submit {
    color: $line2;
  }
  .sc_layouts_row_type_compact .search_wrap.search_style_normal .search_submit:hover, &.sc_layouts_row_type_compact .search_wrap.search_style_normal .search_submit:hover {
    color: $line3;
  }
  .sc_layouts_row_type_compact .search_wrap .search_field::-webkit-input-placeholder, &.sc_layouts_row_type_compact .search_wrap .search_field::-webkit-input-placeholder, .sc_layouts_row_type_compact .search_wrap .search_field::-moz-placeholder, &.sc_layouts_row_type_compact .search_wrap .search_field::-moz-placeholder, .sc_layouts_row_type_compact .search_wrap .search_field:-ms-input-placeholder, &.sc_layouts_row_type_compact .search_wrap .search_field:-ms-input-placeholder, .sc_layouts_row_type_normal .sc_layouts_item, &.sc_layouts_row_type_normal .sc_layouts_item, .sc_layouts_row_type_normal .sc_layouts_item a, &.sc_layouts_row_type_normal .sc_layouts_item a {
    color: #737a80;
  }
  .sc_layouts_row_type_normal .sc_layouts_item a:hover, &.sc_layouts_row_type_normal .sc_layouts_item a:hover, .sc_layouts_row_type_normal .sc_layouts_item a:hover .sc_layouts_item_icon, &.sc_layouts_row_type_normal .sc_layouts_item a:hover .sc_layouts_item_icon {
    color: #1a181d;
  }
  .sc_layouts_row_type_normal .search_wrap .search_submit, &.sc_layouts_row_type_normal .search_wrap .search_submit {
    background-color: transparent;
    color: #9a9d9f;
  }
  .sc_layouts_row_type_normal .search_wrap .search_submit:hover, &.sc_layouts_row_type_normal .search_wrap .search_submit:hover {
    background-color: transparent;
    color: #15212c;
  }
  .sc_layouts_logo {
    b {
      color: #1a181d;
    }
    i {
      color: $line2;
    }
  }
  .sc_layouts_logo_text {
    color: $line2;
  }
  .sc_layouts_logo:hover .logo_text {
    color: #1a181d;
  }
  .logo_slogan {
    color: #737a80;
  }
  .search_style_expand {
    &.search_opened {
      background-color: #fff;
      border-color: #e5e5e5;
      .search_submit {
        color: #737a80;
      }
    }
    .search_submit {
      &:hover, &:focus {
        color: #1a181d;
      }
    }
  }
  .search_style_fullscreen.search_opened {
    .search_form_wrap {
      background-color: rgba(255, 255, 255, 0.9);
    }
    .search_form {
      border-color: #1a181d;
    }
    .search_close, .search_field, .search_submit {
      color: #1a181d;
    }
    .search_close:hover {
      color: #737a80;
    }
    .search_field {
      &:hover, &:focus {
        color: #737a80;
      }
    }
    .search_submit {
      &:hover, &:focus {
        color: #737a80;
      }
    }
    .search_field {
      &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
        color: #8b8f95;
        opacity: 1;
      }
    }
  }
  .search_wrap .search_results {
    background-color: #fff;
    border-color: #e5e5e5;
    &:after {
      background-color: #fff;
      border-left-color: #e5e5e5;
      border-top-color: #e5e5e5;
    }
    .search_results_close {
      color: #8b8f95;
      &:hover {
        color: #1a181d;
      }
    }
  }
  .search_results.widget_area .post_item + .post_item {
    border-top-color: #e5e5e5;
  }
  .sc_layouts_title {
    .sc_layouts_title_meta, .post_meta {
      color: #1a181d;
    }
    .post_meta_item {
      color: #1a181d;
      a, &:before, &:after {
        color: #1a181d;
      }
      &:hover {
        &:before, &:after {
          color: #1a181d;
        }
      }
      &.post_categories {
        color: #1a181d;
        a {
          color: #1a181d;
        }
      }
    }
    .post_date {
      a, &:before, &:after {
        color: #1a181d;
      }
    }
    .post_info .post_info_item {
      color: #1a181d;
      a {
        color: #1a181d;
      }
    }
    .post_info_counters .post_counters_item {
      color: #1a181d;
    }
    .post_counters .socials_share .socials_caption {
      &:before, &:hover:before {
        color: #1a181d;
      }
    }
    .post_date a:hover {
      color: #8b8f95;
    }
    a.post_meta_item:hover {
      color: #8b8f95;
      &:before {
        color: #8b8f95;
      }
    }
    .post_meta_item {
      a:hover {
        color: #8b8f95;
        &:before {
          color: #8b8f95;
        }
      }
      &.post_categories a:hover {
        color: #8b8f95;
      }
    }
    .post_info .post_info_item a:hover, .post_info_counters .post_counters_item:hover {
      color: #8b8f95;
    }
    .sc_layouts_title_description {
      color: #1a181d;
    }
  }
  .sc_layouts_title_breadcrumbs {
    color: #1a181d;
    a {
      color: #1a181d !important;
      &:hover {
        color: #8b8f95 !important;
      }
    }
  }
  .sc_layouts_menu_nav {
    > li {
      > a {
        color: #1a181d;
        &:hover {
          color: $line2 !important;
        }
      }
      &.sfHover > a {
        color: $line2 !important;
      }
      &.current-menu-item > a, &.current-menu-parent > a, &.current-menu-ancestor > a {
        color: #1a181d !important;
      }
    }
    .menu-collapse > a {
      &:before {
        color: #333;
      }
      &:after {
        background-color: #f8f8f8;
      }
      &:hover {
        &:before {
          color: $line3;
        }
        &:after {
          background-color: #e6e8eb;
        }
      }
    }
  }
  .sc_layouts_menu_popup .sc_layouts_menu_nav, .sc_layouts_menu_nav > li ul {
    background-color: #4eb8ff;
  }
  .sc_layouts_menu_popup .sc_layouts_menu_nav > li > a, .sc_layouts_menu_nav > li li > a {
    color: #bfbfbf !important;
  }
  .sc_layouts_menu_popup .sc_layouts_menu_nav > li {
    > a:hover, &.sfHover > a {
      color: #fe7259 !important;
      background-color: #28272e;
    }
  }
  .sc_layouts_menu_nav {
    > li li {
      > a:hover, &.sfHover > a {
        color: #fe7259 !important;
        background-color: #28272e;
      }
    }
    li[class*="columns-"] li.menu-item-has-children {
      > a:hover, &.sfHover > a {
        color: #bfbfbf !important;
        background-color: transparent;
      }
    }
    > li li {
      &[class*="icon-"] {
        &:before, &:hover:before, &.shHover:before {
          color: #fe7259;
        }
      }
      &.current-menu-item > a, &.current-menu-parent > a, &.current-menu-ancestor > a, &.current-menu-item:before, &.current-menu-parent:before, &.current-menu-ancestor:before {
        color: #fe7259 !important;
      }
    }
  }
  &.menu_side_wrap .menu_side_button {
    color: #1d1d1d;
    border-color: #e5e5e5;
    background-color: rgba(248, 248, 248, 0.7);
    &:hover {
      color: #1d1d1d;
      border-color: #72cfd5;
      background-color: $line3;
    }
  }
  .menu_side_inner, .menu_mobile_inner {
    color: #333;
    background-color: #f8f8f8;
  }
  .menu_mobile_button {
    color: #1a181d;
    &:hover {
      color: $line2;
    }
  }
  .menu_mobile_close {
    &:before, &:after {
      border-color: #1d1d1d;
    }
    &:hover {
      &:before, &:after {
        border-color: $line3;
      }
    }
  }
  .menu_mobile_inner {
    a, .menu_mobile_nav_area li:before {
      color: #1d1d1d;
    }
    a:hover, .current-menu-ancestor > a, .current-menu-item > a {
      color: $line3;
    }
    .menu_mobile_nav_area li {
      &:hover:before, &.current-menu-ancestor:before, &.current-menu-item:before {
        color: $line3;
      }
    }
    .search_mobile .search_submit {
      color: #9a9d9f;
      &:focus, &:hover {
        color: #15212c;
      }
    }
    .social_item {
      .social_icon {
        color: $line3;
      }
      &:hover .social_icon {
        color: #1d1d1d;
      }
    }
  }
  .menu_hover_fade_box .sc_layouts_menu_nav > {
    a:hover {
      color: $line3;
      background-color: #f8f8f8;
    }
    li {
      > a:hover, &.sfHover > a {
        color: $line3;
        background-color: #f8f8f8;
      }
    }
  }
  .menu_hover_slide_line .sc_layouts_menu_nav > li#blob {
    background-color: $line2;
  }
  .menu_hover_slide_box .sc_layouts_menu_nav > li#blob {
    background-color: #f8f8f8;
  }
  .menu_hover_zoom_line .sc_layouts_menu_nav > li > a:before {
    background-color: $line2;
  }
  .menu_hover_path_line .sc_layouts_menu_nav > li {
    &:before, &:after {
      background-color: $line2;
    }
    > a {
      &:before, &:after {
        background-color: $line2;
      }
    }
  }
  .menu_hover_roll_down .sc_layouts_menu_nav > li > a:before {
    background-color: $line2;
  }
  .menu_hover_color_line .sc_layouts_menu_nav > li {
    > a {
      &:before {
        background-color: #1a181d;
      }
      &:after {
        background-color: $line2;
      }
    }
    &.menu-item-has-children > a:after {
      background-color: $line2;
    }
    &.sfHover > a {
      color: $line2;
    }
    > a {
      &:hover, &:focus {
        color: $line2;
      }
    }
  }
  &.sc_layouts_row .vc_separator.vc_sep_color_grey .vc_sep_line, .sc_layouts_row .vc_separator.vc_sep_color_grey .vc_sep_line {
    border-color: #e5e5e5;
  }
  .sc_layouts_cart_items_short {
    background-color: #1a181d;
    color: #fff;
  }
  .sc_layouts_cart_widget {
    border-color: #e5e5e5;
    background-color: #fff;
    color: #737a80;
    &:after {
      border-color: #e5e5e5;
      background-color: #fff;
    }
    .sc_layouts_cart_widget_close {
      color: #8b8f95;
      &:hover {
        color: #1a181d;
      }
    }
  }
  .sc_layouts_currency {
    .woocommerce-currency-switcher-form .wSelect-selected {
      color: #333;
      &:hover {
        color: #1d1d1d;
      }
    }
    .chosen-container .chosen-results {
      background: #f8f8f8;
      color: #1d1d1d;
    }
    .woocommerce-currency-switcher-form {
      .wSelect-options-holder, .dd-options, .dd-option {
        background: #f8f8f8;
        color: #1d1d1d;
      }
    }
    .chosen-container .chosen-results li, .woocommerce-currency-switcher-form .wSelect-option {
      color: #1d1d1d;
    }
    .chosen-container .active-result {
      &.highlighted, &.result-selected {
        color: $line3 !important;
      }
    }
    .woocommerce-currency-switcher-form {
      .wSelect-option:hover, .wSelect-options-holder .wSelect-option-selected, .dd-option:hover, .dd-option-selected {
        color: $line3 !important;
      }
      .dd-option-description {
        color: #333;
      }
    }
  }
  #page_preloader, &.header_position_under .page_content_wrap, .page_wrap {
    background-color: #fff;
  }
  .preloader_wrap > div {
    background-color: $line2;
  }
  &.top_panel {
    &.with_bg_image:before {
      background-color: rgba(255, 255, 255, 0.7);
    }
    .slider_engine_revo .slide_subtitle {
      color: $line2;
    }
  }
  .top_panel .slider_engine_revo .slide_subtitle {
    color: $line2;
  }
  .top_panel_default .top_panel_navi, &.top_panel_default .top_panel_navi {
    background-color: #fff;
  }
  .top_panel_default .top_panel_title, &.top_panel_default .top_panel_title {
    background-color: #f8f8f8;
  }
  div.esg-filter-wrapper .esg-filterbutton > span, .mptt-navigation-tabs li a, .alpha_color_tabs .alpha_color_tabs_titles li a {
    color: #1a181d;
    background-color: #f4f6fa;
  }
  div.esg-filter-wrapper .esg-filterbutton > span:hover, .mptt-navigation-tabs li a:hover, .alpha_color_tabs .alpha_color_tabs_titles li a:hover {
    color: #1a181d;
    background-color: $line2;
  }
  div.esg-filter-wrapper .esg-filterbutton.selected > span, .mptt-navigation-tabs li.active a, .alpha_color_tabs .alpha_color_tabs_titles li.ui-state-active a {
    color: #fff;
    background-color: $line3;
  }
  .post_item {
    color: #737a80;
  }
  .post_meta {
    color: #8b8f95;
  }
  .post_meta_item {
    color: #8b8f95;
    a, &:before, &:after {
      color: #8b8f95;
    }
    &:hover {
      &:before, &:after {
        color: #8b8f95;
      }
    }
  }
  .post_date {
    a, &:before, &:after {
      color: #8b8f95;
    }
  }
  .post_info .post_info_item {
    color: #8b8f95;
    a {
      color: #8b8f95;
    }
  }
  .post_info_counters .post_counters_item {
    color: #8b8f95;
  }
  .post_counters .socials_share .socials_caption {
    &:before, &:hover:before {
      color: #8b8f95;
    }
  }
  .post_date a:hover {
    color: #1a181d;
  }
  a.post_meta_item:hover, .post_meta_item a:hover, .post_info .post_info_item a:hover, .post_info_counters .post_counters_item:hover {
    color: #1a181d;
    &:before {
      color: #1a181d;
    }
  }
  .post_item .post_title a:hover {
    color: $line3;
  }
  .post_meta_item.post_author {
    color: #1a181d;
    &:hover {
      color: $line3;
    }
  }
  .post-bottom {
    border-color: #e5e5e5;
  }
  .post_meta_item {
    .socials_share .social_items {
      background-color: #fff;
    }
    .social_items {
      background-color: #fff;
      border-color: #e5e5e5;
      color: #8b8f95;
      &:before {
        background-color: #fff;
        border-color: #e5e5e5;
        color: #8b8f95;
      }
    }
  }
  .post_layout_excerpt:not(.sticky) + .post_layout_excerpt:not(.sticky), .post_layout_classic {
    border-color: #e5e5e5;
  }
  &.gallery_preview {
    &:before {
      background-color: #fff;
    }
    color: #737a80;
  }
  .trx_addons_audio_player .audio_author {
    color: $line2;
  }
  .format-audio .post_featured {
    .post_audio_author {
      color: $line2;
    }
    &.without_thumb {
      .post_audio {
        border-color: #e5e5e5;
      }
      .post_audio_title {
        color: #1a181d;
      }
    }
  }
  .without_thumb .mejs-controls {
    .mejs-currenttime, .mejs-duration {
      color: #1a181d;
    }
  }
  .trx_addons_audio_player {
    &.without_cover {
      border-color: #f4f6fa;
      background-color: #f4f6fa;
    }
    &.with_cover .audio_caption {
      color: #fff;
    }
    &.without_cover .audio_author {
      color: $line3;
    }
    .mejs-container .mejs-controls .mejs-time {
      color: #1d1d1d;
    }
    &.with_cover .mejs-container .mejs-controls .mejs-time {
      color: #fff;
    }
  }
  .mejs-container .mejs-controls {
    background: rgba(26, 24, 29, 0.7);
  }
  .mejs-embed {
    background: rgba(26, 24, 29, 0.7);
    body {
      background: rgba(26, 24, 29, 0.7);
    }
  }
  .mejs-controls {
    .mejs-button, .mejs-time-rail .mejs-time-current, .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
      color: #fff;
      background: $line2;
    }
    .mejs-button:hover {
      color: #1d1d1d;
      background: $line3;
    }
    .mejs-time-rail {
      .mejs-time-total, .mejs-time-loaded {
        background: rgba(253, 230, 66, 0.2);
      }
    }
  }
  .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
    background: rgba(253, 230, 66, 0.2);
  }
  .format-aside .post_content_inner {
    color: #1d1d1d;
    background-color: #f8f8f8;
  }
  .format-link .post_content_inner, .format-status .post_content_inner {
    color: #1a181d;
  }
  .format-chat p > {
    b, strong {
      color: #1a181d;
    }
  }
  .trx_addons_video_player.with_cover .video_hover, .format-video .post_featured.with_thumb .post_video_hover {
    color: #fff;
    background-color: $line3;
  }
  .trx_addons_video_player.with_cover .video_hover:hover, .format-video .post_featured.with_thumb .post_video_hover:hover {
    color: #1a181d;
    background-color: $line2;
  }
  .sidebar_inner .trx_addons_video_player.with_cover .video_hover {
    color: $line3;
    &:hover {
      color: #1d1d1d;
      background-color: $line3;
    }
  }
  .post_layout_chess .post_content_inner:after {
    background: linear-gradient(to top, #fff 0%, rgba(255, 255, 255, 0) 100%) no-repeat scroll right (top / 100%) 100% rgba(255, 255, 255, 0);
  }
  .post_layout_chess_1 .post_meta:before {
    background-color: #e5e5e5;
  }
  .nav-links-old {
    color: #1a181d;
    a:hover {
      color: #1a181d;
      border-color: #1a181d;
    }
  }
  div.esg-pagination .esg-pagination-button, .woocommerce nav.woocommerce-pagination ul li a, .page_links > a, .comments_pagination .page-numbers, .nav-links .page-numbers {
    color: #1a181d;
    background-color: $line2;
  }
  div.esg-pagination .esg-pagination-button {
    &:hover, &.selected {
      color: #fff;
      background-color: $line3;
    }
  }
  .woocommerce nav.woocommerce-pagination ul li {
    a:hover, span.current {
      color: #fff;
      background-color: $line3;
    }
  }
  .page_links > {
    a:hover, span:not(.page_links_title) {
      color: #fff;
      background-color: $line3;
    }
  }
  .comments_pagination {
    a.page-numbers:hover, .page-numbers.current {
      color: #fff;
      background-color: $line3;
    }
  }
  .nav-links {
    a.page-numbers:hover, .page-numbers.current {
      color: #fff;
      background-color: $line3;
    }
  }
  .post_item_single {
    .post_header {
      .post_date {
        color: #8b8f95;
      }
      .post_categories {
        color: $line2;
        a {
          color: $line2;
        }
      }
      .post_meta_item {
        color: #8b8f95;
        &:before, &:hover:before {
          color: #8b8f95;
        }
        a {
          color: #8b8f95;
          &:before, &:hover:before {
            color: #8b8f95;
          }
        }
        .socials_caption {
          color: #8b8f95;
          &:before {
            color: #8b8f95;
          }
        }
      }
      .post_edit a {
        color: #8b8f95;
      }
    }
    .post_meta_item {
      &:hover, > a:hover, .socials_caption:hover {
        color: $line3;
      }
    }
    .post_edit a:hover {
      color: $line3;
    }
    .post_content {
      .post_meta_label, .post_meta_item:hover .post_meta_label {
        color: #1a181d;
      }
      .post_tags {
        color: #8b8f95;
        a {
          color: #8b8f95;
          &:hover {
            color: $line3;
          }
        }
      }
      .post_meta .post_share .social_item {
        .social_icon {
          color: #fff !important;
          background-color: $line2;
        }
        &:hover .social_icon {
          color: #1d1d1d !important;
          background-color: $line3;
        }
      }
    }
  }
  .post-password-form input[type="submit"] {
    border-color: #1a181d;
    &:hover, &:focus {
      color: #fff;
    }
  }
  .nav-links-single .nav-links {
    border-color: #e5e5e5;
    a {
      .meta-nav, .post_date {
        color: #8b8f95;
      }
      &:hover {
        .meta-nav, .post_date {
          color: #1a181d;
        }
        .post-title {
          color: $line2;
        }
      }
    }
  }
  &.author_info {
    color: #737a80;
    background-color: #f4f6fa;
    .author_title {
      color: #1a181d;
    }
    a {
      color: #1a181d;
      &:hover {
        color: $line2;
      }
    }
    .socials_wrap .social_item {
      .social_icon {
        color: #fff;
        background-color: $line2;
      }
      &:hover .social_icon {
        color: #1d1d1d;
        background-color: $line3;
      }
    }
  }
  .related_wrap {
    border-color: #e5e5e5;
    .related_item_style_1 {
      .post_header {
        background-color: rgba(255, 255, 255, 0.7);
      }
      &:hover .post_header {
        background-color: #fff;
      }
      .post_date a {
        color: #737a80;
      }
      &:hover .post_date a {
        color: #8b8f95;
        &:hover {
          color: #1a181d;
        }
      }
    }
  }
  .comments_list_wrap {
    border-color: #e5e5e5;
    > ul {
      border-color: #e5e5e5;
    }
    li {
      + li, ul {
        border-color: #e5e5e5;
      }
    }
    .bypostauthor > .comment_body .comment_author_avatar:after {
      border-color: $line1;
    }
    .comment_info {
      color: #1a181d;
    }
    .comment_counters a {
      color: $line2;
      &:before {
        color: $line2;
      }
      &:hover {
        &:before {
          color: $line3;
        }
        color: $line3;
      }
    }
    .comment_text {
      color: #737a80;
    }
    .comment_reply a {
      color: #1a181d;
      &:hover {
        color: $line3;
      }
    }
  }
  .comments_form_wrap {
    border-color: #e5e5e5;
  }
  .comments_wrap .comments_notes {
    color: #8b8f95;
  }
  .post_item_404 {
    .page_title {
      color: #8b8f95;
    }
    .page_description {
      color: $line3;
    }
    .go_home {
      color: #1a181d !important;
    }
  }
  &.sidebar .sidebar_inner .widget {
    background-color: #fff;
    color: #737a80;
    border-color: #e5e5e5;
  }
  .sidebar[class*="scheme_"] .widget {
    &:nth-child(4n+1):before {
      border-top-color: $line2;
    }
    &:nth-child(4n+2) {
      border-top-color: $line1;
      &:before {
        border-top-color: $line1;
      }
    }
    &:nth-child(4n+3):before {
      border-top-color: $line3;
    }
    &:nth-child(4n+4):before {
      border-top-color: #1a181d;
    }
  }
  &.sidebar {
    h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
      color: #1d1d1d;
    }
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
      color: $line3;
    }
  }
  .widget ul > li:before {
    color: $line3;
  }
  &.sidebar {
    a {
      color: $line3;
      &:hover {
        color: #72cfd5;
      }
    }
    li > a {
      color: #8b8f95;
    }
    .post_title > a {
      color: #1a181d;
    }
    li > a:hover, .post_title > a:hover {
      color: $line3;
    }
    .widget_archive li {
      color: #1d1d1d;
    }
  }
  .widget_calendar {
    caption, tbody td a, th {
      color: #1a181d;
    }
  }
  &.sidebar .widget_calendar {
    caption {
      color: $line3;
      border-color: #e5e5e5;
    }
    tbody td a, th {
      color: #1d1d1d;
    }
  }
  .widget_calendar tbody td {
    color: #737a80 !important;
  }
  &.sidebar .widget_calendar tbody td {
    color: #9a9d9f !important;
  }
  .widget_calendar tbody td a:hover {
    color: $line2;
  }
  &.sidebar .widget_calendar tbody td a:hover {
    color: $line3;
  }
  .widget_calendar tbody td a:after {
    background-color: $line2;
  }
  &.sidebar .widget_calendar tbody td a:after {
    background-color: $line3;
  }
  .widget_calendar td#today {
    color: #1d1d1d !important;
    a {
      color: #fff;
      &:hover {
        color: #1d1d1d;
      }
    }
    &:before {
      background-color: $line2;
    }
  }
  &.sidebar .widget_calendar td#today:before {
  }
  .widget_calendar {
    td#today a {
      &:after {
        background-color: #fff;
      }
      &:hover:after {
        background-color: #1d1d1d;
      }
    }
    #prev a, #next a {
      color: $line2;
    }
  }
  &.sidebar .widget_calendar {
    #prev a, #next a {
      color: $line3;
    }
  }
  .widget_calendar {
    #prev a:hover, #next a:hover {
      color: $line3;
    }
  }
  &.sidebar .widget_calendar {
    #prev a:hover, #next a:hover {
      color: #1a181d;
    }
  }
  .widget_calendar td {
    &#prev a:before, &#next a:before {
      background-color: #fff;
    }
  }
  .widget.widget_recent_comments ul li {
    color: #8b8f95;
    border-color: #e5e5e5;
    a {
      color: #1a181d;
      &:hover {
        color: $line3;
      }
    }
  }
  .widget_categories li {
    color: #1a181d;
  }
  &.sidebar .widget_categories li {
    color: #1d1d1d;
  }
  .sc_edd_details .downloads_page_tags .downloads_page_data > a, .widget_product_tag_cloud a, .widget_tag_cloud a {
    color: #fff;
    background-color: $line3;
  }
  .sc_edd_details .downloads_page_tags .downloads_page_data > a:hover, .widget_product_tag_cloud a:hover, .widget_tag_cloud a:hover {
    color: #1a181d !important;
    background-color: $line2;
  }
  .widget_product_tag_cloud, .widget_tag_cloud {
    border-top-color: $line2;
  }
  .widget_rss .widget_title a:first-child {
    color: $line2;
  }
  &.sidebar .widget_rss .widget_title a:first-child, .widget_rss .widget_title a:first-child:hover {
    color: $line3;
  }
  &.sidebar .widget_rss .widget_title a:first-child:hover {
    color: #72cfd5;
  }
  .widget_rss .rss-date {
    color: #8b8f95;
  }
  &.sidebar .widget_rss .rss-date {
    color: #b7b7b7;
  }
  &.footer_wrap {
    background-color: #f8f8f8;
    color: #333;
  }
}

/* Row type:Narrow */

/* Row type:Compact */

/* Row type:Normal */

.footer_wrap .scheme_default.vc_row {
  background-color: #f8f8f8;
  color: #333;
}

.scheme_default.footer_wrap {
  .widget, .sc_content .wpb_column {
    border-color: #e5e5e5;
  }
}

.footer_wrap .scheme_default.vc_row {
  .widget, .sc_content .wpb_column {
    border-color: #e5e5e5;
  }
}

.scheme_default.footer_wrap {
  h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #1d1d1d;
  }
}

.footer_wrap .scheme_default.vc_row {
  h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #1d1d1d;
  }
}

.scheme_default.footer_wrap {
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
    color: $line3;
  }
}

.footer_wrap .scheme_default.vc_row {
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
    color: $line3;
  }
}

.scheme_default.footer_wrap .widget li:before, .footer_wrap .scheme_default.vc_row .widget li:before {
  background-color: $line3;
}

.scheme_default.footer_wrap a, .footer_wrap .scheme_default.vc_row a {
  color: #333;
}

.scheme_default.footer_wrap a:hover, .footer_wrap .scheme_default.vc_row a:hover {
  color: $line3;
}

.scheme_default {
  .footer_logo_inner {
    border-color: #e5e5e5;
    &:after {
      background-color: #333;
    }
  }
  .footer_socials_inner .social_item {
    .social_icon {
      color: #333;
    }
    &:hover .social_icon {
      color: #1d1d1d;
    }
  }
  .menu_footer_nav_area {
    ul li {
      a {
        color: #1d1d1d;
        &:hover {
          color: $line3;
        }
      }
      + li:before {
        border-color: #b7b7b7;
      }
    }
    > ul > li ul {
      border-color: #313131;
    }
  }
  .footer_wrap .sc_layouts_menu > ul > li ul {
    border-color: #313131;
  }
  .footer_copyright_inner {
    background-color: #fff;
    border-color: #e5e5e5;
    color: #1a181d;
    a {
      color: #1a181d;
      &:hover {
        color: $line2;
      }
    }
    .copyright_text {
      color: #737a80;
    }
  }
  .mfp-bg {
    background-color: rgba(255, 255, 255, 0.7);
  }
  .mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close, .mfp-close-btn-in .mfp-close {
    color: #1a181d;
    background-color: transparent;
  }
  .mfp-image-holder .mfp-close:hover, .mfp-iframe-holder .mfp-close:hover, .mfp-close-btn-in .mfp-close:hover {
    color: $line2;
  }
  button[disabled] {
    background-color: #8b8f95 !important;
    color: #737a80 !important;
  }
  input {
    &[type="submit"][disabled], &[type="button"][disabled] {
      background-color: #8b8f95 !important;
      color: #737a80 !important;
    }
  }
  .trx_addons_accent {
    color: $line2;
    > {
      a, * {
        color: $line2;
      }
      a:hover {
        color: #1a181d;
      }
    }
  }
  .sidebar .trx_addons_accent, &.sidebar .trx_addons_accent, .sidebar .trx_addons_accent > a, &.sidebar .trx_addons_accent > a, .sidebar .trx_addons_accent > *, &.sidebar .trx_addons_accent > *, .footer_wrap .trx_addons_accent, &.footer_wrap .trx_addons_accent, .footer_wrap .trx_addons_accent > a, &.footer_wrap .trx_addons_accent > a, .footer_wrap .trx_addons_accent > *, &.footer_wrap .trx_addons_accent > * {
    color: $line3;
  }
  .sidebar .trx_addons_accent > a:hover, &.sidebar .trx_addons_accent > a:hover, .footer_wrap .trx_addons_accent > a:hover, &.footer_wrap .trx_addons_accent > a:hover {
    color: #1d1d1d;
  }
  .trx_addons_hover {
    color: $line3;
    > * {
      color: $line3;
    }
  }
  .trx_addons_accent_bg {
    background-color: $line2;
    color: #1d1d1d;
  }
  .trx_addons_inverse {
    color: #fff;
    background-color: #1a181d;
  }
  .trx_addons_dark {
    color: #1a181d;
    > a {
      color: #1a181d;
      &:hover {
        color: $line2;
      }
    }
  }
  .trx_addons_inverse {
    color: #fff;
    background-color: #1a181d;
    > a {
      color: #fff;
      background-color: #1a181d;
      &:hover {
        color: #1d1d1d;
      }
    }
  }
  .trx_addons_dropcap_style_1 {
    color: #fff;
    background-color: $line2;
  }
  .trx_addons_dropcap_style_2 {
    color: #1d1d1d;
    background-color: $line3;
  }
  ul[class*="trx_addons_list"] {
    > li:before {
      color: $line2;
    }
    &[class*="_circled"] > li:before {
      color: #fff;
      background-color: $line2;
    }
  }
  .trx_addons_list_parameters > li + li {
    border-color: #e5e5e5;
  }
  .trx_addons_tooltip {
    color: #1a181d;
    border-color: #1a181d;
    &:before {
      color: #fff;
      background-color: #1a181d;
    }
    &:after {
      border-top-color: #1a181d;
    }
  }
  blockquote {
    &.trx_addons_blockquote_style_1 {
      &:before {
        color: #fff;
        background-color: #1a181d;
      }
      color: #fff;
      background-color: #1a181d;
      b {
        color: #fff;
      }
      a, cite {
        color: $line2;
      }
      a:hover {
        color: #fff;
      }
    }
    &.trx_addons_blockquote_style_2 {
      color: #1d1d1d;
      background-color: $line2;
      &:before, a, cite {
        color: #fff;
      }
      a:hover {
        color: #1d1d1d;
      }
    }
  }
  .trx_addons_hover_mask {
    background-color: rgba(78, 184, 255, 0.7);
  }
  .trx_addons_hover_title {
    color: #fff;
  }
  .trx_addons_hover_text {
    color: #bfbfbf;
  }
  .trx_addons_hover_icon, .trx_addons_hover_links a {
    color: #fff;
    background-color: #fff;
  }
  .trx_addons_hover_icon:hover, .trx_addons_hover_links a:hover {
    color: #1d1d1d !important;
    background-color: #fe7259;
  }
  .widget .trx_addons_tabs .trx_addons_tabs_titles li {
    a {
      color: #333;
      background-color: #e5e5e5;
    }
    &.ui-state-active a, a:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  .slider_container {
    .slide_info.slide_info_large {
      background-color: rgba(255, 255, 255, 0.7);
      &:hover {
        background-color: #fff;
      }
      .slide_cats a {
        color: $line2;
      }
      .slide_title a {
        color: #1a181d;
      }
      .slide_date {
        color: #737a80;
      }
      &:hover .slide_date {
        color: #8b8f95;
      }
      .slide_cats a:hover, .slide_title a:hover {
        color: $line3;
      }
    }
    &.slider_multi {
      .slide_cats a:hover, .slide_title a:hover, a:hover .slide_title {
        color: $line3;
      }
    }
  }
  .sc_slider_controls .slider_controls_wrap > a, .slider_container.slider_controls_side .slider_controls_wrap > a, .slider_outer_controls_side .slider_controls_wrap > a {
    color: $line2;
    background-color: #1a181d;
    border-color: #1a181d;
  }
  .sc_slider_controls .slider_controls_wrap > a:hover, .slider_container.slider_controls_side .slider_controls_wrap > a:hover, .slider_outer_controls_side .slider_controls_wrap > a:hover {
    color: #fff;
    background-color: $line2;
    border-color: $line2;
  }
  .sc_slider_controls {
    .slider_progress {
      background-color: #e5e5e5;
    }
    .slider_progress_bar {
      background-color: $line2;
    }
  }
  .slider_container {
    &.slider_controls_top .slider_controls_wrap > a, &.slider_controls_bottom .slider_controls_wrap > a {
      color: #fff;
      background-color: #1a181d;
      border-color: #1a181d;
    }
  }
  .slider_outer_controls_top .slider_controls_wrap > a, .slider_outer_controls_bottom .slider_controls_wrap > a {
    color: #fff;
    background-color: #1a181d;
    border-color: #1a181d;
  }
  .slider_container {
    &.slider_controls_top .slider_controls_wrap > a:hover, &.slider_controls_bottom .slider_controls_wrap > a:hover {
      color: #fff;
      border-color: $line2;
      background-color: #1d1d1d;
    }
  }
  .slider_outer_controls_top .slider_controls_wrap > a:hover, .slider_outer_controls_bottom .slider_controls_wrap > a:hover {
    color: #fff;
    border-color: $line2;
    background-color: #1d1d1d;
  }
  .slider_container .slider_pagination_wrap .swiper-pagination-bullet, .slider_outer .slider_pagination_wrap .swiper-pagination-bullet {
    border-color: #e5e5e5;
    background-color: #f8f8f8;
  }
  .swiper-pagination-custom .swiper-pagination-button {
    border-color: #e5e5e5;
    background-color: #f8f8f8;
    &.swiper-pagination-button-active {
      border-color: $line2;
      background-color: $line2;
    }
  }
  .slider_container .slider_pagination_wrap .swiper-pagination-bullet.swiper-pagination-bullet-active, .slider_outer .slider_pagination_wrap .swiper-pagination-bullet.swiper-pagination-bullet-active, .slider_container .slider_pagination_wrap .swiper-pagination-bullet:hover, .slider_outer .slider_pagination_wrap .swiper-pagination-bullet:hover {
    border-color: $line2;
    background-color: $line2;
  }
  .slider_container .swiper-pagination-progress .swiper-pagination-progressbar {
    background-color: $line2;
  }
  .slider_outer {
    .swiper-pagination-progress .swiper-pagination-progressbar {
      background-color: $line2;
    }
    > .swiper-pagination-fraction {
      color: #1a181d;
    }
  }
  .slider_titles_outside_wrap {
    .slide_title a {
      color: #1a181d;
      &:hover {
        color: $line2;
      }
    }
    .slide_cats, .slide_subtitle {
      color: $line2;
    }
  }
  .slider_style_modern {
    .slider_controls_label {
      color: #fff;
    }
    .slider_pagination_wrap {
      color: #8b8f95;
    }
    .swiper-pagination-current {
      color: #1a181d;
    }
  }
  .sc_slider_controller .slider-slide.swiper-slide-active {
    border-color: $line2;
  }
  .sc_slider_controller_titles {
    .slider-slide {
      background-color: #f8f8f8;
      &:after {
        background-color: #e5e5e5;
      }
      &.swiper-slide-active {
        background-color: #fff;
      }
    }
    .sc_slider_controller_info_title {
      color: #1d1d1d;
    }
    .sc_slider_controller_info_number {
      color: #b7b7b7;
    }
    .slider_controls_wrap > a {
      color: #fff;
      background-color: $line2;
      &:hover {
        color: #fff;
        background-color: #1a181d;
      }
    }
  }
  .widget_categories_list {
    .categories_list_style_1 .categories_list_item:hover .categories_list_title, .categories_list_style_3 .categories_list_item:hover .categories_list_title {
      color: $line2;
    }
    .categories_list_style_2 .categories_list_title a:hover {
      color: #1a181d;
    }
  }
  .widget_contacts .contacts_info {
    color: #333;
    span {
      &:before {
        color: $line3;
      }
      a {
        color: #1d1d1d;
        &:hover {
          color: $line3;
        }
      }
    }
    > {
      div > a:before, a:before {
        color: $line3;
      }
    }
    > {
      div > a, a {
        color: #1d1d1d;
      }
    }
    > {
      div > a:hover, a:hover {
        color: $line3;
      }
    }
  }
  .sc_recent_news_header {
    border-color: #1a181d;
  }
  .sc_recent_news_header_category_item_more {
    color: $line2;
  }
  .sc_recent_news_header_more_categories {
    border-color: #313131;
    background-color: #4eb8ff;
    > a {
      color: #fff;
      &:hover {
        color: #fe7259;
        background-color: #28272e;
      }
    }
  }
  .sc_recent_news {
    .post_counters_item, .post_counters .post_counters_edit a {
      color: #fff;
      background-color: $line2;
    }
    .post_counters_item:hover, .post_counters .post_counters_edit a:hover {
      color: #fff;
      background-color: #1a181d;
    }
  }
  .sidebar_inner .sc_recent_news {
    .post_counters_item:hover, .post_counters .post_counters_edit a:hover {
      color: #1d1d1d;
      background-color: #f8f8f8;
    }
  }
  .sc_recent_news_style_news-magazine .post_accented_border, .sc_recent_news_style_news-excerpt .post_item {
    border-color: #e5e5e5;
  }
  .widget_twitter {
    .widget_content {
      .sc_twitter_item, li {
        color: #737a80;
      }
      .sc_twitter_item .sc_twitter_item_icon {
        color: $line2 !important;
      }
    }
    .swiper-pagination-bullet {
      background-color: #8b8f95;
    }
    .swiper-pagination-bullet-active {
      background-color: $line2;
    }
    .widget_content .sc_twitter_list li {
      color: #333;
      &:before {
        color: $line3 !important;
      }
    }
  }
  .sc_item_subtitle {
    color: $line2;
  }
  .color_style_link2 .sc_item_subtitle {
    color: $line1;
  }
  .color_style_link3 .sc_item_subtitle {
    color: $line3;
  }
  .sc_item_subtitle.sc_item_title_style_shadow {
    color: #8b8f95;
  }
  .theme_scroll_down:hover {
    color: $line2;
  }
  .sc_action_item {
    .sc_action_item_subtitle {
      color: $line2;
    }
    &.color_style_link2 .sc_action_item_subtitle {
      color: $line1;
    }
    &.color_style_link3 .sc_action_item_subtitle {
      color: $line3;
    }
    &.color_style_dark .sc_action_item_subtitle {
      color: #1a181d;
    }
  }
  .sc_action_item_event {
    .sc_action_item_date, .sc_action_item_info {
      color: #1a181d;
      border-color: #737a80;
    }
    .sc_action_item_description {
      color: #737a80;
    }
    &.with_image .sc_action_item_inner {
      background-color: #fff;
    }
  }
  &.menu_side_icons .toc_menu_item .toc_menu_icon, .menu_side_inner > .toc_menu_item .toc_menu_icon {
    background-color: #fff;
    border-color: #e5e5e5;
    color: $line2;
  }
  &.menu_side_icons {
    .toc_menu_item:hover .toc_menu_icon, .toc_menu_item_active .toc_menu_icon {
      background-color: $line2;
      color: #fff;
    }
  }
  .menu_side_inner > {
    .toc_menu_item:hover .toc_menu_icon, .toc_menu_item_active .toc_menu_icon {
      background-color: $line2;
      color: #fff;
    }
  }
  &.menu_side_icons .toc_menu_icon_default:before, .menu_side_inner > .toc_menu_icon_default:before {
    background-color: $line2;
  }
  &.menu_side_icons {
    .toc_menu_item:hover .toc_menu_icon_default:before, .toc_menu_item_active .toc_menu_icon_default:before {
      background-color: #1a181d;
    }
  }
  .menu_side_inner > {
    .toc_menu_item:hover .toc_menu_icon_default:before, .toc_menu_item_active .toc_menu_icon_default:before {
      background-color: #1a181d;
    }
  }
  &.menu_side_icons .toc_menu_item .toc_menu_description, .menu_side_inner > .toc_menu_item .toc_menu_description {
    color: #fff;
    background-color: $line2;
  }
  &.menu_side_dots #toc_menu {
    .toc_menu_item {
      .toc_menu_icon {
        background-color: #f8f8f8;
        color: #333;
      }
      &:hover .toc_menu_icon {
        color: $line3;
      }
    }
    .toc_menu_item_active .toc_menu_icon {
      color: $line3;
    }
    .toc_menu_item {
      .toc_menu_icon:before {
        background-color: $line3;
      }
      &:hover .toc_menu_icon:before {
        background-color: #72cfd5;
      }
    }
  }
  .sc_blogger.slider_container .swiper-pagination-bullet {
    border-color: #8b8f95;
  }
  .sc_blogger_item {
    background-color: #f8f8f8;
  }
  .sc_blogger_post_meta {
    color: #b7b7b7;
  }
  .sc_blogger_item_title a {
    color: #1d1d1d;
    &:hover {
      color: $line3;
    }
  }
  .sc_blogger_post_meta {
    color: #b7b7b7;
  }
  .sc_blogger_item_content {
    color: #333;
  }
  .sc_blogger_item .more-link {
    color: $line3;
    &:hover {
      color: #1d1d1d;
    }
  }
  .sc_cars_columns_1 .sc_cars_item {
    background-color: #f8f8f8;
  }
  .sc_cars_item_status > a, .sc_cars_item_type > a {
    color: #8b8f95;
  }
  .sc_cars_item_compare {
    color: #8b8f95;
    &.in_compare_list {
      color: $line2;
    }
  }
  .sc_cars_item_status > a:hover, .sc_cars_item_type > a:hover {
    color: #1a181d;
  }
  .sc_cars_item_compare {
    &:hover, &.in_compare_list:hover {
      color: #1a181d;
    }
  }
  .sc_cars_item_options {
    .sc_cars_item_row_address, .sc_cars_item_row_meta {
      color: #8b8f95;
    }
  }
  .cars_page_title .cars_page_status > a {
    color: #fff;
    background-color: $line2;
    &:hover {
      color: #1d1d1d;
      background-color: #fff04f;
    }
  }
  .cars_page_title_address, .cars_price {
    color: #8b8f95;
  }
  .cars_page_attachments_list > a:before, .cars_page_features_list > a:before {
    color: $line2;
  }
  .cars_page_tabs.trx_addons_tabs .trx_addons_tabs_titles {
    border-color: #e5e5e5;
    li {
      > a {
        background-color: #f8f8f8;
        border-color: #f8f8f8;
        border-bottom-color: #e5e5e5;
      }
      &.ui-state-active > a {
        border-color: #e5e5e5;
        background-color: #fff;
        border-bottom-color: #fff;
      }
      &:not(.ui-state-active) > a:hover {
        background-color: #e6e8eb;
        border-color: #e6e8eb #e6e8eb #e5e5e5;
      }
    }
  }
  .cars_page_agent_info_position {
    color: #8b8f95;
  }
  .cars_page_agent_info_phones > {
    span, a {
      color: #737a80;
    }
  }
  .cars_page_agent_info_address:before, .cars_page_agent_info_phones > :before {
    color: #1a181d;
  }
  .cars_page_agent_info_profiles.socials_wrap .social_item .social_icon {
    color: #737a80;
  }
  .cars_search_form .cars_search_basic .cars_search_show_advanced {
    color: #9a9d9f;
    background-color: #f4f6fa;
    &:hover {
      color: #15212c;
    }
  }
  .sc_cars_compare_data .cars_feature_present {
    color: $line2;
  }
  .sc_content_number {
    color: #e6e8eb;
  }
  .sc_countdown_default .sc_countdown_digits span {
    color: #fff;
    border-color: $line3;
    background-color: $line2;
  }
  .sc_countdown_circle .sc_countdown_digits {
    color: $line3;
    border-color: #e5e5e5;
    background-color: #f8f8f8;
  }
  .sc_courses.slider_container .swiper-pagination-bullet {
    border-color: #8b8f95;
  }
  .sc_courses_default {
    .sc_courses_item {
      background-color: #f8f8f8;
    }
    .sc_courses_item_categories {
      background-color: #1d1d1d;
      a {
        color: #fff;
        &:hover {
          color: $line3;
        }
      }
    }
    .sc_courses_item_meta {
      color: #b7b7b7;
    }
    .sc_courses_item_date {
      color: #1d1d1d;
    }
    .sc_courses_item_price {
      color: $line3;
    }
    .sc_courses_item_period {
      color: #b7b7b7;
    }
  }
  .courses_single {
    .courses_page_meta {
      color: #8b8f95;
    }
    .courses_page_meta_item_date {
      color: #1a181d;
    }
    .courses_page_period {
      color: #8b8f95;
    }
  }
  .sc_dishes_default {
    .sc_dishes_item {
      color: #333;
      background-color: #f8f8f8;
    }
    .sc_dishes_item_subtitle {
      color: $line3;
      a {
        color: $line3;
        &:hover {
          color: #72cfd5;
        }
      }
    }
    &.color_style_link2 .sc_dishes_item_subtitle {
      color: #8be77c;
      a {
        color: #8be77c;
        &:hover {
          color: #80d572;
        }
      }
    }
    &.color_style_link3 .sc_dishes_item_subtitle {
      color: #eec432;
      a {
        color: #eec432;
        &:hover {
          color: #ddb837;
        }
      }
    }
    &.color_style_dark .sc_dishes_item_subtitle {
      color: #1d1d1d;
      a {
        color: #1d1d1d;
        &:hover {
          color: $line3;
        }
      }
    }
    .sc_dishes_item_featured_left, .sc_dishes_item_featured_right {
      color: #737a80;
      background-color: transparent;
    }
    .sc_dishes_item_featured_left .sc_dishes_item_subtitle, .sc_dishes_item_featured_right .sc_dishes_item_subtitle {
      color: $line2;
      a {
        color: $line2;
      }
    }
    .sc_dishes_item_featured_left .sc_dishes_item_subtitle a:hover, .sc_dishes_item_featured_right .sc_dishes_item_subtitle a:hover {
      color: $line3;
    }
    &.color_style_link2 {
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle, .sc_dishes_item_featured_right .sc_dishes_item_subtitle {
        color: $line1;
        a {
          color: $line1;
        }
      }
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle a:hover, .sc_dishes_item_featured_right .sc_dishes_item_subtitle a:hover {
        color: $line3;
      }
    }
    &.color_style_link3 {
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle, .sc_dishes_item_featured_right .sc_dishes_item_subtitle {
        color: $line3;
        a {
          color: $line3;
        }
      }
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle a:hover, .sc_dishes_item_featured_right .sc_dishes_item_subtitle a:hover {
        color: $line2;
      }
    }
    &.color_style_dark {
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle, .sc_dishes_item_featured_right .sc_dishes_item_subtitle {
        color: #1a181d;
        a {
          color: #1a181d;
        }
      }
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle a:hover, .sc_dishes_item_featured_right .sc_dishes_item_subtitle a:hover {
        color: $line2;
      }
    }
  }
  .sc_dishes_compact {
    .sc_dishes_item {
      color: #333;
      background-color: #f8f8f8;
    }
    .sc_dishes_item_header {
      color: #fff;
      background-color: #1a181d;
    }
    .sc_dishes_item_price, .sc_dishes_item_subtitle a {
      color: #fff;
    }
    .sc_dishes_item_price:hover, .sc_dishes_item:hover .sc_dishes_item_price, .sc_dishes_item_subtitle a:hover, .sc_dishes_item:hover .sc_dishes_item_subtitle a {
      color: $line2;
    }
    &.color_style_link2 {
      .sc_dishes_item_price:hover, .sc_dishes_item:hover .sc_dishes_item_price, .sc_dishes_item_subtitle a:hover, .sc_dishes_item:hover .sc_dishes_item_subtitle a {
        color: $line1;
      }
    }
    &.color_style_link3 {
      .sc_dishes_item_price:hover, .sc_dishes_item:hover .sc_dishes_item_price, .sc_dishes_item_subtitle a:hover, .sc_dishes_item:hover .sc_dishes_item_subtitle a {
        color: $line3;
      }
    }
    .sc_dishes_item_title a {
      color: $line2;
    }
    &.color_style_link2 .sc_dishes_item_title a {
      color: $line1;
    }
    &.color_style_link3 .sc_dishes_item_title a {
      color: $line3;
    }
    .sc_dishes_item_title a:hover, .sc_dishes_item:hover .sc_dishes_item_title a {
      color: #fff;
    }
  }
  .sc_dishes.slider_container .swiper-pagination-bullet, .sc_events.slider_container .swiper-pagination-bullet {
    border-color: #8b8f95;
  }
  .sc_events_default {
    .sc_events_item {
      background-color: #f8f8f8;
    }
    .sc_events_item_date {
      background-color: $line3;
      color: #fff;
    }
    .sc_events_item:hover .sc_events_item_date {
      background-color: #1d1d1d;
    }
    .sc_events_item_title {
      color: #1d1d1d;
    }
    .sc_events_item:hover .sc_events_item_title, .sc_events_item_button {
      color: $line3;
    }
    .sc_events_item:hover .sc_events_item_button {
      color: #1d1d1d;
    }
  }
  .sc_events_detailed {
    .sc_events_item, .sc_events_item_date_wrap, .sc_events_item_time_wrap:before, .sc_events_item_button_wrap:before {
      border-color: $line2;
    }
    .sc_events_item_date, .sc_events_item_button {
      color: $line2;
    }
    .sc_events_item_title {
      color: #1a181d;
    }
    .sc_events_item_time {
      color: #737a80;
    }
    .sc_events_item:hover {
      background-color: $line2;
      color: #fff;
      color: #1d1d1d;
      .sc_events_item_date, .sc_events_item_button, .sc_events_item_title, .sc_events_item_time {
        color: #1d1d1d;
      }
      border-color: #1d1d1d;
      .sc_events_item_date_wrap, .sc_events_item_time_wrap:before, .sc_events_item_button_wrap:before {
        border-color: #1d1d1d;
      }
    }
  }
  &.sc_form {
    background-color: #fff;
  }
  span.sc_form_field_title {
    color: #1a181d;
  }
  .sc_form {
    .sc_form_info_icon {
      color: $line2;
    }
    .sc_form_info_data > {
      a, span {
        color: #1a181d;
      }
      a:hover {
        color: $line2;
      }
    }
  }
  [class*="sc_input_hover_"] .sc_form_field_content {
    color: #1a181d;
  }
  .sc_input_hover_accent {
    input {
      &[type="text"]:focus, &[type="number"]:focus, &[type="email"]:focus, &[type="password"]:focus, &[type="search"]:focus {
        border-color: $line2 !important;
      }
    }
    select:focus {
      border-color: $line2 !important;
    }
    .select2-container {
      &.select2-container--focus span.select2-selection, &.select2-container--open span.select2-selection {
        border-color: $line2 !important;
      }
    }
    textarea:focus {
      border-color: $line2 !important;
    }
    .sc_form_field_hover:before {
      color: rgba(253, 230, 66, 0.2);
    }
  }
  .sc_input_hover_path .sc_form_field_graphic {
    stroke: #f4f6fa;
  }
  .sc_input_hover_jump {
    .sc_form_field_content {
      color: #15212c;
      &:before {
        color: $line2;
      }
    }
    input {
      &[type="text"], &[type="number"], &[type="email"], &[type="password"], &[type="search"] {
        border-color: #f4f6fa;
      }
    }
    textarea {
      border-color: #f4f6fa;
    }
    input {
      &[type="text"]:focus, &[type="number"]:focus, &[type="email"]:focus, &[type="password"]:focus, &[type="search"]:focus {
        border-color: $line2 !important;
      }
    }
    textarea:focus {
      border-color: $line2 !important;
    }
  }
  .sc_input_hover_underline {
    .sc_form_field_hover:before {
      background-color: #f4f6fa;
    }
    input:focus + .sc_form_field_hover:before, textarea:focus + .sc_form_field_hover:before, input.filled + .sc_form_field_hover:before, textarea.filled + .sc_form_field_hover:before {
      background-color: $line2;
    }
    .sc_form_field_content {
      color: #15212c;
    }
    input:focus, textarea:focus, input.filled, textarea.filled, input:focus + .sc_form_field_hover > .sc_form_field_content, textarea:focus + .sc_form_field_hover > .sc_form_field_content, input.filled + .sc_form_field_hover > .sc_form_field_content, textarea.filled + .sc_form_field_hover > .sc_form_field_content {
      color: $line2 !important;
    }
  }
  .sc_input_hover_iconed {
    .sc_form_field_hover {
      color: #9a9d9f;
    }
    input:focus + .sc_form_field_hover, textarea:focus + .sc_form_field_hover, input.filled + .sc_form_field_hover, textarea.filled + .sc_form_field_hover {
      color: #15212c;
    }
  }
  .sc_googlemap_content, &.sc_googlemap_content {
    color: #737a80;
    background-color: #fff;
  }
  .sc_googlemap_content {
    b, strong {
      color: #1a181d;
    }
  }
  &.sc_googlemap_content {
    b, strong {
      color: #1a181d;
    }
  }
  .sc_googlemap_content_detailed:before {
    color: $line2;
  }
  .sc_icons {
    .sc_icons_icon {
      color: $line2;
    }
    .sc_icons_item_linked:hover .sc_icons_icon {
      color: #1a181d;
    }
    .sc_icons_item_title {
      color: $line2;
    }
  }
  &.footer_wrap .sc_icons {
    .sc_icons_item_title {
      color: #1a181d;
    }
    .sc_icons_item_description {
      color: #737a80;
    }
  }
  .sc_icons_item_description, .sc_icons_modern .sc_icons_item_description {
    color: #1a181d;
  }
  .sc_sport_default .sc_sport_item_subtitle .sc_sport_item_date {
    color: #8b8f95;
  }
  .sc_matches_main {
    .swiper-pagination .swiper-pagination-bullet {
      border-color: #e5e5e5;
    }
    .sc_matches_item_score a {
      color: #1a181d;
      &:hover {
        color: $line2;
      }
    }
  }
  .color_style_link2 .sc_matches_main .sc_matches_item_score a:hover {
    color: $line1;
  }
  .color_style_link3 .sc_matches_main .sc_matches_item_score a:hover {
    color: $line3;
  }
  .color_style_dark .sc_matches_main .sc_matches_item_score a:hover {
    color: #1a181d;
  }
  .sc_matches_other {
    .sc_matches_item_link {
      color: #1d1d1d;
      background-color: #f8f8f8;
    }
    .sc_matches_item_club {
      color: #b7b7b7;
    }
    .sc_matches_item_date {
      color: #1d1d1d;
      background-color: #e5e5e5;
    }
    .sc_matches_item_link:hover {
      background-color: #e6e8eb;
      .sc_matches_item_date {
        background-color: #dadada;
      }
    }
  }
  .sc_points_table {
    td a {
      color: #1d1d1d;
    }
    tr:hover {
      td {
        background-color: #72cfd5 !important;
      }
      a {
        color: #1d1d1d !important;
      }
    }
    td a:hover {
      color: #1d1d1d !important;
    }
    tr {
      &.sc_points_table_accented_top td {
        background-color: rgba(253, 230, 66, 0.7);
      }
      &.sc_points_table_accented_bottom td {
        background-color: #f8f8f8;
      }
    }
  }
  .sc_price_item {
    color: #bfbfbf;
    background-color: #4eb8ff;
    border-color: #313131;
    &:hover {
      background-color: #28272e;
      border-color: #3d3d3d;
    }
    .sc_price_item_icon {
      color: #fff;
    }
    &:hover .sc_price_item_icon {
      color: #fe7259;
    }
    .sc_price_item_label {
      background-color: #fff;
      color: #1d1d1d;
    }
    &:hover .sc_price_item_label {
      background-color: #fe7259;
      color: #1d1d1d;
    }
    .sc_price_item_subtitle {
      color: #fff;
    }
    .sc_price_item_title {
      color: #fff;
      a {
        color: #fff;
      }
    }
    &:hover .sc_price_item_title {
      color: #fe7259;
      a {
        color: #fe7259;
      }
    }
    .sc_price_item_price, .sc_price_item_description, .sc_price_item_details {
      color: #fff;
    }
  }
  .sc_promo_icon {
    color: $line2;
  }
  .sc_promo {
    .sc_promo_title, .sc_promo_descr {
      color: #1a181d;
    }
    .sc_promo_content {
      color: #737a80;
    }
  }
  .sc_promo_modern .sc_promo_link2 {
    color: #fff;
    background-color: $line2 !important;
    &:hover {
      color: #fff;
      background-color: #1a181d;
    }
  }
  &.sc_promo {
    .sc_promo_text.trx_addons_stretch_height, .sc_promo_text_inner {
      background-color: #f8f8f8;
    }
    .sc_promo_title {
      color: $line3;
    }
    .sc_promo_subtitle {
      color: #72cfd5;
    }
    .sc_promo_descr {
      color: #1d1d1d;
    }
    .sc_promo_content {
      color: #333;
    }
  }
  .sc_properties_columns_1 .sc_properties_item {
    background-color: #f8f8f8;
  }
  .sc_properties_item_status > a, .sc_properties_item_type > a {
    color: #8b8f95;
  }
  .sc_properties_item_compare {
    color: #8b8f95;
    &.in_compare_list {
      color: $line2;
    }
  }
  .sc_properties_item_status > a:hover, .sc_properties_item_type > a:hover {
    color: #1a181d;
  }
  .sc_properties_item_compare {
    &:hover, &.in_compare_list:hover {
      color: #1a181d;
    }
  }
  .sc_properties_item_options {
    .sc_properties_item_row_address, .sc_properties_item_row_meta {
      color: #8b8f95;
    }
  }
  .properties_page_title .properties_page_status > a {
    color: #fff;
    background-color: $line2;
    &:hover {
      color: #1d1d1d;
      background-color: #fff04f;
    }
  }
  .properties_page_title_address, .properties_price {
    color: #8b8f95;
  }
  .properties_page_attachments_list > a:before, .properties_page_features_list > a:before {
    color: $line2;
  }
  .properties_page_tabs.trx_addons_tabs .trx_addons_tabs_titles {
    border-color: #e5e5e5;
    li {
      > a {
        background-color: #f8f8f8;
        border-color: #f8f8f8;
        border-bottom-color: #e5e5e5;
      }
      &.ui-state-active > a {
        border-color: #e5e5e5;
        background-color: #fff;
        border-bottom-color: #fff;
      }
      &:not(.ui-state-active) > a:hover {
        background-color: #e6e8eb;
        border-color: #e6e8eb #e6e8eb #e5e5e5;
      }
    }
  }
  .properties_page_agent_info_position {
    color: #8b8f95;
  }
  .properties_page_agent_info_phones > {
    span, a {
      color: #737a80;
    }
  }
  .properties_page_agent_info_address:before, .properties_page_agent_info_phones > :before {
    color: #1a181d;
  }
  .properties_page_agent_info_profiles.socials_wrap .social_item .social_icon {
    color: #737a80;
  }
  .properties_search_form .properties_search_basic .properties_search_show_advanced {
    color: #9a9d9f;
    background-color: #f4f6fa;
    &:hover {
      color: #15212c;
    }
  }
  .sc_properties_compare_data .properties_feature_present {
    color: $line2;
  }
  .sc_services .sc_services_item_number {
    color: #e6e8eb;
  }
  .sc_services_default {
    .sc_services_item {
      color: #333;
      background-color: #f8f8f8;
    }
    .sc_services_item_icon {
      color: $line3;
      border-color: $line3;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: #1a181d;
      background-color: $line3;
      border-color: $line3;
    }
    &.color_style_link2 {
      .sc_services_item_icon {
        color: #8be77c;
        border-color: #8be77c;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: #1a181d;
        background-color: #8be77c;
        border-color: #8be77c;
      }
    }
    &.color_style_link3 {
      .sc_services_item_icon {
        color: #eec432;
        border-color: #eec432;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: #1a181d;
        background-color: #eec432;
        border-color: #eec432;
      }
    }
    &.color_style_dark {
      .sc_services_item_icon {
        color: #1d1d1d;
        border-color: #1d1d1d;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: #1a181d;
        background-color: #1d1d1d;
        border-color: #1d1d1d;
      }
    }
    .sc_services_item_subtitle a {
      color: $line3;
      &:hover {
        color: #72cfd5;
      }
    }
    &.color_style_link2 .sc_services_item_subtitle a {
      color: #8be77c;
      &:hover {
        color: #80d572;
      }
    }
    &.color_style_link3 .sc_services_item_subtitle a {
      color: #eec432;
      &:hover {
        color: #ddb837;
      }
    }
    &.color_style_dark .sc_services_item_subtitle a {
      color: #1d1d1d;
      &:hover {
        color: $line3;
      }
    }
    .sc_services_item_featured_left, .sc_services_item_featured_right {
      color: #737a80;
      background-color: transparent;
    }
  }
  .sc_services_list .sc_services_item {
    color: #737a80;
    background-color: transparent;
  }
  .sc_services_default {
    .sc_services_item_featured_left .sc_services_item_icon, .sc_services_item_featured_right .sc_services_item_icon {
      color: $line2;
      border-color: $line2;
    }
  }
  .sc_services_list {
    .sc_services_item_icon {
      color: $line2;
      border-color: $line2;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: $line3;
    }
  }
  .sc_services_default {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #1a181d;
      background-color: $line2;
      border-color: $line2;
    }
  }
  .sc_services_list {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #1a181d;
      background-color: $line2;
      border-color: $line2;
    }
  }
  .sc_services_default {
    .sc_services_item_featured_left .sc_services_item_subtitle a, .sc_services_item_featured_right .sc_services_item_subtitle a {
      color: $line2;
    }
    .sc_services_item_featured_left .sc_services_item_subtitle a:hover, .sc_services_item_featured_right .sc_services_item_subtitle a:hover {
      color: $line3;
    }
    &.color_style_link2 {
      .sc_services_item_featured_left .sc_services_item_icon, .sc_services_item_featured_right .sc_services_item_icon {
        color: $line1;
        border-color: $line1;
      }
    }
  }
  .sc_services_list.color_style_link2 {
    .sc_services_item_icon {
      color: $line1;
      border-color: $line1;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: $line3;
    }
  }
  .sc_services_default.color_style_link2 {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #1a181d;
      background-color: $line1;
      border-color: $line1;
    }
  }
  .sc_services_list.color_style_link2 {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #1a181d;
      background-color: $line1;
      border-color: $line1;
    }
  }
  .sc_services_default {
    &.color_style_link2 {
      .sc_services_item_featured_left .sc_services_item_subtitle a, .sc_services_item_featured_right .sc_services_item_subtitle a {
        color: $line1;
      }
      .sc_services_item_featured_left .sc_services_item_subtitle a:hover, .sc_services_item_featured_right .sc_services_item_subtitle a:hover {
        color: $line3;
      }
    }
    &.color_style_link3 {
      .sc_services_item_featured_left .sc_services_item_icon, .sc_services_item_featured_right .sc_services_item_icon {
        color: $line3;
        border-color: $line3;
      }
    }
  }
  .sc_services_list.color_style_link3 {
    .sc_services_item_icon {
      color: $line3;
      border-color: $line3;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: $line2;
    }
  }
  .sc_services_default.color_style_link3 {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #1a181d;
      background-color: $line3;
      border-color: $line3;
    }
  }
  .sc_services_list.color_style_link3 {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #1a181d;
      background-color: $line3;
      border-color: $line3;
    }
  }
  .sc_services_default {
    &.color_style_link3 {
      .sc_services_item_featured_left .sc_services_item_subtitle a, .sc_services_item_featured_right .sc_services_item_subtitle a {
        color: $line3;
      }
      .sc_services_item_featured_left .sc_services_item_subtitle a:hover, .sc_services_item_featured_right .sc_services_item_subtitle a:hover {
        color: $line2;
      }
    }
    &.color_style_dark {
      .sc_services_item_featured_left .sc_services_item_icon, .sc_services_item_featured_right .sc_services_item_icon {
        color: #1a181d;
        border-color: #1a181d;
      }
    }
  }
  .sc_services_list.color_style_dark {
    .sc_services_item_icon {
      color: #1a181d;
      border-color: #1a181d;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: $line2;
    }
  }
  .sc_services_default.color_style_dark {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #1a181d;
      background-color: #1a181d;
      border-color: #1a181d;
    }
  }
  .sc_services_list.color_style_dark {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #1a181d;
      background-color: #1a181d;
      border-color: #1a181d;
    }
  }
  .sc_services_default.color_style_dark {
    .sc_services_item_featured_left .sc_services_item_subtitle a, .sc_services_item_featured_right .sc_services_item_subtitle a {
      color: #1a181d;
    }
    .sc_services_item_featured_left .sc_services_item_subtitle a:hover, .sc_services_item_featured_right .sc_services_item_subtitle a:hover {
      color: $line2;
    }
  }
  .sc_services_light {
    .sc_services_item_icon {
      color: $line2;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: $line3;
    }
    &.color_style_link2 {
      .sc_services_item_icon {
        color: $line1;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: $line3;
      }
    }
    &.color_style_link3 {
      .sc_services_item_icon {
        color: $line3;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: $line2;
      }
    }
    &.color_style_dark {
      .sc_services_item_icon {
        color: #1a181d;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: $line2;
      }
    }
  }
  .sc_services_callouts {
    .sc_services_item {
      background-color: #f8f8f8;
    }
    .sc_services_item_marker {
      border-color: #fff;
      background-color: $line3;
      color: #fff;
    }
    .sc_services_item .sc_services_item_marker_back {
      border-color: #fff;
      background-color: #72cfd5;
      color: #1d1d1d;
    }
    &.color_style_link2 {
      .sc_services_item_marker {
        background-color: #8be77c;
      }
      .sc_services_item .sc_services_item_marker_back {
        background-color: #80d572;
      }
    }
    &.color_style_link3 {
      .sc_services_item_marker {
        background-color: #eec432;
      }
      .sc_services_item .sc_services_item_marker_back {
        background-color: #ddb837;
      }
    }
    &.color_style_dark {
      .sc_services_item_marker {
        background-color: #1d1d1d;
      }
      .sc_services_item .sc_services_item_marker_back {
        background-color: $line3;
      }
    }
    .sc_services_item_marker_bg {
      border-color: #fff;
      background-color: #fff;
    }
  }
  .sc_services_timeline {
    .sc_services_item_timeline {
      border-color: #e5e5e5;
    }
    .sc_services_item_marker {
      border-color: $line2;
      background-color: $line2;
      color: #fff;
    }
    .sc_services_item:hover .sc_services_item_marker {
      border-color: $line3;
      background-color: $line3;
      color: #1d1d1d;
    }
    &.color_style_link2 {
      .sc_services_item_marker {
        border-color: $line1;
        background-color: $line1;
      }
      .sc_services_item:hover .sc_services_item_marker {
        border-color: $line3;
        background-color: $line3;
      }
    }
    &.color_style_link3 {
      .sc_services_item_marker {
        border-color: $line3;
        background-color: $line3;
      }
      .sc_services_item:hover .sc_services_item_marker {
        border-color: $line2;
        background-color: $line2;
      }
    }
    &.color_style_dark {
      .sc_services_item_marker {
        border-color: #1a181d;
        background-color: #1a181d;
      }
      .sc_services_item:hover .sc_services_item_marker {
        border-color: $line2;
        background-color: $line2;
      }
    }
  }
  .sc_services_iconed {
    .sc_services_item {
      color: #333;
      background-color: #f8f8f8;
    }
    .sc_services_item_icon:hover, .sc_services_item:hover .sc_services_item_icon, .sc_services_item_header .sc_services_item_subtitle a:hover, .sc_services_item:hover .sc_services_item_header .sc_services_item_subtitle a {
      color: $line2;
    }
    &.color_style_link2 {
      .sc_services_item_icon:hover, .sc_services_item:hover .sc_services_item_icon, .sc_services_item_header .sc_services_item_subtitle a:hover, .sc_services_item:hover .sc_services_item_header .sc_services_item_subtitle a {
        color: $line1;
      }
    }
    &.color_style_link3 {
      .sc_services_item_icon:hover, .sc_services_item:hover .sc_services_item_icon, .sc_services_item_header .sc_services_item_subtitle a:hover, .sc_services_item:hover .sc_services_item_header .sc_services_item_subtitle a {
        color: $line3;
      }
    }
    .sc_services_item_header .sc_services_item_title a {
      color: $line2;
    }
    &.color_style_link2 .sc_services_item_header .sc_services_item_title a {
      color: $line1;
    }
    &.color_style_link3 .sc_services_item_header .sc_services_item_title a {
      color: $line3;
    }
    .sc_services_item_header .sc_services_item_title a:hover {
      color: #fff;
    }
    .sc_services_item {
      &:hover .sc_services_item_header .sc_services_item_title a, .sc_services_item_header .sc_services_item_subtitle a {
        color: #fff;
      }
      &:hover .sc_services_item_header .sc_services_item_subtitle a, .sc_services_item_header .sc_services_item_subtitle a:hover {
        color: $line2;
      }
    }
    &.color_style_link2 .sc_services_item {
      &:hover .sc_services_item_header .sc_services_item_subtitle a, .sc_services_item_header .sc_services_item_subtitle a:hover {
        color: $line1;
      }
    }
    &.color_style_link3 .sc_services_item {
      &:hover .sc_services_item_header .sc_services_item_subtitle a, .sc_services_item_header .sc_services_item_subtitle a:hover {
        color: $line3;
      }
    }
    .sc_services_item_content .sc_services_item_title a {
      color: #1d1d1d;
      &:hover {
        color: $line3;
      }
    }
    .sc_services_item:hover .sc_services_item_content .sc_services_item_title a {
      color: $line3;
    }
    &.color_style_link2 {
      .sc_services_item_content .sc_services_item_title a:hover, .sc_services_item:hover .sc_services_item_content .sc_services_item_title a {
        color: #8be77c;
      }
    }
    &.color_style_link3 {
      .sc_services_item_content .sc_services_item_title a:hover, .sc_services_item:hover .sc_services_item_content .sc_services_item_title a {
        color: #eec432;
      }
    }
    &.color_style_dark {
      .sc_services_item_content .sc_services_item_title a:hover, .sc_services_item:hover .sc_services_item_content .sc_services_item_title a {
        color: #1d1d1d;
      }
    }
  }
  .sc_services.slider_container .swiper-pagination-bullet {
    border-color: #8b8f95;
  }
  .sc_services_list {
    .sc_services_item_featured_left .sc_services_item_number, .sc_services_item_featured_right .sc_services_item_number {
      color: #8b8f95;
    }
  }
  .sc_services_hover {
    .sc_services_item_icon, .sc_services_item_title a:hover, .sc_services_item_subtitle a:hover {
      color: $line2;
    }
    &.color_style_link2 {
      .sc_services_item_icon, .sc_services_item_title a:hover, .sc_services_item_subtitle a:hover {
        color: $line1;
      }
    }
    &.color_style_link3 {
      .sc_services_item_icon, .sc_services_item_title a:hover, .sc_services_item_subtitle a:hover {
        color: $line3;
      }
    }
    [class*="column-"]:nth-child(2n) .sc_services_item.with_image .sc_services_item_header.without_image, .slider-slide:nth-child(2n) .sc_services_item.with_image .sc_services_item_header.without_image {
      background-color: #e6e8eb;
    }
    [class*="column-"]:nth-child(2n+1) .sc_services_item.with_image .sc_services_item_header.without_image, .slider-slide:nth-child(2n+1) .sc_services_item.with_image .sc_services_item_header.without_image {
      background-color: #f8f8f8;
    }
    .sc_services_item.with_image {
      .sc_services_item_header.without_image {
        .sc_services_item_icon, .sc_services_item_number {
          color: #b7b7b7;
        }
        .sc_services_item_title a {
          color: #1d1d1d;
        }
      }
      &:hover .sc_services_item_header.without_image .sc_services_item_title a, .sc_services_item_header.without_image .sc_services_item_title a:hover {
        color: $line3;
      }
    }
    &.color_style_link2 .sc_services_item.with_image {
      &:hover .sc_services_item_header.without_image .sc_services_item_title a, .sc_services_item_header.without_image .sc_services_item_title a:hover {
        color: #8be77c;
      }
    }
    &.color_style_link3 .sc_services_item.with_image {
      &:hover .sc_services_item_header.without_image .sc_services_item_title a, .sc_services_item_header.without_image .sc_services_item_title a:hover {
        color: #eec432;
      }
    }
    .sc_services_item.with_image .sc_services_item_header.without_image .sc_services_item_subtitle a {
      color: $line3;
    }
    &.color_style_link2 .sc_services_item.with_image .sc_services_item_header.without_image .sc_services_item_subtitle a {
      color: #8be77c;
    }
    &.color_style_link3 .sc_services_item.with_image .sc_services_item_header.without_image .sc_services_item_subtitle a {
      color: #eec432;
    }
    .sc_services_item.with_image {
      &:hover .sc_services_item_header.without_image .sc_services_item_subtitle a, .sc_services_item_header.without_image .sc_services_item_subtitle a:hover {
        color: #72cfd5;
      }
    }
    &.color_style_link2 .sc_services_item.with_image {
      &:hover .sc_services_item_header.without_image .sc_services_item_subtitle a, .sc_services_item_header.without_image .sc_services_item_subtitle a:hover {
        color: #80d572;
      }
    }
    &.color_style_link3 .sc_services_item.with_image {
      &:hover .sc_services_item_header.without_image .sc_services_item_subtitle a, .sc_services_item_header.without_image .sc_services_item_subtitle a:hover {
        color: #ddb837;
      }
    }
    .sc_services_item.with_image .sc_services_item_header.without_image .sc_services_item_text {
      color: #333;
    }
  }
  .sc_services_chess {
    .sc_services_item {
      color: #333;
      background-color: #f8f8f8;
    }
    .sc_services_item_title a {
      color: #1d1d1d;
      &:hover {
        color: $line3;
      }
    }
    &.color_style_link2 .sc_services_item_title a:hover {
      color: #8be77c;
    }
    &.color_style_link3 .sc_services_item_title a:hover {
      color: #eec432;
    }
    .sc_services_item:hover {
      color: #8b8f95;
      background-color: #1a181d;
      .sc_services_item_title a {
        color: #fff;
        &:hover {
          color: $line2;
        }
      }
    }
  }
  .sc_services_tabs_simple {
    .sc_services_item_icon {
      color: $line2;
    }
    &.color_style_link2 .sc_services_item_icon {
      color: $line1;
    }
    &.color_style_link3 .sc_services_item_icon {
      color: $line3;
    }
    &.color_style_dark .sc_services_item_icon {
      color: #1a181d;
    }
    .sc_services_item:hover {
      .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
        color: $line3;
      }
    }
    .sc_services_tabs_list_item_active {
      .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
        color: $line3;
      }
    }
    &.color_style_link2 {
      .sc_services_item:hover {
        .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
          color: $line3;
        }
      }
      .sc_services_tabs_list_item_active {
        .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
          color: $line3;
        }
      }
    }
    &.color_style_link3 {
      .sc_services_item:hover {
        .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
          color: $line2;
        }
      }
      .sc_services_tabs_list_item_active {
        .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
          color: $line2;
        }
      }
    }
  }
  .sc_services_tabs {
    .sc_services_item_content {
      color: #333;
      background-color: #f8f8f8;
    }
    .sc_services_item_title a {
      color: #1d1d1d;
      &:hover {
        color: $line3;
      }
    }
    &.color_style_link2 .sc_services_item_title a:hover {
      color: #8be77c;
    }
    &.color_style_link3 .sc_services_item_title a:hover {
      color: #eec432;
    }
    .sc_services_tabs_list_item .sc_services_item_icon {
      color: $line3;
    }
    &.color_style_link2 .sc_services_tabs_list_item .sc_services_item_icon {
      color: #8be77c;
    }
    &.color_style_link3 .sc_services_tabs_list_item .sc_services_item_icon {
      color: #eec432;
    }
    .sc_services_tabs_list_item {
      .sc_services_item_number {
        color: #b7b7b7;
      }
      background-color: #f8f8f8;
      &:nth-child(2n+2) {
        background-color: #e6e8eb;
      }
      &:hover, &:nth-child(2n+2):hover {
        background-color: #dadada;
      }
      .sc_services_item_title {
        color: #1d1d1d;
      }
      &:hover .sc_services_item_title {
        color: $line3;
      }
    }
    &.color_style_link2 .sc_services_tabs_list_item:hover .sc_services_item_title {
      color: #8be77c;
    }
    &.color_style_link3 .sc_services_tabs_list_item:hover .sc_services_item_title {
      color: #eec432;
    }
    &.color_style_dark .sc_services_tabs_list_item:hover .sc_services_item_title {
      color: #1d1d1d;
    }
    .sc_services_tabs_list_item:hover .sc_services_item_icon {
      color: #72cfd5;
    }
    &.color_style_link2 .sc_services_tabs_list_item:hover .sc_services_item_icon {
      color: #80d572;
    }
    &.color_style_link3 .sc_services_tabs_list_item:hover .sc_services_item_icon {
      color: #ddb837;
    }
    &.color_style_dark .sc_services_tabs_list_item:hover .sc_services_item_icon {
      color: #1d1d1d;
    }
    .sc_services_tabs_list_item {
      &:hover .sc_services_item_number {
        color: #333;
      }
      &.sc_services_tabs_list_item_active {
        background-color: #1d1d1d !important;
        .sc_services_item_title {
          color: #fff;
        }
        .sc_services_item_icon, .sc_services_item_number {
          color: $line3;
        }
      }
    }
    &.color_style_link2 .sc_services_tabs_list_item.sc_services_tabs_list_item_active {
      .sc_services_item_icon, .sc_services_item_number {
        color: #8be77c;
      }
    }
    &.color_style_link3 .sc_services_tabs_list_item.sc_services_tabs_list_item_active {
      .sc_services_item_icon, .sc_services_item_number {
        color: #eec432;
      }
    }
    &.color_style_dark .sc_services_tabs_list_item.sc_services_tabs_list_item_active {
      .sc_services_item_icon, .sc_services_item_number {
        color: #1d1d1d;
      }
    }
  }
  .sc_skills_counter .sc_skills_icon {
    color: #1a181d;
  }
  .sc_skills {
    .sc_skills_total {
      color: $line2;
    }
    &.color_style_link2 .sc_skills_total {
      color: $line1;
    }
    &.color_style_link3 .sc_skills_total {
      color: $line3;
    }
    &.color_style_dark .sc_skills_total, .sc_skills_item_title, .sc_skills_legend_title, .sc_skills_legend_value {
      color: #1a181d;
    }
  }
  .sc_skills_counter .sc_skills_column + .sc_skills_column:before {
    background-color: #e5e5e5;
  }
  .socials_wrap .social_item {
    .social_icon {
      background-color: #f8f8f8;
      color: #1d1d1d;
      i {
        color: #1d1d1d;
      }
    }
    &:hover .social_icon {
      background-color: #e6e8eb;
      color: $line3;
      i {
        color: $line3;
      }
    }
  }
  .sidebar_inner .socials_wrap .social_item {
    .social_icon {
      background-color: #e6e8eb;
    }
    &:hover .social_icon {
      color: #fff;
      i {
        color: #fff;
      }
      background-color: #72cfd5;
    }
  }
  .footer_wrap .socials_wrap .social_item .social_icon, &.footer_wrap .socials_wrap .social_item .social_icon {
    color: #fff;
    background-color: $line3;
  }
  .footer_wrap .socials_wrap .social_item:hover .social_icon, &.footer_wrap .socials_wrap .social_item:hover .social_icon {
    color: #1d1d1d;
    background-color: #72cfd5;
  }
  .footer_wrap .sc_layouts_row_type_compact .socials_wrap .social_item .social_icon, &.footer_wrap .sc_layouts_row_type_compact .socials_wrap .social_item .social_icon {
    color: #1a181d;
    background-color: transparent;
  }
  .footer_wrap .sc_layouts_row_type_compact .socials_wrap .social_item:hover .social_icon, &.footer_wrap .sc_layouts_row_type_compact .socials_wrap .social_item:hover .social_icon {
    color: $line2;
    background-color: transparent;
  }
  .socials_share.socials_type_drop .social_item {
    > .social_icon > i {
      color: #8b8f95;
    }
    &:hover > .social_icon > i {
      color: #1a181d;
    }
  }
  .sc_testimonials_item_content {
    color: #1a181d;
    &:before {
      color: $line2;
    }
  }
  .sc_testimonials_item_author_title {
    color: $line2;
  }
  .color_style_link2 {
    .sc_testimonials_item_content:before, .sc_testimonials_item_author_title {
      color: $line1;
    }
  }
  .color_style_link3 {
    .sc_testimonials_item_content:before, .sc_testimonials_item_author_title {
      color: $line3;
    }
  }
  .color_style_dark {
    .sc_testimonials_item_content:before, .sc_testimonials_item_author_title {
      color: #1a181d;
    }
  }
  .sc_testimonials_item_author_subtitle {
    color: #8b8f95;
  }
  .sc_testimonials_simple {
    .sc_testimonials_item_author_data:before {
      background-color: #8b8f95;
    }
    [class*="column"] .sc_testimonials_item_author_data {
      border-color: #8b8f95;
    }
  }
  .sc_team_default .sc_team_item {
    color: #333;
    background-color: #f8f8f8;
  }
  .sc_team {
    .sc_team_item_thumb .sc_team_item_title a:hover {
      color: $line3;
    }
    &.color_style_link2 .sc_team_item_thumb .sc_team_item_title a:hover {
      color: #8be77c;
    }
    &.color_style_link3 .sc_team_item_thumb .sc_team_item_title a:hover {
      color: #eec432;
    }
  }
  .sc_team_default {
    .sc_team_item_subtitle {
      color: $line3;
    }
    &.color_style_link2 .sc_team_item_subtitle {
      color: #8be77c;
    }
    &.color_style_link3 .sc_team_item_subtitle {
      color: #eec432;
    }
    &.color_style_dark .sc_team_item_subtitle {
      color: #1d1d1d;
    }
    .sc_team_item_socials .social_item .social_icon {
      color: #fff;
      background-color: $line3;
    }
  }
  .team_member_page .team_member_socials .social_item .social_icon {
    color: #fff;
    background-color: $line3;
  }
  .sc_team_default {
    &.color_style_link2 .sc_team_item_socials .social_item .social_icon {
      background-color: #8be77c;
    }
    &.color_style_link3 .sc_team_item_socials .social_item .social_icon {
      background-color: #eec432;
    }
    &.color_style_dark .sc_team_item_socials .social_item .social_icon {
      background-color: #1d1d1d;
    }
    .sc_team_item_socials .social_item:hover .social_icon {
      color: #f8f8f8;
      background-color: #1d1d1d;
    }
  }
  .team_member_page .team_member_socials .social_item:hover .social_icon {
    color: #f8f8f8;
    background-color: #1d1d1d;
  }
  .sc_team_default {
    &.color_style_link2 .sc_team_item_socials .social_item:hover .social_icon {
      background-color: #80d572;
    }
    &.color_style_link3 .sc_team_item_socials .social_item:hover .social_icon {
      background-color: #ddb837;
    }
    &.color_style_dark .sc_team_item_socials .social_item:hover .social_icon {
      background-color: $line3;
    }
  }
  .sc_team .sc_team_item_thumb .sc_team_item_socials .social_item {
    .social_icon {
      color: #1a181d;
      background-color: rgba(255, 255, 255, 0);
    }
    &:hover .social_icon {
      color: $line3;
      background-color: rgba(255, 255, 255, 0);
    }
  }
  .team_member_page .team_member_featured .team_member_avatar {
    border-color: #e5e5e5;
  }
  .sc_team_short {
    .sc_team_item_thumb {
      border-color: $line2;
    }
    &.color_style_link2 .sc_team_item_thumb {
      border-color: $line1;
    }
    &.color_style_link3 .sc_team_item_thumb {
      border-color: $line3;
    }
    &.color_style_dark .sc_team_item_thumb {
      border-color: #1a181d;
    }
  }
  .sc_team.slider_container .swiper-pagination-bullet {
    border-color: #8b8f95;
  }
  .sport_page_list {
    border-color: #e5e5e5;
    li {
      + li {
        border-color: #e5e5e5;
      }
      &:nth-child(2n+1) {
        background-color: #f8f8f8;
        color: #333;
      }
    }
  }
  .trx_addons_scroll_to_top, .trx_addons_cv .trx_addons_scroll_to_top {
    color: #fff;
    border-color: $line2;
    background-color: $line2;
  }
  .trx_addons_scroll_to_top:hover, .trx_addons_cv .trx_addons_scroll_to_top:hover {
    color: #1d1d1d;
    border-color: #fff04f;
    background-color: #fff04f;
  }
  .trx_addons_popup {
    background-color: #f8f8f8;
    border-color: #e5e5e5;
    color: #333;
    button.mfp-close {
      background-color: #e6e8eb;
      border-color: #dadada;
      color: #333;
      &:hover {
        background-color: #1d1d1d;
        color: #f8f8f8;
      }
    }
    .trx_addons_tabs_titles li.trx_addons_tabs_title {
      background-color: #e6e8eb;
      border-color: #dadada;
      &.ui-tabs-active {
        background-color: #f8f8f8;
        border-bottom-color: transparent;
      }
      a {
        color: #333;
        > i {
          color: #333;
        }
      }
    }
    li.trx_addons_tabs_title a:hover {
      color: $line3;
    }
    .trx_addons_tabs_titles li.trx_addons_tabs_title {
      a:hover > i {
        color: $line3;
      }
      &[data-disabled="true"] a {
        color: #b7b7b7;
        > i {
          color: #b7b7b7;
        }
        &:hover {
          color: #b7b7b7;
          > i {
            color: #b7b7b7;
          }
        }
      }
      &.ui-tabs-active a {
        color: #1d1d1d;
        > i {
          color: #1d1d1d;
        }
        &:hover {
          color: #1d1d1d;
          > i {
            color: #1d1d1d;
          }
        }
      }
    }
  }
  .trx_addons_profiler {
    background-color: #f8f8f8;
    border-color: #dadada;
  }
  .trx_addons_profiler_title {
    color: #1d1d1d;
  }
  .trx_addons_profiler table {
    td, th {
      border-color: #e5e5e5;
    }
    td {
      color: #333;
    }
    th {
      background-color: #e6e8eb;
      color: #1d1d1d;
    }
  }
  .trx_addons_cv, .trx_addons_cv_body_wrap {
    color: #333;
    background-color: #f8f8f8;
  }
  .trx_addons_cv a {
    color: $line3;
    &:hover {
      color: #72cfd5;
    }
  }
  .trx_addons_cv_header {
    background-color: #fff;
  }
  .trx_addons_cv_header_image img {
    border-color: #1a181d;
  }
  .trx_addons_cv_header {
    .trx_addons_cv_header_letter, .trx_addons_cv_header_text {
      color: #1a181d;
    }
    .trx_addons_cv_header_socials .social_item {
      > .social_icon {
        color: rgba(26, 24, 29, 0.7);
      }
      &:hover > .social_icon {
        color: #1a181d;
      }
    }
  }
  .trx_addons_cv_header_letter, .trx_addons_cv_header_text, .trx_addons_cv_header_socials .social_item > .social_icon {
    text-shadow: 1px 1px 6px #fff;
  }
  .trx_addons_cv_tint_dark {
    .trx_addons_cv_header_letter, .trx_addons_cv_header_text {
      color: #fff;
      text-shadow: 1px 1px 3px #1a181d;
    }
    .trx_addons_cv_header_socials .social_item {
      > .social_icon {
        color: #fff;
        text-shadow: 1px 1px 3px #1a181d;
      }
      &:hover > .social_icon {
        color: $line3;
      }
    }
  }
  .trx_addons_cv_navi_buttons .trx_addons_cv_navi_buttons_area {
    .trx_addons_cv_navi_buttons_item {
      color: #b7b7b7;
      background-color: #f8f8f8;
      border-color: #fff;
    }
    .trx_addons_cv_navi_buttons_item_active, .trx_addons_cv_navi_buttons_item:hover {
      color: #1d1d1d;
      border-color: #f8f8f8;
    }
  }
  .trx_addons_cv .trx_addons_cv_section_title {
    color: #1d1d1d;
    a {
      color: #1d1d1d;
    }
  }
  .trx_addons_cv_section_title.ui-state-active {
    border-color: #1d1d1d;
  }
  .trx_addons_cv_section_content .trx_addons_tabs .trx_addons_tabs_titles li {
    > a {
      color: #b7b7b7;
    }
    &.ui-state-active > a, > a:hover {
      color: #1d1d1d;
    }
  }
  .trx_addons_cv_section .trx_addons_pagination > {
    * {
      color: #333;
    }
    a:hover {
      color: #1d1d1d;
    }
  }
  .trx_addons_pagination > span.active {
    color: #1d1d1d;
    border-color: #1d1d1d;
  }
  .trx_addons_cv_breadcrumbs {
    .trx_addons_cv_breadcrumbs_item {
      color: #b7b7b7;
    }
    a.trx_addons_cv_breadcrumbs_item:hover {
      color: #1d1d1d;
    }
  }
  .trx_addons_cv_single {
    .trx_addons_cv_single_title {
      color: #1d1d1d;
    }
    .trx_addons_cv_single_subtitle {
      color: #b7b7b7;
    }
  }
  .trx_addons_tabs_content_delimiter {
    .trx_addons_cv_resume_columns .trx_addons_cv_resume_item, .trx_addons_cv_resume_columns_2 .trx_addons_cv_resume_column:nth-child(2n+2) .trx_addons_cv_resume_item {
      border-color: #e5e5e5;
    }
    .trx_addons_cv_resume_columns_3 .trx_addons_cv_resume_column {
      &:nth-child(3n+2) .trx_addons_cv_resume_item, &:nth-child(3n+3) .trx_addons_cv_resume_item {
        border-color: #e5e5e5;
      }
    }
    .trx_addons_cv_resume_columns_4 .trx_addons_cv_resume_column {
      &:nth-child(4n+2) .trx_addons_cv_resume_item, &:nth-child(4n+3) .trx_addons_cv_resume_item, &:nth-child(4n+4) .trx_addons_cv_resume_item {
        border-color: #e5e5e5;
      }
    }
    .trx_addons_cv_resume_columns_2 .trx_addons_cv_resume_column {
      &:nth-child(2n+3) .trx_addons_cv_resume_item, &:nth-child(2n+4) .trx_addons_cv_resume_item {
        border-color: #e5e5e5;
      }
    }
    .trx_addons_cv_resume_columns_3 .trx_addons_cv_resume_column {
      &:nth-child(3n+4) .trx_addons_cv_resume_item, &:nth-child(3n+5) .trx_addons_cv_resume_item, &:nth-child(3n+6) .trx_addons_cv_resume_item {
        border-color: #e5e5e5;
      }
    }
    .trx_addons_cv_resume_columns_4 .trx_addons_cv_resume_column {
      &:nth-child(4n+5) .trx_addons_cv_resume_item, &:nth-child(4n+6) .trx_addons_cv_resume_item, &:nth-child(4n+7) .trx_addons_cv_resume_item, &:nth-child(4n+8) .trx_addons_cv_resume_item {
        border-color: #e5e5e5;
      }
    }
  }
  .trx_addons_cv_resume_item_meta {
    color: #1d1d1d;
  }
  .trx_addons_cv_resume_item .trx_addons_cv_resume_item_title {
    color: #1d1d1d;
    a {
      color: #1d1d1d;
      &:hover {
        color: $line3;
      }
    }
  }
  .trx_addons_cv_resume_item_subtitle {
    color: #b7b7b7;
  }
  .trx_addons_cv_resume_style_skills {
    .trx_addons_cv_resume_item_skills {
      color: #1d1d1d;
    }
    .trx_addons_cv_resume_item_skill:after {
      border-color: #1d1d1d;
    }
  }
  .trx_addons_cv_resume_style_education .trx_addons_cv_resume_item_number {
    color: #b7b7b7;
  }
  .trx_addons_cv_resume_style_services {
    .trx_addons_cv_resume_item_icon {
      color: #1d1d1d;
      &:hover {
        color: $line3;
      }
    }
    .trx_addons_cv_resume_item_text a:hover {
      color: $line3;
    }
    .trx_addons_cv_resume_item_title > a {
      &:hover:after {
        border-color: $line3;
      }
      &:after {
        border-top-color: #1d1d1d;
      }
    }
    .trx_addons_cv_resume_item_text a {
      color: #1d1d1d;
    }
  }
  .trx_addons_cv_portfolio_item .trx_addons_cv_portfolio_item_title, .trx_addons_cv_testimonials_item .trx_addons_cv_testimonials_item_title, .trx_addons_cv_certificates_item .trx_addons_cv_certificates_item_title {
    color: #1d1d1d;
    a {
      color: #1d1d1d;
    }
  }
  .trx_addons_cv {
    .trx_addons_contact_form .trx_addons_contact_form_title, .trx_addons_contact_form_field_title {
      color: #1d1d1d;
    }
  }
  .trx_addons_contact_form .trx_addons_contact_form_field {
    input[type="text"], textarea {
      border-color: #e5e5e5;
      color: #333;
    }
    input[type="text"]:focus, textarea:focus {
      background-color: #e6e8eb;
      color: #1d1d1d;
    }
  }
  .trx_addons_contact_form_field button {
    background-color: #1d1d1d;
    border-color: #1d1d1d;
    color: #fff;
    &:hover {
      color: #1d1d1d;
    }
  }
  .trx_addons_contact_form_info_icon {
    color: #b7b7b7;
  }
  .trx_addons_contact_form_info_area {
    color: #1d1d1d;
  }
  .trx_addons_contact_form_info_item_phone .trx_addons_contact_form_info_data {
    color: #1d1d1d !important;
  }
  .trx_addons_cv_about_page .trx_addons_cv_single_title {
    color: #1d1d1d;
  }
  .trx_addons_attrib_item {
    &.trx_addons_attrib_button, &.trx_addons_attrib_image, &.trx_addons_attrib_color {
      border-color: #e5e5e5;
      background-color: #f8f8f8;
    }
    &.trx_addons_attrib_button:hover, &.trx_addons_attrib_image:hover, &.trx_addons_attrib_color:hover {
      border-color: #dadada;
      background-color: #e6e8eb;
    }
    &.trx_addons_attrib_selected {
      border-color: $line3 !important;
      background-color: #e6e8eb;
    }
    &.trx_addons_attrib_disabled span {
      &:before, &:after {
        background-color: #72cfd5;
      }
    }
  }
  .trx_addons_range_slider_label_min, .trx_addons_range_slider_label_max {
    color: #333;
  }
  div.ui-slider {
    background-color: #f8f8f8;
    border-color: #e5e5e5;
    .ui-slider-handle {
      border-color: #dadada;
      background-color: #e6e8eb;
    }
    .ui-slider-range {
      background-color: #e6e8eb;
    }
  }
  .trx_addons_accent, .trx_addons_accent_big {
    color: #1a181d;
  }
  .trx_addons_accent > {
    a, * {
      color: #1a181d;
    }
  }
  .trx_addons_accent_hovered {
    color: $line3;
  }
  .trx_addons_accent_bg {
    background-color: $line2;
    color: #1a181d;
  }
  .trx_addons_tooltip {
    color: #1a181d;
    border-color: #1a181d;
    &:before {
      background-color: $line1;
      color: #fff;
    }
    &:after {
      border-top-color: $line1;
    }
  }
  .trx_addons_dropcap_style_1 {
    background: $line3;
    color: #fff;
  }
  .trx_addons_dropcap_style_2 {
    background: $line2;
    color: #1a181d;
  }
  blockquote {
    color: rgba(255, 255, 255, 0.8);
    > {
      a, p > a, cite, p > cite {
        color: #fff;
      }
      a, p > a:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  figure figcaption {
    color: #fff;
    background-color: rgba(84, 185, 253, 0.85);
  }
  .wp-caption {
    .wp-caption-text, .wp-caption-dd {
      color: #fff;
      background-color: rgba(84, 185, 253, 0.85);
    }
  }
  .wp-caption-overlay .wp-caption {
    .wp-caption-text, .wp-caption-dd {
      color: #fff;
      background-color: rgba(84, 185, 253, 0.85);
    }
  }
  ul {
    &[class*="trx_addons_list_custom"] > li:before, &[class*="trx_addons_list"] > li:before {
      color: $line3;
    }
    &[class*="trx_addons_list_dot"] > li:before {
      color: $line1;
    }
  }
  .sc_layouts_menu_nav > li {
    > a {
      color: #1a181d !important;
      &:hover {
        color: $line3 !important;
      }
    }
    &.sfHover > a, &.current-menu-item > a, &.current-menu-parent > a, &.current-menu-ancestor > a {
      color: $line3 !important;
    }
    ul {
      background-color: #15212c;
      border-bottom-color: $line2 !important;
    }
  }
  .sc_layouts_menu_popup .sc_layouts_menu_nav > li > a, .sc_layouts_menu_nav > li li > a {
    color: #fff !important;
  }
  .sc_layouts_menu_popup .sc_layouts_menu_nav > li {
    > a:hover, &.sfHover > a {
      color: $line3 !important;
      background-color: rgba(255, 255, 255, 0);
    }
  }
  .sc_layouts_menu_nav > li li {
    > a:hover, &.sfHover > a, &.current-menu-item > a, &.current-menu-parent > a, &.current-menu-ancestor > a {
      color: $line3 !important;
      background-color: rgba(255, 255, 255, 0);
    }
  }
  .sc_layouts_title_caption {
    color: #1a181d;
  }
  .sc_layouts_title_breadcrumbs a, .breadcrumbs_item.current {
    color: #1a181d !important;
  }
  .sc_layouts_title_breadcrumbs a:hover {
    color: $line2 !important;
  }
  .sc_slider_controls .slider_controls_wrap > a, .slider_container.slider_controls_side .slider_controls_wrap > a, .slider_outer_controls_side .slider_controls_wrap > a {
    color: #1a181d;
    background-color: #f8f8f8;
  }
  .sc_slider_controls .slider_controls_wrap > a:hover, .slider_container.slider_controls_side .slider_controls_wrap > a:hover, .slider_outer_controls_side .slider_controls_wrap > a:hover {
    color: #fff;
    background-color: #1a181d;
  }
  .sc_price .trx_addons_columns_wrap > div:last-child .sc_price_item {
    color: #737a80;
    background-color: #fff;
    border-color: #e5e5e5;
  }
  .sc_price_item {
    color: #737a80;
    background-color: #fff;
    border-color: #e5e5e5;
    &:hover {
      color: #737a80;
      background-color: #fff;
      border-color: #e5e5e5;
    }
    .sc_price_item_icon {
      color: $line2;
    }
    &:hover .sc_price_item_icon {
      color: $line3;
    }
    .sc_price_item_label, &:hover .sc_price_item_label {
      background-color: $line2;
      color: #fff;
    }
    .sc_price_item_subtitle {
      color: #1a181d;
    }
    .sc_price_item_title, &:hover .sc_price_item_title {
      color: #1a181d;
      a {
        color: #1a181d;
      }
    }
    .sc_price_item_price {
      color: $line3;
    }
    .sc_price_item_description, .sc_price_item_details {
      color: #737a80;
    }
  }
  .price-header:after {
    background: $line3;
  }
  .sc_price .trx_addons_columns_wrap > div {
    &:nth-child(3n+2) .sc_price_item .sc_price_item_link {
      color: #fff;
    }
    &:nth-child(3n+1) .sc_price_item .sc_price_item_link {
      color: #fff;
      &:hover {
        color: #1a181d !important;
      }
    }
    &:nth-child(3n+2) .sc_price_item .price-header:after {
      background: $line1;
    }
    &:nth-child(3n+3) .sc_price_item .price-header:after {
      background: $line2;
    }
    &:nth-child(3n+2) .sc_price_item .sc_price_item_price {
      color: $line1;
    }
    &:nth-child(3n+3) .sc_price_item .sc_price_item_price {
      color: #1a181d;
    }
  }
  .author_info:after, .sc_content_bordered:after {
    background: linear-gradient(to bottom, $line1 0%, $line1 33.33%, $line2 33.33%, $line2 66.66%, $line3 66.66%, $line3 100%);
  }
  .sc_layouts_logo .logo_text, .trx_addons_scroll_to_top {
    color: #1a181d;
  }
  .sc_layouts_row_type_normal .sc_layouts_item a:hover .sc_layouts_item_icon, .sc_layouts_item_icon, .sc_layouts_item_details_line1 {
    color: $line3;
  }
  .sc_layouts_row_type_normal {
    .sc_layouts_item a:hover .sc_layouts_item_details_line2 {
      color: $line3;
    }
    .sc_layouts_column .search_style_fullscreen:not(.search_opened) .search_submit {
      background: #15212c;
      &:hover {
        background: $line3;
      }
      &:before {
        color: #fff;
      }
    }
  }
  .sc_skills_pie.sc_skills_compact_off {
    .sc_skills_total {
      color: #1a181d;
    }
    .sc_skills_item_title {
      color: #737a80;
    }
  }
  .format-video .post_featured.with_thumb .mask, .trx_addons_video_player.with_cover .video_mask {
    background: linear-gradient(to top, rgba(26, 24, 29, 0.7) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
  }
  .format-audio .post_featured.without_thumb .post_audio {
    background: #f4f6fa;
  }
  .mejs-controls {
    .mejs-horizontal-volume-slider .mejs-horizontal-volume-current, .mejs-time-rail .mejs-time-current {
      background: $line3;
    }
    .mejs-button {
      background: rgba(255, 255, 255, 0);
      color: $line3;
      &:hover {
        background: rgba(255, 255, 255, 0);
        color: #1a181d;
      }
    }
  }
  .trx_addons_audio_player .mejs-controls {
    .mejs-horizontal-volume-slider .mejs-horizontal-volume-total:before, .mejs-time-rail .mejs-time-total:before {
      background: #e5e5e5;
    }
  }
  .mejs-controls .mejs-time-rail {
    .mejs-time-total, .mejs-time-loaded {
      background: #e5e5e5;
    }
  }
  .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
    background: #e5e5e5;
  }
  .without_thumb .mejs-controls {
    .mejs-currenttime, .mejs-duration {
      color: #737a80;
    }
  }
  .trx_addons_audio_player.without_cover .audio_author, .format-audio .post_featured .post_audio_author, .trx_addons_audio_player .mejs-container .mejs-controls .mejs-time {
    color: #737a80;
  }
  .trx_addons_label_text {
    color: #1a181d;
  }
  .sc_content_bordered a {
    color: $line3;
    &:hover {
      color: #1a181d;
    }
  }
  .post_item_single {
    &.post_type_post.post_format_audio .mejs-controls .mejs-button:hover {
      color: #fff;
    }
    .post_content .post_share .socials_wrap .social_item {
      &:nth-child(4n+1) .social_icon {
        color: $line3;
      }
      &:nth-child(4n+2) .social_icon {
        color: $line1;
      }
      &:nth-child(4n+3) .social_icon {
        color: $line2;
      }
      &:nth-child(4n+4) .social_icon {
        color: #1a181d;
      }
      &:hover .social_icon {
        color: #737a80 !important;
      }
    }
  }
  .comments_list_wrap .comment_info {
    color: #8b8f95;
  }
  .comment_author {
    color: $line3;
  }
  .comments_list_wrap .comment_author:after {
    color: #8b8f95;
  }
  .sc_item_subtitle, .sc_services_light .sc_services_item_title a:hover, .single-cpt_services ul[class*="trx_addons_list_dot"] > li:before {
    color: $line3;
  }
  .gyges .tp-tab {
    background-color: rgba(21, 33, 44, 0.3);
    &:hover, &.selected {
      background-color: rgba(21, 33, 44, 0.9);
    }
  }
  .sc_team_short {
    .sc_team_item_title a {
      color: #737a80;
      &:hover {
        color: #1a181d;
      }
    }
    .sc_team_item_subtitle {
      color: $line3;
    }
    .sc_team_columns_wrap > div {
      &:nth-child(3n+2) .sc_team_item_subtitle {
        color: $line1;
      }
      &:nth-child(3n+3) .sc_team_item_subtitle {
        color: #1a181d;
      }
    }
    .sc_team_item_thumb {
      border-color: $line3;
    }
    .sc_team_columns_wrap > div {
      &:nth-child(3n+2) .sc_team_item_thumb {
        border-color: $line1;
      }
      &:nth-child(3n+3) .sc_team_item_thumb {
        border-color: $line2;
      }
    }
    .trx_addons_hover_mask {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
  .scheme_dark {
    .sc_item_subtitle {
      color: $line2;
    }
    .sc_testimonials_item_content {
      color: rgba(255, 255, 255, 0.4);
    }
  }
  .sc_testimonials_item_author_title {
    color: #1a181d;
  }
  .slider_container {
    &.slider_controls_top .slider_controls_wrap > a, &.slider_controls_bottom .slider_controls_wrap > a {
      color: #1a181d;
      background-color: $line2;
      border-color: $line2;
    }
  }
  .slider_outer_controls_top .slider_controls_wrap > a, .slider_outer_controls_bottom .slider_controls_wrap > a {
    color: #1a181d;
    background-color: $line2;
    border-color: $line2;
  }
  .slider_container {
    &.slider_controls_top .slider_controls_wrap > a:hover, &.slider_controls_bottom .slider_controls_wrap > a:hover {
      color: #fff;
      border-color: $line3;
      background-color: $line3;
    }
  }
  .slider_outer_controls_top .slider_controls_wrap > a:hover, .slider_outer_controls_bottom .slider_controls_wrap > a:hover {
    color: #fff;
    border-color: $line3;
    background-color: $line3;
  }
  .sc_content_tbordered:after {
    background: linear-gradient(to right, $line3 0%, $line3 17%, #1a181d 17%, #1a181d 34%, $line1 34%, $line1 50.5%, $line2 50.5%, $line2 67%, $line3 67%, $line3 83.5%, $line1 83.5%, $line1 100%);
  }
  .bullet-bar .tp-bullet {
    &:hover, &.selected {
      background-color: rgba(255, 255, 255, 0.7);
    }
    background-color: rgba(255, 255, 255, 0.3);
  }
  .sc_services_default {
    .sc_services_item {
      background-color: #fff;
      &:hover {
        background-color: #f4f6fa;
      }
    }
    .sc_services_columns_wrap > div {
      + div {
        border-color: #f4f6fa;
      }
      &:nth-child(6n+1) .sc_services_item:after {
        background-color: $line3;
      }
      &:nth-child(6n+2) .sc_services_item:after {
        background-color: #1a181d;
      }
      &:nth-child(6n+3) .sc_services_item:after {
        background-color: $line1;
      }
      &:nth-child(6n+4) .sc_services_item:after {
        background-color: $line2;
      }
      &:nth-child(6n+5) .sc_services_item:after {
        background-color: $line3;
      }
      &:nth-child(6n+6) .sc_services_item:after {
        background-color: $line1;
      }
    }
  }
  .sc_button.sc_button_size_large.sc_button_simple:not(.sc_button_bg_image) {
    color: $linea;
    &:hover {
      color: $line3 !important;
    }
  }
  .scheme_dark {
    .sc_layouts_item_icon, .logo_slogan {
      color: #fff;
    }
  }
  .menu_mobile .search_mobile .search_field {
    border-color: rgba(255, 255, 255, 0.4);
  }
  .team_member_page .team_member_socials .social_item {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .mc4wp-form {
    input[type="email"] {
      background-color: #f4f6fa;
      border-color: #f4f6fa;
      color: #9a9d9f;
    }
    .mc4wp-alert {
      background-color: $line2;
      border-color: $line3;
      color: #1d1d1d;
    }
  }
  .woocommerce {
    .woocommerce-breadcrumb {
      color: #737a80;
      a {
        color: $line2;
        &:hover {
          color: $line3;
        }
      }
    }
    .widget_price_filter .ui-slider {
      .ui-slider-range {
        background-color: $line3;
      }
      .ui-slider-handle {
        background-color: #fff;
        border-color: $line3;
      }
    }
    .woocommerce-ordering select {
      border-color: #e5e5e5;
    }
    span.onsale {
      color: #fff;
      background-color: $line2;
    }
    ul.products li.product {
      .post_header {
        a {
          color: #1d1d1d;
          &:hover {
            color: $line3;
          }
        }
        .post_tags {
          color: $line3;
          a {
            color: $line3;
            &:hover {
              color: #72cfd5;
            }
          }
        }
      }
      .price {
        color: #737a80;
      }
    }
  }
  .woocommerce-page ul.products li.product .price, .woocommerce ul.products li.product .price ins, .woocommerce-page ul.products li.product .price ins {
    color: #737a80;
  }
  .woocommerce ul.products li.product .price del, .woocommerce-page ul.products li.product .price del {
    color: #b7b7b7;
  }
  .woocommerce {
    div.product form.cart .variations {
      td {
        border-color: #e5e5e5;
        + td {
          border-color: #e5e5e5;
        }
      }
      border-color: #e5e5e5;
    }
    &.widget_shopping_cart ul.cart_list li {
      border-color: #e5e5e5;
    }
    div.product {
      p.price span.amount, span.price span.amount, p.price, span.price {
        color: $line3;
      }
    }
    span.amount {
      color: #737a80;
    }
  }
  .woocommerce-page span.amount {
    color: #737a80;
  }
  .woocommerce table.shop_table td span.amount {
    color: #1a181d;
  }
  aside.woocommerce del {
    color: #8b8f95 !important;
  }
  .woocommerce del, .woocommerce-page del {
    color: #8b8f95 !important;
    > span.amount {
      color: #8b8f95 !important;
    }
  }
  .woocommerce {
    &.widget_shopping_cart .total .amount, .widget_shopping_cart .total .amount {
      color: $line3;
    }
  }
  .woocommerce-page {
    &.widget_shopping_cart .total .amount, .widget_shopping_cart .total .amount {
      color: $line3;
    }
  }
  .woocommerce {
    .price del:before {
      background-color: #8b8f95;
    }
    div.product form.cart div.quantity span {
      color: #1a181d;
      background-color: #f4f6fa;
    }
  }
  .woocommerce-page div.product form.cart div.quantity span, .woocommerce .shop_table.cart div.quantity span, .woocommerce-page .shop_table.cart div.quantity span {
    color: #1a181d;
    background-color: #f4f6fa;
  }
  .woocommerce div.product form.cart div.quantity span:hover, .woocommerce-page div.product form.cart div.quantity span:hover, .woocommerce .shop_table.cart div.quantity span:hover, .woocommerce-page .shop_table.cart div.quantity span:hover {
    color: $line3;
    background-color: #f4f6fa;
  }
  .woocommerce div.product form.cart div.quantity input[type="number"], .woocommerce-page div.product form.cart div.quantity input[type="number"], .woocommerce .shop_table.cart input[type="number"], .woocommerce-page .shop_table.cart div.quantity input[type="number"] {
    background-color: #f4f6fa;
  }
  .woocommerce div.product {
    .product_meta {
      span > {
        a, span {
          color: #737a80;
        }
      }
      a:hover {
        color: $line3;
      }
    }
    div.images {
      .flex-viewport, img {
        border-color: #e5e5e5;
      }
      a:hover img {
        border-color: $line2;
      }
    }
  }
  .single-product div.product .trx-stretch-width .woocommerce-tabs {
    border-color: #f4f6fa;
    .wc-tabs {
      background-color: #f4f6fa;
    }
  }
  .woocommerce {
    div.product .woocommerce-tabs .panel, #content div.product .woocommerce-tabs .panel {
      border-color: #e5e5e5;
    }
  }
  .woocommerce-page {
    div.product .woocommerce-tabs .panel, #content div.product .woocommerce-tabs .panel {
      border-color: #e5e5e5;
    }
  }
  .single-product div.product {
    .woocommerce-tabs .wc-tabs li {
      a {
        color: #1a181d;
        background-color: #f4f6fa;
      }
      &.active a {
        color: #1a181d;
        background-color: #fff;
      }
    }
    .trx-stretch-width .woocommerce-tabs .wc-tabs li:before {
      background-color: #e5e5e5;
    }
    .woocommerce-tabs {
      .wc-tabs li:not(.active) a:hover {
        color: #1a181d;
        background-color: #fff;
      }
      .panel {
        color: #737a80;
      }
    }
  }
  .woocommerce table.shop_attributes {
    tr {
      &:nth-child(2n+1) > * {
        background-color: rgba(248, 248, 248, 0.4);
      }
      &:nth-child(2n) > *, &.alt > * {
        background-color: rgba(248, 248, 248, 0.2);
      }
    }
    th {
      color: #1a181d;
    }
  }
  .single-product .related {
    border-color: #e5e5e5;
  }
  .star-rating {
    span, &:before {
      color: $line2;
    }
  }
  .alpha_color_shop_mode_buttons a {
    color: #f4f6fa;
    &:hover {
      color: $line3;
    }
  }
  .shop_mode_thumbs .alpha_color_shop_mode_buttons a.woocommerce_thumbs, .shop_mode_list .alpha_color_shop_mode_buttons a.woocommerce_list {
    color: $line3;
  }
  .woocommerce {
    .woocommerce-message, .woocommerce-info {
      background-color: #f8f8f8;
      border-top-color: #1d1d1d;
    }
    .woocommerce-error {
      background-color: #f8f8f8;
      border-top-color: $line3;
    }
    .woocommerce-message:before, .woocommerce-info:before {
      color: #1d1d1d;
    }
    .woocommerce-error:before {
      color: $line3;
    }
    table.shop_table {
      td {
        border-color: #e5e5e5 !important;
        background-color: #fff;
      }
      th {
        border-color: rgba(229, 229, 229, 0.2) !important;
      }
      tfoot th {
        color: #1a181d;
        border-color: transparent !important;
        background-color: transparent;
      }
    }
  }
  .woocommerce-page table.shop_table tfoot th {
    color: #1a181d;
    border-color: transparent !important;
    background-color: transparent;
  }
  .woocommerce {
    .quantity input.qty, #content .quantity input.qty {
      color: #15212c;
    }
  }
  .woocommerce-page {
    .quantity input.qty, #content .quantity input.qty {
      color: #15212c;
    }
  }
  .woocommerce .cart-collaterals .cart_totals table select, .woocommerce-page .cart-collaterals .cart_totals table select {
    color: #9a9d9f;
    background-color: #f4f6fa;
  }
  .woocommerce .cart-collaterals .cart_totals table select:focus, .woocommerce-page .cart-collaterals .cart_totals table select:focus {
    color: #15212c;
    background-color: #fff;
  }
  .woocommerce .cart-collaterals .shipping_calculator .shipping-calculator-button:after, .woocommerce-page .cart-collaterals .shipping_calculator .shipping-calculator-button:after, .woocommerce table.shop_table .cart-subtotal .amount, .woocommerce-page table.shop_table .cart-subtotal .amount, .woocommerce table.shop_table .shipping td, .woocommerce-page table.shop_table .shipping td {
    color: #1a181d;
  }
  .woocommerce {
    table.cart td + td a, #content table.cart td + td a {
      color: #1a181d;
    }
  }
  .woocommerce-page {
    table.cart td + td a, #content table.cart td + td a {
      color: #1a181d;
    }
  }
  .woocommerce {
    table.cart td + td span, #content table.cart td + td span {
      color: #1a181d;
    }
  }
  .woocommerce-page {
    table.cart td + td span, #content table.cart td + td span {
      color: #1a181d;
    }
  }
  .woocommerce {
    table.cart td + td a:hover, #content table.cart td + td a:hover {
      color: $line2;
    }
  }
  .woocommerce-page {
    table.cart td + td a:hover, #content table.cart td + td a:hover {
      color: $line2;
    }
  }
  #add_payment_method table.cart td.actions .coupon .input-text, .woocommerce-cart table.cart td.actions .coupon .input-text, .woocommerce-checkout table.cart td.actions .coupon .input-text {
    border-color: #f4f6fa;
  }
  #add_payment_method #payment ul.payment_methods, .woocommerce-cart #payment ul.payment_methods, .woocommerce-checkout #payment ul.payment_methods {
    border-color: #e5e5e5;
  }
  #add_payment_method #payment div.payment_box, .woocommerce-cart #payment div.payment_box, .woocommerce-checkout #payment div.payment_box {
    color: #15212c;
    background-color: #fff;
  }
  #add_payment_method #payment div.payment_box:before, .woocommerce-cart #payment div.payment_box:before, .woocommerce-checkout #payment div.payment_box:before {
    border-color: transparent transparent #fff;
  }
  .woocommerce .order_details li strong, .woocommerce-page .order_details li strong {
    color: #1a181d;
  }
  .woocommerce .order_details.woocommerce-thankyou-order-details {
    color: #333;
    background-color: #f8f8f8;
    strong {
      color: #1d1d1d;
    }
  }
  .woocommerce-account .woocommerce-MyAccount-navigation {
    border-color: #e5e5e5;
  }
  .woocommerce-MyAccount-navigation {
    ul li {
      border-color: #e5e5e5;
    }
    li {
      + li {
        border-color: #e5e5e5;
      }
      &.is-active a {
        color: $line2;
      }
    }
  }
  .woocommerce-MyAccount-content .my_account_orders .button {
    color: $line2;
    &:hover {
      color: $line3;
    }
  }
  .widget_product_search form {
    &:after {
      color: #9a9d9f;
    }
    &:hover:after {
      color: #15212c;
    }
  }
  .widget_shopping_cart .total {
    color: #1a181d;
    border-color: #e5e5e5;
  }
  .woocommerce ul.cart_list li dl, .woocommerce-page ul.cart_list li dl, .woocommerce ul.product_list_widget li dl, .woocommerce-page ul.product_list_widget li dl {
    border-color: #e5e5e5;
  }
  .widget_layered_nav ul li.chosen a {
    color: #1a181d;
  }
  .widget_price_filter {
    .price_slider_wrapper .ui-widget-content {
      background: #8b8f95;
    }
    .price_label span {
      color: #737a80;
    }
  }
  .woocommerce .shop_mode_list ul.products li.product .price, .woocommerce-page .shop_mode_list ul.products li.product .price {
    color: $line3;
  }
  .yith_magnifier_zoom_wrap .yith_magnifier_zoom_magnifier {
    border-color: #e5e5e5;
  }
  .yith-woocompare-widget a.clear-all {
    color: #fff;
    background-color: $line3;
    &:hover {
      color: #1d1d1d;
      background-color: #72cfd5;
    }
  }
  .widget.WOOCS_SELECTOR .woocommerce-currency-switcher-form {
    .chosen-container-single .chosen-single {
      color: #9a9d9f;
      background: #f4f6fa;
      &:hover {
        color: #15212c;
        background: #fff;
      }
    }
    .chosen-container {
      .chosen-drop {
        color: #15212c;
        background: #fff;
        border-color: #d9dce1;
      }
      .chosen-results li {
        color: #15212c;
        &:hover, &.highlighted, &.result-selected {
          color: $line3 !important;
        }
      }
    }
  }
  .woocommerce ul.products li.product .post_data:before {
    background: linear-gradient(to right, $line1 0%, $line1 33.33%, $line2 33.33%, $line2 66.66%, $line3 66.66%, $line3 100%);
  }
  &.vc_section, &.wpb_row, &.wpb_column > .vc_column-inner > .wpb_wrapper, &.wpb_text_column {
    color: #737a80;
  }
  .wpb-js-composer {
    .vc_tta.vc_tta-accordion .vc_tta-panel-heading .vc_tta-controls-icon {
      color: $line3;
    }
    .vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel {
      .vc_tta-panel-title > a {
        color: #1a181d;
      }
      &.vc_active .vc_tta-panel-title > a, .vc_tta-panel-title > a:hover {
        color: $line3;
      }
    }
  }
  .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tabs-list .vc_tta-tab {
    > a {
      color: #1a181d;
      background-color: #f4f6fa;
      &:hover {
        color: #1a181d;
        background-color: #fff;
      }
    }
    &.vc_active > a {
      color: #1a181d;
      background-color: #fff;
    }
  }
  .vc_general.vc_tta.vc_tta-tabs.vc_tta-color-grey.vc_tta-style-classic.vc_tta-shape-square {
    border-color: #f4f6fa;
  }
  .wpb-js-composer .vc_tta-tab:not(.vc_active) + .vc_tta-tab:before {
    background-color: #e5e5e5;
  }
  .vc_separator.vc_sep_color_grey .vc_sep_line {
    border-color: #e5e5e5;
  }
  .vc_progress_bar.vc_progress_bar_narrow {
    .vc_single_bar {
      background-color: #f8f8f8;
    }
    &.vc_progress-bar-color-bar_red .vc_single_bar .vc_bar {
      background-color: $line3;
    }
    .vc_single_bar .vc_label {
      color: #1a181d;
      .vc_label_units {
        color: #8b8f95;
      }
    }
  }
  .sc_button_hover_slide_left {
    background: linear-gradient(to right, $line3 50%, $line2 50%) no-repeat scroll right (bottom / 210%) 100% rgba(255, 255, 255, 0) !important;
  }
  .sc_button_hover_slide_right {
    background: linear-gradient(to left, $line3 50%, $line2 50%) no-repeat scroll left (bottom / 210%) 100% rgba(255, 255, 255, 0) !important;
  }
  .sc_button_hover_slide_top {
    background: linear-gradient(to bottom, $line3 50%, $line2 50%) no-repeat scroll right (bottom / 100%) 210% rgba(255, 255, 255, 0) !important;
  }
  .sc_button_hover_slide_bottom {
    background: linear-gradient(to top, $line3 50%, $line2 50%) no-repeat scroll right (top / 100%) 210% rgba(255, 255, 255, 0) !important;
  }
  .sc_button_hover_style_link2 {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, $line3 50%, $line1 50%) no-repeat scroll right (bottom / 210%) 100% $line1 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, $line3 50%, $line1 50%) no-repeat scroll left (bottom / 210%) 100% $line1 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, $line3 50%, $line1 50%) no-repeat scroll right (bottom / 100%) 210% $line1 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, $line3 50%, $line1 50%) no-repeat scroll right (top / 100%) 210% $line1 !important;
    }
  }
  .sc_button_hover_style_link3 {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, $line2 50%, $line3 50%) no-repeat scroll right (bottom / 210%) 100% rgba(255, 255, 255, 0) !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, $line2 50%, $line3 50%) no-repeat scroll left (bottom / 210%) 100% rgba(255, 255, 255, 0) !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, $line2 50%, $line3 50%) no-repeat scroll right (bottom / 100%) 210% rgba(255, 255, 255, 0) !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, $line2 50%, $line3 50%) no-repeat scroll right (top / 100%) 210% rgba(255, 255, 255, 0) !important;
    }
  }
  .sc_button_hover_style_dark {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, $line2 50%, #1a181d 50%) no-repeat scroll right (bottom / 210%) 100% #1a181d !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, $line2 50%, #1a181d 50%) no-repeat scroll left (bottom / 210%) 100% #1a181d !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, $line2 50%, #1a181d 50%) no-repeat scroll right (bottom / 100%) 210% #1a181d !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, $line2 50%, #1a181d 50%) no-repeat scroll right (top / 100%) 210% #1a181d !important;
    }
  }
  .sc_button_hover_style_inverse {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, #fff 50%, $line2 50%) no-repeat scroll right (bottom / 210%) 100% $line2 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, #fff 50%, $line2 50%) no-repeat scroll left (bottom / 210%) 100% $line2 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, #fff 50%, $line2 50%) no-repeat scroll right (bottom / 100%) 210% $line2 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, #fff 50%, $line2 50%) no-repeat scroll right (top / 100%) 210% $line2 !important;
    }
  }
  .sc_button_hover_style_hover {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, $line3 50%, $line2 50%) no-repeat scroll right (bottom / 210%) 100% $line2 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, $line3 50%, $line2 50%) no-repeat scroll left (bottom / 210%) 100% $line2 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, $line3 50%, $line2 50%) no-repeat scroll right (bottom / 100%) 210% $line2 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, $line3 50%, $line2 50%) no-repeat scroll right (top / 100%) 210% $line2 !important;
    }
  }
  .sc_button_hover_style_alter {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, #1d1d1d 50%, $line3 50%) no-repeat scroll right (bottom / 210%) 100% $line3 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, #1d1d1d 50%, $line3 50%) no-repeat scroll left (bottom / 210%) 100% $line3 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, #1d1d1d 50%, $line3 50%) no-repeat scroll right (bottom / 100%) 210% $line3 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, #1d1d1d 50%, $line3 50%) no-repeat scroll right (top / 100%) 210% $line3 !important;
    }
  }
  .sc_button_hover_style_alterbd {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, $line3 50%, #e5e5e5 50%) no-repeat scroll right (bottom / 210%) 100% #e5e5e5 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, $line3 50%, #e5e5e5 50%) no-repeat scroll left (bottom / 210%) 100% #e5e5e5 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, $line3 50%, #e5e5e5 50%) no-repeat scroll right (bottom / 100%) 210% #e5e5e5 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, $line3 50%, #e5e5e5 50%) no-repeat scroll right (top / 100%) 210% #e5e5e5 !important;
    }
  }
  .sc_button_hover_style_extra {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, #fff 50%, #4eb8ff 50%) no-repeat scroll right (bottom / 210%) 100% #4eb8ff !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, #fff 50%, #4eb8ff 50%) no-repeat scroll left (bottom / 210%) 100% #4eb8ff !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, #fff 50%, #4eb8ff 50%) no-repeat scroll right (bottom / 100%) 210% #4eb8ff !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, #fff 50%, #4eb8ff 50%) no-repeat scroll right (top / 100%) 210% #4eb8ff !important;
    }
  }
  .sc_button_hover_style_alter {
    &.sc_button_hover_slide_left:hover, &.sc_button_hover_slide_right:hover, &.sc_button_hover_slide_top:hover, &.sc_button_hover_slide_bottom:hover {
      color: #fff !important;
    }
  }
  .sc_button_hover_style_extra {
    &.sc_button_hover_slide_left:hover, &.sc_button_hover_slide_right:hover, &.sc_button_hover_slide_top:hover, &.sc_button_hover_slide_bottom:hover {
      color: #fff !important;
    }
  }
  .sc_button_hover_slide_left {
    &:hover, &.active {
      background-position: left bottom !important;
      color: #fff !important;
    }
  }
  .ui-state-active .sc_button_hover_slide_left, .vc_active .sc_button_hover_slide_left, .vc_tta-accordion .vc_tta-panel-title:hover .sc_button_hover_slide_left, li.active .sc_button_hover_slide_left {
    background-position: left bottom !important;
    color: #fff !important;
  }
  .sc_button_hover_slide_right {
    &:hover, &.active {
      background-position: right bottom !important;
      color: #fff !important;
    }
  }
  .ui-state-active .sc_button_hover_slide_right, .vc_active .sc_button_hover_slide_right, .vc_tta-accordion .vc_tta-panel-title:hover .sc_button_hover_slide_right, li.active .sc_button_hover_slide_right {
    background-position: right bottom !important;
    color: #fff !important;
  }
  .sc_button_hover_slide_top {
    &:hover, &.active {
      background-position: right top !important;
      color: #fff !important;
    }
  }
  .ui-state-active .sc_button_hover_slide_top, .vc_active .sc_button_hover_slide_top, .vc_tta-accordion .vc_tta-panel-title:hover .sc_button_hover_slide_top, li.active .sc_button_hover_slide_top {
    background-position: right top !important;
    color: #fff !important;
  }
  .sc_button_hover_slide_bottom {
    &:hover, &.active {
      background-position: right bottom !important;
      color: #fff !important;
    }
  }
  .ui-state-active .sc_button_hover_slide_bottom, .vc_active .sc_button_hover_slide_bottom, .vc_tta-accordion .vc_tta-panel-title:hover .sc_button_hover_slide_bottom, li.active .sc_button_hover_slide_bottom {
    background-position: right bottom !important;
    color: #fff !important;
  }
  .post_featured {
    .mask {
      background-color: rgba(26, 24, 29, 0.7);
    }
    &.hover_dots {
      &:hover .mask {
        background-color: rgba(26, 24, 29, 0.7);
      }
      .icons span {
        background-color: $line2;
      }
      .post_info {
        color: #fff;
      }
    }
    &.hover_icon, &.hover_icons {
      .icons a {
        color: #1a181d;
        background-color: $line2;
      }
      a:hover {
        color: #fff;
        background-color: $line3;
      }
    }
    &.hover_fade .post_info {
      color: #fff;
      a, .post_meta_item {
        color: #fff;
      }
      .post_meta .post_meta_item {
        &:before, &:hover:before {
          color: #fff;
        }
      }
      a:hover {
        color: $line2;
      }
    }
    &.hover_slide .post_info {
      color: #fff;
      a, .post_meta_item {
        color: #fff;
      }
      .post_meta .post_meta_item {
        &:before, &:hover:before {
          color: #fff;
        }
      }
      a:hover {
        color: $line2;
      }
      .post_title:after {
        background-color: #fff;
      }
    }
    &.hover_pull {
      background-color: #4eb8ff;
      .post_info {
        color: #fff;
        a {
          color: #fff;
          &:hover {
            color: #fff;
            &:before {
              color: #fff;
            }
          }
        }
      }
    }
    &.hover_border .post_info {
      color: #fff;
      a, .post_meta_item {
        color: #fff;
      }
      .post_meta .post_meta_item {
        &:before, &:hover:before {
          color: #fff;
        }
      }
      a:hover {
        color: $line2;
      }
      &:before, &:after {
        border-color: #fff;
      }
    }
    &.hover_shop .icons a {
      color: #1a181d;
      border-color: $line2 !important;
      background-color: $line2;
      &:hover {
        color: #fff;
        border-color: $line3 !important;
        background-color: $line3;
      }
    }
    &.hover_shop_buttons .icons {
      .shop_link {
        color: #fff;
        background-color: #1a181d;
      }
      a:hover {
        color: #1d1d1d;
        background-color: $line3;
      }
    }
  }
}

/* Attention! This widget placed in the content area and should use main text colors */

/* Sports:Matches and Players */

/* Skills (Counters) */

/* Background color for blocks with specified scheme (removed,use bg_color instead).scheme_self.vc_section[data-vc-full-width="true"],.scheme_self.wpb_row[data-vc-full-width="true"],.scheme_self.wpb_column:not([class*="sc_extra_bg_"])>.vc_column-inner>.wpb_wrapper,.scheme_self.wpb_text_column{background-color:#ffffff}*/

/* Mask for parallax background (removed,use bg_color + bg_mask instead).scheme_self.vc_row.vc_parallax[class*="scheme_"] .vc_parallax-inner:before{background-color:rgba(255,255,255,0.8)}*/

/* ================= BUTTON'S HOVERS ==================== */

/* ================= IMAGE'S HOVERS ==================== */

.scheme_dark {
  body {
    background-color: #0e0d12;
  }
  color: #787f86;
  h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, li a {
    color: #fff;
  }
  [class*="color_style_"] {
    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, li a {
      color: #fff;
    }
  }
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, li a:hover {
    color: $line3;
  }
  .color_style_link2 {
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, li a:hover {
      color: $line1;
    }
  }
  .color_style_link3 {
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, li a:hover {
      color: $line3;
    }
  }
  .color_style_dark {
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, li a:hover {
      color: $line2;
    }
  }
  b, strong {
    color: #fff;
  }
  s, strike, del {
    color: #919497;
  }
  code {
    color: #919497;
    background-color: #2c3c4a;
    border-color: #464646;
    a {
      color: $line3;
      &:hover {
        color: #fe7259;
      }
    }
  }
  a {
    color: #fff;
    &:hover {
      color: $line3;
    }
  }
  .color_style_link2 a {
    color: $line1;
    &:hover {
      color: #8be77c;
    }
  }
  .color_style_link3 a {
    color: $line3;
    &:hover {
      color: $line2;
    }
  }
  .color_style_dark a {
    color: #fff;
    &:hover {
      color: $line2;
    }
  }
  blockquote {
    color: #fff;
    background-color: #1e1d22;
    &:before {
      color: #ffaa5f;
    }
    a {
      color: #ffaa5f;
      &:hover {
        color: #fe7259;
      }
    }
  }
  table {
    th {
      border-color: rgba(14, 13, 18, 0.2);
      + th {
        border-color: rgba(14, 13, 18, 0.2);
      }
    }
    td + th {
      border-color: rgba(14, 13, 18, 0.2);
    }
    tr:last-child td, td, th + td, td + td {
      color: #787f86;
      border-color: #2e2c33;
    }
    th {
      color: #fff;
      background-color: $line3;
      border-color: rgba(14, 13, 18, 0.2);
      b, strong, a:hover {
        color: #fff;
      }
    }
    > tbody > tr {
      &:nth-child(2n+1) > td {
        background-color: #2c3c4a;
      }
      &:nth-child(2n) > td {
        background-color: #0e0d12;
      }
    }
  }
  hr {
    border-color: #2e2c33;
  }
  figure figcaption {
    color: #787f86;
    background-color: rgba(14, 13, 18, 0.8);
  }
  .wp-caption {
    .wp-caption-text, .wp-caption-dd {
      color: #787f86;
      background-color: rgba(14, 13, 18, 0.8);
    }
  }
  .wp-caption-overlay .wp-caption {
    .wp-caption-text, .wp-caption-dd {
      color: #787f86;
      background-color: rgba(14, 13, 18, 0.8);
    }
  }
  ul > li:before {
    color: $line2;
  }
  .widget_search form:after, .woocommerce.widget_product_search form:after, .widget_display_search form:after, #bbpress-forums #bbp-search-form:after {
    color: #969ea5;
  }
  .widget_search form:hover:after, .woocommerce.widget_product_search form:hover:after, .widget_display_search form:hover:after, #bbpress-forums #bbp-search-form:hover:after {
    color: #fff;
  }
  fieldset {
    border-color: #2e2c33;
    legend {
      color: #fff;
      background-color: #0e0d12;
    }
  }
  input {
    &[type="text"], &[type="number"], &[type="email"], &[type="tel"], &[type="search"], &[type="password"] {
      color: #969ea5;
      border-color: #2e2d32;
      background-color: #2c3c4a;
    }
  }
  .select_container {
    color: #969ea5;
    border-color: #2e2d32;
    background-color: #2c3c4a;
  }
  .select2-container.select2-container--default {
    span {
      &.select2-choice, &.select2-selection {
        color: #969ea5;
        border-color: #2e2d32;
        background-color: #2c3c4a;
      }
    }
    .select2-selection--single .select2-selection__rendered, .select2-selection--multiple {
      color: #969ea5;
      border-color: #2e2d32;
      background-color: #2c3c4a;
    }
  }
  textarea {
    color: #969ea5;
    border-color: #2e2d32;
    background-color: #2c3c4a;
    &.wp-editor-area {
      color: #969ea5;
      border-color: #2e2d32;
      background-color: #2c3c4a;
    }
  }
  #buddypress {
    .dir-search input {
      &[type="search"], &[type="text"] {
        color: #969ea5;
        border-color: #2e2d32;
        background-color: #2c3c4a;
      }
    }
    .groups-members-search input {
      &[type="search"], &[type="text"] {
        color: #969ea5;
        border-color: #2e2d32;
        background-color: #2c3c4a;
      }
    }
    .standard-form {
      input {
        &[type="color"], &[type="date"], &[type="datetime-local"], &[type="datetime"], &[type="email"], &[type="month"], &[type="number"], &[type="password"], &[type="range"], &[type="search"], &[type="tel"], &[type="text"], &[type="time"], &[type="url"], &[type="week"] {
          color: #969ea5;
          border-color: #2e2d32;
          background-color: #2c3c4a;
        }
      }
      select, textarea {
        color: #969ea5;
        border-color: #2e2d32;
        background-color: #2c3c4a;
      }
    }
    form#whats-new-form textarea {
      color: #969ea5;
      border-color: #2e2d32;
      background-color: #2c3c4a;
    }
  }
  #booked-page-form {
    input {
      &[type="email"], &[type="text"], &[type="password"] {
        color: #969ea5;
        border-color: #2e2d32;
        background-color: #2c3c4a;
      }
    }
    textarea {
      color: #969ea5;
      border-color: #2e2d32;
      background-color: #2c3c4a;
    }
  }
  .booked-upload-wrap {
    color: #969ea5;
    border-color: #2e2d32;
    background-color: #2c3c4a;
    input {
      color: #969ea5;
      border-color: #2e2d32;
      background-color: #2c3c4a;
    }
  }
  input {
    &[type="text"]:focus, &[type="number"]:focus, &[type="email"]:focus, &[type="tel"]:focus, &[type="search"]:focus, &[type="password"]:focus {
      color: #fff;
      border-color: #353535;
      background-color: #2e2d32;
    }
  }
  .select_container:hover {
    color: #fff;
    border-color: #353535;
    background-color: #2e2d32;
  }
  select option {
    &:hover, &:focus {
      color: #fff;
      border-color: #353535;
      background-color: #2e2d32;
    }
  }
  .select2-container {
    &.select2-container--default span.select2-choice:hover, &.select2-container--focus span.select2-choice, &.select2-container--open span.select2-choice, &.select2-container--focus span.select2-selection--single .select2-selection__rendered, &.select2-container--open span.select2-selection--single .select2-selection__rendered, &.select2-container--default span.select2-selection--multiple:hover, &.select2-container--focus span.select2-selection--multiple, &.select2-container--open span.select2-selection--multiple {
      color: #fff;
      border-color: #353535;
      background-color: #2e2d32;
    }
  }
  textarea {
    &:focus, &.wp-editor-area:focus {
      color: #fff;
      border-color: #353535;
      background-color: #2e2d32;
    }
  }
  #buddypress {
    .dir-search input {
      &[type="search"]:focus, &[type="text"]:focus {
        color: #fff;
        border-color: #353535;
        background-color: #2e2d32;
      }
    }
    .groups-members-search input {
      &[type="search"]:focus, &[type="text"]:focus {
        color: #fff;
        border-color: #353535;
        background-color: #2e2d32;
      }
    }
    .standard-form {
      input {
        &[type="color"]:focus, &[type="date"]:focus, &[type="datetime-local"]:focus, &[type="datetime"]:focus, &[type="email"]:focus, &[type="month"]:focus, &[type="number"]:focus, &[type="password"]:focus, &[type="range"]:focus, &[type="search"]:focus, &[type="tel"]:focus, &[type="text"]:focus, &[type="time"]:focus, &[type="url"]:focus, &[type="week"]:focus {
          color: #fff;
          border-color: #353535;
          background-color: #2e2d32;
        }
      }
      select:focus, textarea:focus {
        color: #fff;
        border-color: #353535;
        background-color: #2e2d32;
      }
    }
    form#whats-new-form textarea:focus {
      color: #fff;
      border-color: #353535;
      background-color: #2e2d32;
    }
  }
  #booked-page-form {
    input {
      &[type="email"]:focus, &[type="text"]:focus, &[type="password"]:focus {
        color: #fff;
        border-color: #353535;
        background-color: #2e2d32;
      }
    }
    textarea:focus {
      color: #fff;
      border-color: #353535;
      background-color: #2e2d32;
    }
  }
  .booked-upload-wrap {
    &:hover, input:focus {
      color: #fff;
      border-color: #353535;
      background-color: #2e2d32;
    }
  }
  input[placeholder]::-webkit-input-placeholder, textarea[placeholder]::-webkit-input-placeholder, input[placeholder]::-moz-placeholder, textarea[placeholder]::-moz-placeholder, input[placeholder]:-ms-input-placeholder, textarea[placeholder]:-ms-input-placeholder, input[placeholder]::placeholder, textarea[placeholder]::placeholder {
    color: #969ea5;
  }
  .select_container {
    &:before {
      color: #969ea5;
      background-color: #2c3c4a;
    }
    &:focus:before, &:hover:before {
      color: #fff;
      background-color: #2e2d32;
    }
    &:after {
      color: #969ea5;
    }
    &:focus:after, &:hover:after {
      color: #fff;
    }
    select {
      color: #969ea5;
      background: #2c3c4a !important;
      &:focus {
        color: #fff;
        background-color: #2e2d32 !important;
      }
    }
  }
  .select2-dropdown {
    color: #fff;
    border-color: #353535;
    background: #2e2d32;
  }
  .select2-container {
    &.select2-container--focus span.select2-selection, &.select2-container--open span.select2-selection {
      color: #fff;
      border-color: #353535;
      background: #2e2d32;
    }
    .select2-results__option {
      color: #fff;
      background: #2e2d32;
    }
  }
  .select2-dropdown .select2-highlighted, .select2-container .select2-results__option--highlighted[aria-selected] {
    color: #fff;
    background: $line2;
  }
  input {
    &[type="radio"] + label:before, &[type="checkbox"] + label:before {
      border-color: #2e2d32;
      background-color: #2c3c4a;
    }
  }
  .sc_button_simple {
    &:not(.sc_button_bg_image) {
      color: $line3;
      &:before, &:after {
        color: $line3;
      }
      &:hover {
        color: #fff !important;
        &:before, &:after {
          color: #fff !important;
        }
      }
    }
    &.color_style_link2:not(.sc_button_bg_image) {
      color: $line1;
      &:before, &:after {
        color: $line1;
      }
    }
  }
  .color_style_link2 .sc_button_simple:not(.sc_button_bg_image) {
    color: $line1;
    &:before, &:after {
      color: $line1;
    }
  }
  .sc_button_simple.color_style_link2:not(.sc_button_bg_image):hover {
    color: #8be77c;
    &:before, &:after {
      color: #8be77c;
    }
  }
  .color_style_link2 .sc_button_simple:not(.sc_button_bg_image):hover {
    color: #8be77c;
    &:before, &:after {
      color: #8be77c;
    }
  }
  .sc_button_simple.color_style_link3:not(.sc_button_bg_image) {
    color: $line3;
    &:before, &:after {
      color: $line3;
    }
  }
  .color_style_link3 .sc_button_simple:not(.sc_button_bg_image) {
    color: $line3;
    &:before, &:after {
      color: $line3;
    }
  }
  .sc_button_simple.color_style_link3:not(.sc_button_bg_image):hover {
    color: $line2;
    &:before, &:after {
      color: $line2;
    }
  }
  .color_style_link3 .sc_button_simple:not(.sc_button_bg_image):hover {
    color: $line2;
    &:before, &:after {
      color: $line2;
    }
  }
  .sc_button_simple.color_style_dark:not(.sc_button_bg_image) {
    color: #fff;
    &:before, &:after {
      color: #fff;
    }
  }
  .color_style_dark .sc_button_simple:not(.sc_button_bg_image) {
    color: #fff;
    &:before, &:after {
      color: #fff;
    }
  }
  .sc_button_simple.color_style_dark:not(.sc_button_bg_image):hover {
    color: $line2;
    &:before, &:after {
      color: $line2;
    }
  }
  .color_style_dark .sc_button_simple:not(.sc_button_bg_image):hover {
    color: $line2;
    &:before, &:after {
      color: $line2;
    }
  }
  .sc_button_bordered {
    &:not(.sc_button_bg_image) {
      color: $line2;
      border-color: $line2;
      &:hover {
        color: $line3 !important;
        border-color: $line3 !important;
      }
    }
    &.color_style_link2:not(.sc_button_bg_image) {
      color: $line1;
      border-color: $line1;
      &:hover {
        color: #8be77c !important;
        border-color: #8be77c !important;
      }
    }
    &.color_style_link3:not(.sc_button_bg_image) {
      color: $line3;
      border-color: $line3;
      &:hover {
        color: $line2 !important;
        border-color: $line2 !important;
      }
    }
    &.color_style_dark:not(.sc_button_bg_image) {
      color: #fff;
      border-color: #fff;
      &:hover {
        color: $line2 !important;
        border-color: $line2 !important;
      }
    }
  }
  button {
    color: #000;
    background-color: $line2;
  }
  input {
    &[type="reset"], &[type="submit"], &[type="button"] {
      color: #000;
      background-color: $line2;
    }
  }
  .post_item .more-link, .comments_wrap .form-submit input[type="submit"] {
    color: #000;
    background-color: $line2;
  }
  #buddypress {
    .comment-reply-link, .generic-button a, a.button, button {
      color: #000;
      background-color: $line2;
    }
    input {
      &[type="button"], &[type="reset"], &[type="submit"] {
        color: #000;
        background-color: $line2;
      }
    }
    ul.button-nav li a {
      color: #000;
      background-color: $line2;
    }
  }
  a.bp-title-button, .booked-calendar-wrap .booked-appt-list .timeslot .timeslot-people button {
    color: #000;
    background-color: $line2;
  }
  body {
    #booked-profile-page {
      .booked-profile-appt-list .appt-block .booked-cal-buttons .google-cal-button > a, input[type="submit"], button {
        color: #000;
        background-color: $line2;
      }
    }
    .booked-list-view {
      input[type="submit"], button {
        color: #000;
        background-color: $line2;
      }
    }
    table.booked-calendar {
      input[type="submit"], button {
        color: #000;
        background-color: $line2;
      }
    }
    .booked-modal {
      input[type="submit"], button {
        color: #000;
        background-color: $line2;
      }
    }
  }
  .sc_button_default, .sc_button:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image), .socials_share:not(.socials_type_drop) .social_icon {
    color: #000;
    background-color: $line2;
  }
  #tribe-bar-form {
    .tribe-bar-submit input[type="submit"], &.tribe-bar-mini .tribe-bar-submit input[type="submit"] {
      color: #000;
      background-color: $line2;
    }
  }
  #tribe-bar-views {
    li.tribe-bar-views-option a, .tribe-bar-views-list .tribe-bar-views-option.tribe-bar-active a {
      color: #000;
      background-color: $line2;
    }
  }
  #tribe-events .tribe-events-button, .tribe-events-button, .tribe-events-cal-links a, .tribe-events-sub-nav li a, .edd_download_purchase_form .button, #edd-purchase-button, .edd-submit.button, .widget_edd_cart_widget .edd_checkout a, .sc_edd_details .downloads_page_tags .downloads_page_data > a {
    color: #000;
    background-color: $line2;
  }
  .woocommerce {
    #respond input#submit, .button {
      color: #000;
      background-color: $line2;
    }
  }
  .woocommerce-page .button, .woocommerce a.button, .woocommerce-page a.button, .woocommerce button.button, .woocommerce-page button.button, .woocommerce input.button, .woocommerce-page input.button, .woocommerce input[type="button"], .woocommerce-page input[type="button"], .woocommerce input[type="submit"], .woocommerce-page input[type="submit"] {
    color: #000;
    background-color: $line2;
  }
  .woocommerce {
    #respond input#submit.alt, a.button.alt, button.button.alt, input.button.alt {
      color: #000;
      background-color: $line2;
    }
  }
  .theme_button {
    color: #fff !important;
    background-color: $line2 !important;
    &.color_style_link2 {
      background-color: $line1 !important;
    }
    &.color_style_link3 {
      background-color: $line3 !important;
    }
    &.color_style_dark {
      color: #0e0d12 !important;
      background-color: #fff !important;
    }
  }
  .sc_price_item_link {
    color: #fff;
    background-color: #ffaa5f;
  }
  .sc_button_default.color_style_link2, .sc_button.color_style_link2:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image) {
    background-color: $line1;
  }
  .sc_button_default.color_style_link3, .sc_button.color_style_link3:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image) {
    background-color: $line3;
    color: #fff;
  }
  .sc_button_default.color_style_dark, .sc_button.color_style_dark:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image) {
    color: #0e0d12;
    background-color: #fff;
  }
  .search_wrap .search_submit:before {
    color: #969ea5;
  }
  button {
    &:hover, &:focus {
      color: #fff;
      background-color: $line3;
    }
  }
  input {
    &[type="submit"] {
      &:hover, &:focus {
        color: #fff;
        background-color: $line3;
      }
    }
    &[type="reset"] {
      &:hover, &:focus {
        color: #fff;
        background-color: $line3;
      }
    }
    &[type="button"] {
      &:hover, &:focus {
        color: #fff;
        background-color: $line3;
      }
    }
  }
  .post_item .more-link:hover {
    color: #fff;
    background-color: $line3;
  }
  .comments_wrap .form-submit input[type="submit"] {
    &:hover, &:focus {
      color: #fff;
      background-color: $line3;
    }
  }
  #buddypress {
    .comment-reply-link:hover, .generic-button a:hover, a.button:hover, button:hover {
      color: #fff;
      background-color: $line3;
    }
    input {
      &[type="button"]:hover, &[type="reset"]:hover, &[type="submit"]:hover {
        color: #fff;
        background-color: $line3;
      }
    }
    ul.button-nav li a:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  a.bp-title-button:hover, .booked-calendar-wrap .booked-appt-list .timeslot .timeslot-people button:hover {
    color: #fff;
    background-color: $line3;
  }
  body {
    #booked-profile-page {
      .booked-profile-appt-list .appt-block .booked-cal-buttons .google-cal-button > a:hover, input[type="submit"]:hover, button:hover {
        color: #fff;
        background-color: $line3;
      }
    }
    .booked-list-view {
      input[type="submit"]:hover, button:hover {
        color: #fff;
        background-color: $line3;
      }
    }
    table.booked-calendar {
      input[type="submit"]:hover, button:hover {
        color: #fff;
        background-color: $line3;
      }
    }
    .booked-modal {
      input[type="submit"]:hover, button:hover {
        color: #fff;
        background-color: $line3;
      }
    }
  }
  .sc_button_default:hover, .sc_button:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover, .socials_share:not(.socials_type_drop) .social_icon:hover {
    color: #fff;
    background-color: $line3;
  }
  #tribe-bar-form {
    .tribe-bar-submit input[type="submit"] {
      &:hover, &:focus {
        color: #fff;
        background-color: $line3;
      }
    }
    &.tribe-bar-mini .tribe-bar-submit input[type="submit"] {
      &:hover, &:focus {
        color: #fff;
        background-color: $line3;
      }
    }
  }
  #tribe-bar-views {
    li.tribe-bar-views-option a:hover, .tribe-bar-views-list .tribe-bar-views-option.tribe-bar-active a:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  #tribe-events .tribe-events-button:hover, .tribe-events-button:hover, .tribe-events-cal-links a:hover, .tribe-events-sub-nav li a:hover {
    color: #fff;
    background-color: $line3;
  }
  .edd_download_purchase_form .button {
    &:hover, &:active, &:focus {
      color: #fff;
      background-color: $line3;
    }
  }
  #edd-purchase-button {
    &:hover, &:active, &:focus {
      color: #fff;
      background-color: $line3;
    }
  }
  .edd-submit.button {
    &:hover, &:active, &:focus {
      color: #fff;
      background-color: $line3;
    }
  }
  .widget_edd_cart_widget .edd_checkout a:hover, .sc_edd_details .downloads_page_tags .downloads_page_data > a:hover {
    color: #fff;
    background-color: $line3;
  }
  .woocommerce {
    #respond input#submit:hover, .button:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  .woocommerce-page .button:hover, .woocommerce a.button:hover, .woocommerce-page a.button:hover, .woocommerce button.button:hover, .woocommerce-page button.button:hover, .woocommerce input.button:hover, .woocommerce-page input.button:hover, .woocommerce input[type="button"]:hover, .woocommerce-page input[type="button"]:hover, .woocommerce input[type="submit"]:hover, .woocommerce-page input[type="submit"]:hover {
    color: #fff;
    background-color: $line3;
  }
  .woocommerce {
    #respond input#submit.alt:hover, a.button.alt:hover, button.button.alt:hover, input.button.alt:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  .theme_button {
    &:hover, &:focus {
      color: #1d1d1d !important;
      background-color: #fff04f !important;
    }
    &.color_style_link2:hover {
      background-color: #8be77c !important;
    }
    &.color_style_link3:hover {
      background-color: $line2 !important;
    }
    &.color_style_dark:hover {
      color: #1d1d1d !important;
      background-color: $line2 !important;
    }
  }
  .sc_price_item:hover .sc_price_item_link, .sc_price_item_link:hover {
    color: #1d1d1d;
    background-color: #fe7259;
  }
  .sc_button_default.color_style_link2:hover, .sc_button.color_style_link2:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover {
    background-color: #8be77c;
  }
  .sc_button_default.color_style_link3:hover, .sc_button.color_style_link3:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover {
    background-color: $line2;
    color: #fff !important;
  }
  .sc_button_default.color_style_dark:hover, .sc_button.color_style_dark:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover {
    color: #1d1d1d;
    background-color: $line2;
  }
  .search_wrap .search_submit:hover:before {
    color: #fff;
  }
  .woocommerce {
    .woocommerce-message .button, .woocommerce-error .button, .woocommerce-info .button {
      color: #1d1d1d;
      background-color: $line2;
    }
  }
  .widget {
    &.woocommerce {
      .button, a.button, button.button {
        color: #1d1d1d;
        background-color: $line2;
      }
      input {
        &.button, &[type="button"], &[type="submit"] {
          color: #1d1d1d;
          background-color: $line2;
        }
      }
    }
    &.WOOCS_CONVERTER .button, &.yith-woocompare-widget a.button {
      color: #1d1d1d;
      background-color: $line2;
    }
  }
  .widget_product_search .search_button {
    color: #1d1d1d;
    background-color: $line2;
  }
  .mc4wp-form input[type="submit"] {
    &:hover, &:focus {
      color: #1d1d1d !important;
      background-color: $line2;
    }
    color: #fff !important;
    background-color: $line3;
  }
  .woocommerce {
    .woocommerce-message .button:hover, .woocommerce-error .button:hover, .woocommerce-info .button:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  .widget {
    &.woocommerce {
      .button:hover, a.button:hover, button.button:hover {
        color: #fff;
        background-color: $line3;
      }
      input {
        &.button:hover {
          color: #fff;
          background-color: $line3;
        }
        &[type="button"] {
          &:hover, &:focus {
            color: #fff;
            background-color: $line3;
          }
        }
        &[type="submit"] {
          &:hover, &:focus {
            color: #fff;
            background-color: $line3;
          }
        }
      }
    }
    &.WOOCS_CONVERTER .button:hover, &.yith-woocompare-widget a.button:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  .widget_product_search .search_button:hover {
    color: #fff;
    background-color: $line3;
  }
  .wp-editor-container input[type="button"] {
    background-color: #2c3c4a;
    border-color: #464646;
    color: #fff;
    -webkit-box-shadow: 0 1px 0 0 #4a4a4a;
    -ms-box-shadow: 0 1px 0 0 #4a4a4a;
    box-shadow: 0 1px 0 0 #4a4a4a;
    &:hover, &:focus {
      background-color: #333;
      border-color: #4a4a4a;
      color: $line3;
    }
  }
  .sticky {
    border-color: #2e2c33;
    .label_sticky {
      border-top-color: $line2;
    }
  }
  .sc_layouts_row, &.sc_layouts_row {
    color: #787f86;
    background-color: #0e0d12;
  }
  .sc_layouts_row_delimiter, &.sc_layouts_row_delimiter {
    border-color: #2e2c33;
  }
  .footer_wrap .sc_layouts_row_delimiter {
    border-color: #464646;
  }
}

.footer_wrap .scheme_dark {
  &.vc_row .sc_layouts_row_delimiter, &.sc_layouts_row_delimiter {
    border-color: #464646;
  }
}

.scheme_dark {
  &.footer_wrap {
    .sc_layouts_row_delimiter {
      border-color: #464646;
    }
    .sc_layouts_row_type_normal .sc_layouts_item a, .textwidget, .widget ul li a {
      color: #919497;
    }
    .sc_layouts_row_type_normal .sc_layouts_item a:hover {
      color: $line3;
    }
  }
  .footer_wrap .widget ul li a:hover {
    color: $line3;
  }
  .sc_layouts_item_icon {
    color: #919497;
  }
  .sc_layouts_item_details_line1 {
    color: $line2;
  }
  .sc_layouts_item_details_line2 {
    color: #fff;
  }
  span.trx_addons_login_menu {
    color: #919497;
    background-color: #2c3c4a;
    border-color: #464646;
    &:after {
      color: #919497;
      background-color: #2c3c4a;
      border-color: #464646;
    }
    .trx_addons_login_menu_delimiter {
      border-color: #464646;
    }
    .trx_addons_login_menu_item {
      color: #919497;
      &:hover {
        color: #fff;
        background-color: #333;
      }
    }
  }
  .sc_layouts_row_fixed_on {
    background-color: #0e0d12;
  }
  .sc_layouts_row.sc_layouts_row_type_narrow, &.sc_layouts_row.sc_layouts_row_type_narrow {
    color: #919497;
    background-color: #2c3c4a;
  }
  .sc_layouts_row_type_narrow .sc_layouts_item, &.sc_layouts_row_type_narrow .sc_layouts_item, .sc_layouts_row_type_narrow .sc_layouts_item a, &.sc_layouts_row_type_narrow .sc_layouts_item a {
    color: #919497;
  }
  .sc_layouts_row_type_narrow .sc_layouts_item a:hover, &.sc_layouts_row_type_narrow .sc_layouts_item a:hover {
    color: #fff;
    .sc_layouts_item_icon {
      color: #fff;
    }
  }
  .sc_layouts_row_type_narrow .sc_layouts_item_icon, &.sc_layouts_row_type_narrow .sc_layouts_item_icon {
    color: $line3;
  }
  .sc_layouts_row_type_narrow {
    .sc_layouts_item_details_line1, .sc_layouts_item_details_line2 {
      color: #919497;
    }
  }
  &.sc_layouts_row_type_narrow {
    .sc_layouts_item_details_line1, .sc_layouts_item_details_line2 {
      color: #919497;
    }
  }
  .sc_layouts_row_type_narrow .socials_wrap .social_item .social_icon, &.sc_layouts_row_type_narrow .socials_wrap .social_item .social_icon {
    background-color: transparent;
    color: $line3;
  }
  .sc_layouts_row_type_narrow .socials_wrap .social_item:hover .social_icon, &.sc_layouts_row_type_narrow .socials_wrap .social_item:hover .social_icon {
    background-color: transparent;
    color: #fe7259;
  }
  .sc_layouts_row_type_narrow .sc_button, &.sc_layouts_row_type_narrow .sc_button {
    background-color: transparent;
    border-color: $line3;
    color: $line3;
  }
  .sc_layouts_row_type_narrow .sc_button:hover, &.sc_layouts_row_type_narrow .sc_button:hover {
    background-color: transparent;
    border-color: #fe7259;
    color: #fe7259 !important;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_link2, &.sc_layouts_row_type_narrow .sc_button.color_style_link2 {
    border-color: #8be77c;
    color: #8be77c;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_link2:hover, &.sc_layouts_row_type_narrow .sc_button.color_style_link2:hover {
    border-color: #80d572;
    color: #80d572 !important;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_link3, &.sc_layouts_row_type_narrow .sc_button.color_style_link3 {
    border-color: #eec432;
    color: #eec432;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_link3:hover, &.sc_layouts_row_type_narrow .sc_button.color_style_link2:hover {
    border-color: #ddb837;
    color: #ddb837 !important;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_dark, &.sc_layouts_row_type_narrow .sc_button.color_style_dark {
    border-color: #fff;
    color: #fff;
  }
  .sc_layouts_row_type_narrow .sc_button.color_style_dark:hover, &.sc_layouts_row_type_narrow .sc_button.color_style_dark:hover {
    border-color: $line3;
    color: $line3 !important;
  }
  .sc_layouts_row_type_narrow .search_wrap .search_submit, &.sc_layouts_row_type_narrow .search_wrap .search_submit {
    background-color: transparent;
    color: $line3;
  }
  .sc_layouts_row_type_narrow .search_wrap .search_field, &.sc_layouts_row_type_narrow .search_wrap .search_field, .sc_layouts_row_type_narrow .search_wrap .search_field::-webkit-input-placeholder, &.sc_layouts_row_type_narrow .search_wrap .search_field::-webkit-input-placeholder, .sc_layouts_row_type_narrow .search_wrap .search_field::-moz-placeholder, &.sc_layouts_row_type_narrow .search_wrap .search_field::-moz-placeholder, .sc_layouts_row_type_narrow .search_wrap .search_field:-ms-input-placeholder, &.sc_layouts_row_type_narrow .search_wrap .search_field:-ms-input-placeholder {
    color: #919497;
  }
  .sc_layouts_row_type_narrow .search_wrap .search_field:focus, &.sc_layouts_row_type_narrow .search_wrap .search_field:focus {
    color: #fff;
  }
  .sc_layouts_row_type_compact .sc_layouts_item, &.sc_layouts_row_type_compact .sc_layouts_item, .sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button), &.sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button) {
    color: #787f86;
  }
  .sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button):hover, &.sc_layouts_row_type_compact .sc_layouts_item a:not(.sc_button):not(.button):hover, .sc_layouts_row_type_compact .sc_layouts_item a:hover .sc_layouts_item_icon, &.sc_layouts_row_type_compact .sc_layouts_item a:hover .sc_layouts_item_icon {
    color: #fff;
  }
  .sc_layouts_row_type_compact .sc_layouts_item_icon, &.sc_layouts_row_type_compact .sc_layouts_item_icon {
    color: $line2;
  }
  .sc_layouts_row_type_compact {
    .sc_layouts_item_details_line1, .sc_layouts_item_details_line2 {
      color: #787f86;
    }
  }
  &.sc_layouts_row_type_compact {
    .sc_layouts_item_details_line1, .sc_layouts_item_details_line2 {
      color: #787f86;
    }
  }
  .sc_layouts_row_type_compact .socials_wrap .social_item .social_icon, &.sc_layouts_row_type_compact .socials_wrap .social_item .social_icon {
    background-color: transparent;
    color: #fff;
  }
  .sc_layouts_row_type_compact .socials_wrap .social_item:hover .social_icon, &.sc_layouts_row_type_compact .socials_wrap .social_item:hover .social_icon {
    background-color: transparent;
    color: $line3;
  }
  .sc_layouts_row_type_compact .search_wrap .search_submit, &.sc_layouts_row_type_compact .search_wrap .search_submit {
    background-color: transparent;
    color: #fff;
  }
  .sc_layouts_row_type_compact .search_wrap .search_submit:hover, &.sc_layouts_row_type_compact .search_wrap .search_submit:hover {
    background-color: transparent;
    color: $line3;
  }
  .sc_layouts_row_type_compact .search_wrap.search_style_normal .search_submit, &.sc_layouts_row_type_compact .search_wrap.search_style_normal .search_submit {
    color: $line2;
  }
  .sc_layouts_row_type_compact .search_wrap.search_style_normal .search_submit:hover, &.sc_layouts_row_type_compact .search_wrap.search_style_normal .search_submit:hover {
    color: $line3;
  }
  .sc_layouts_row_type_compact .search_wrap .search_field::-webkit-input-placeholder, &.sc_layouts_row_type_compact .search_wrap .search_field::-webkit-input-placeholder, .sc_layouts_row_type_compact .search_wrap .search_field::-moz-placeholder, &.sc_layouts_row_type_compact .search_wrap .search_field::-moz-placeholder, .sc_layouts_row_type_compact .search_wrap .search_field:-ms-input-placeholder, &.sc_layouts_row_type_compact .search_wrap .search_field:-ms-input-placeholder, .sc_layouts_row_type_normal .sc_layouts_item, &.sc_layouts_row_type_normal .sc_layouts_item, .sc_layouts_row_type_normal .sc_layouts_item a, &.sc_layouts_row_type_normal .sc_layouts_item a {
    color: #787f86;
  }
  .sc_layouts_row_type_normal .sc_layouts_item a:hover, &.sc_layouts_row_type_normal .sc_layouts_item a:hover, .sc_layouts_row_type_normal .sc_layouts_item a:hover .sc_layouts_item_icon, &.sc_layouts_row_type_normal .sc_layouts_item a:hover .sc_layouts_item_icon {
    color: #fff;
  }
  .sc_layouts_row_type_normal .search_wrap .search_submit, &.sc_layouts_row_type_normal .search_wrap .search_submit {
    background-color: transparent;
    color: #969ea5;
  }
  .sc_layouts_row_type_normal .search_wrap .search_submit:hover, &.sc_layouts_row_type_normal .search_wrap .search_submit:hover {
    background-color: transparent;
    color: #fff;
  }
  .sc_layouts_logo {
    b {
      color: #fff;
    }
    i {
      color: $line2;
    }
  }
  .sc_layouts_logo_text {
    color: $line2;
  }
  .sc_layouts_logo:hover .logo_text {
    color: #fff;
  }
  .logo_slogan {
    color: #787f86;
  }
  .search_style_expand {
    &.search_opened {
      background-color: #0e0d12;
      border-color: #2e2c33;
      .search_submit {
        color: #787f86;
      }
    }
    .search_submit {
      &:hover, &:focus {
        color: #fff;
      }
    }
  }
  .search_style_fullscreen.search_opened {
    .search_form_wrap {
      background-color: rgba(14, 13, 18, 0.9);
    }
    .search_form {
      border-color: #fff;
    }
    .search_close, .search_field, .search_submit {
      color: #fff;
    }
    .search_close:hover {
      color: #787f86;
    }
    .search_field {
      &:hover, &:focus {
        color: #787f86;
      }
    }
    .search_submit {
      &:hover, &:focus {
        color: #787f86;
      }
    }
    .search_field {
      &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
        color: #919497;
        opacity: 1;
      }
    }
  }
  .search_wrap .search_results {
    background-color: #0e0d12;
    border-color: #2e2c33;
    &:after {
      background-color: #0e0d12;
      border-left-color: #2e2c33;
      border-top-color: #2e2c33;
    }
    .search_results_close {
      color: #919497;
      &:hover {
        color: #fff;
      }
    }
  }
  .search_results.widget_area .post_item + .post_item {
    border-top-color: #2e2c33;
  }
  .sc_layouts_title {
    .sc_layouts_title_meta, .post_meta {
      color: #fff;
    }
    .post_meta_item {
      color: #fff;
      a, &:before, &:after {
        color: #fff;
      }
      &:hover {
        &:before, &:after {
          color: #fff;
        }
      }
      &.post_categories {
        color: #fff;
        a {
          color: #fff;
        }
      }
    }
    .post_date {
      a, &:before, &:after {
        color: #fff;
      }
    }
    .post_info .post_info_item {
      color: #fff;
      a {
        color: #fff;
      }
    }
    .post_info_counters .post_counters_item {
      color: #fff;
    }
    .post_counters .socials_share .socials_caption {
      &:before, &:hover:before {
        color: #fff;
      }
    }
    .post_date a:hover {
      color: #919497;
    }
    a.post_meta_item:hover {
      color: #919497;
      &:before {
        color: #919497;
      }
    }
    .post_meta_item {
      a:hover {
        color: #919497;
        &:before {
          color: #919497;
        }
      }
      &.post_categories a:hover {
        color: #919497;
      }
    }
    .post_info .post_info_item a:hover, .post_info_counters .post_counters_item:hover {
      color: #919497;
    }
    .sc_layouts_title_description {
      color: #fff;
    }
  }
  .sc_layouts_title_breadcrumbs {
    color: #fff;
    a {
      color: #fff !important;
      &:hover {
        color: #919497 !important;
      }
    }
  }
  .sc_layouts_menu_nav {
    > li {
      > a {
        color: #fff;
        &:hover {
          color: $line2 !important;
        }
      }
      &.sfHover > a {
        color: $line2 !important;
      }
      &.current-menu-item > a, &.current-menu-parent > a, &.current-menu-ancestor > a {
        color: #fff !important;
      }
    }
    .menu-collapse > a {
      &:before {
        color: #919497;
      }
      &:after {
        background-color: #2c3c4a;
      }
      &:hover {
        &:before {
          color: $line3;
        }
        &:after {
          background-color: #333;
        }
      }
    }
  }
  .sc_layouts_menu_popup .sc_layouts_menu_nav, .sc_layouts_menu_nav > li ul {
    background-color: #1e1d22;
  }
  .sc_layouts_menu_popup .sc_layouts_menu_nav > li > a, .sc_layouts_menu_nav > li li > a {
    color: #a6a6a6 !important;
  }
  .sc_layouts_menu_popup .sc_layouts_menu_nav > li {
    > a:hover, &.sfHover > a {
      color: #fe7259 !important;
      background-color: #28272e;
    }
  }
  .sc_layouts_menu_nav {
    > li li {
      > a:hover, &.sfHover > a {
        color: #fe7259 !important;
        background-color: #28272e;
      }
    }
    li[class*="columns-"] li.menu-item-has-children {
      > a:hover, &.sfHover > a {
        color: #a6a6a6 !important;
        background-color: transparent;
      }
    }
    > li li {
      &[class*="icon-"] {
        &:before, &:hover:before, &.shHover:before {
          color: #fe7259;
        }
      }
      &.current-menu-item > a, &.current-menu-parent > a, &.current-menu-ancestor > a, &.current-menu-item:before, &.current-menu-parent:before, &.current-menu-ancestor:before {
        color: #fe7259 !important;
      }
    }
  }
  &.menu_side_wrap .menu_side_button {
    color: #fff;
    border-color: #464646;
    background-color: rgba(44, 60, 74, 0.7);
    &:hover {
      color: #1d1d1d;
      border-color: #fe7259;
      background-color: $line3;
    }
  }
  .menu_side_inner, .menu_mobile_inner {
    color: #919497;
    background-color: #2c3c4a;
  }
  .menu_mobile_button {
    color: #fff;
    &:hover {
      color: $line2;
    }
  }
  .menu_mobile_close {
    &:before, &:after {
      border-color: #fff;
    }
    &:hover {
      &:before, &:after {
        border-color: $line3;
      }
    }
  }
  .menu_mobile_inner {
    a, .menu_mobile_nav_area li:before {
      color: #fff;
    }
    a:hover, .current-menu-ancestor > a, .current-menu-item > a {
      color: $line3;
    }
    .menu_mobile_nav_area li {
      &:hover:before, &.current-menu-ancestor:before, &.current-menu-item:before {
        color: $line3;
      }
    }
    .search_mobile .search_submit {
      color: #969ea5;
      &:focus, &:hover {
        color: #fff;
      }
    }
    .social_item {
      .social_icon {
        color: $line3;
      }
      &:hover .social_icon {
        color: #fff;
      }
    }
  }
  .menu_hover_fade_box .sc_layouts_menu_nav > {
    a:hover {
      color: $line3;
      background-color: #2c3c4a;
    }
    li {
      > a:hover, &.sfHover > a {
        color: $line3;
        background-color: #2c3c4a;
      }
    }
  }
  .menu_hover_slide_line .sc_layouts_menu_nav > li#blob {
    background-color: $line2;
  }
  .menu_hover_slide_box .sc_layouts_menu_nav > li#blob {
    background-color: #2c3c4a;
  }
  .menu_hover_zoom_line .sc_layouts_menu_nav > li > a:before {
    background-color: $line2;
  }
  .menu_hover_path_line .sc_layouts_menu_nav > li {
    &:before, &:after {
      background-color: $line2;
    }
    > a {
      &:before, &:after {
        background-color: $line2;
      }
    }
  }
  .menu_hover_roll_down .sc_layouts_menu_nav > li > a:before {
    background-color: $line2;
  }
  .menu_hover_color_line .sc_layouts_menu_nav > li {
    > a {
      &:before {
        background-color: #fff;
      }
      &:after {
        background-color: $line2;
      }
    }
    &.menu-item-has-children > a:after {
      background-color: $line2;
    }
    &.sfHover > a {
      color: $line2;
    }
    > a {
      &:hover, &:focus {
        color: $line2;
      }
    }
  }
  &.sc_layouts_row .vc_separator.vc_sep_color_grey .vc_sep_line, .sc_layouts_row .vc_separator.vc_sep_color_grey .vc_sep_line {
    border-color: #464646;
  }
  .sc_layouts_cart_items_short {
    background-color: #fff;
    color: #0e0d12;
  }
  .sc_layouts_cart_widget {
    border-color: #2e2c33;
    background-color: #0e0d12;
    color: #787f86;
    &:after {
      border-color: #2e2c33;
      background-color: #0e0d12;
    }
    .sc_layouts_cart_widget_close {
      color: #919497;
      &:hover {
        color: #fff;
      }
    }
  }
  .sc_layouts_currency {
    .woocommerce-currency-switcher-form .wSelect-selected {
      color: #919497;
      &:hover {
        color: #fff;
      }
    }
    .chosen-container .chosen-results {
      background: #2c3c4a;
      color: #fff;
    }
    .woocommerce-currency-switcher-form {
      .wSelect-options-holder, .dd-options, .dd-option {
        background: #2c3c4a;
        color: #fff;
      }
    }
    .chosen-container .chosen-results li, .woocommerce-currency-switcher-form .wSelect-option {
      color: #fff;
    }
    .chosen-container .active-result {
      &.highlighted, &.result-selected {
        color: $line3 !important;
      }
    }
    .woocommerce-currency-switcher-form {
      .wSelect-option:hover, .wSelect-options-holder .wSelect-option-selected, .dd-option:hover, .dd-option-selected {
        color: $line3 !important;
      }
      .dd-option-description {
        color: #919497;
      }
    }
  }
  #page_preloader, &.header_position_under .page_content_wrap, .page_wrap {
    background-color: #0e0d12;
  }
  .preloader_wrap > div {
    background-color: $line2;
  }
  &.top_panel {
    &.with_bg_image:before {
      background-color: rgba(14, 13, 18, 0.7);
    }
    .slider_engine_revo .slide_subtitle {
      color: $line2;
    }
  }
  .top_panel .slider_engine_revo .slide_subtitle {
    color: $line2;
  }
  .top_panel_default .top_panel_navi, &.top_panel_default .top_panel_navi {
    background-color: #0e0d12;
  }
  .top_panel_default .top_panel_title, &.top_panel_default .top_panel_title {
    background-color: #2c3c4a;
  }
  div.esg-filter-wrapper .esg-filterbutton > span, .mptt-navigation-tabs li a, .alpha_color_tabs .alpha_color_tabs_titles li a {
    color: #fff;
    background-color: #2c3c4a;
  }
  div.esg-filter-wrapper .esg-filterbutton > span:hover, .mptt-navigation-tabs li a:hover, .alpha_color_tabs .alpha_color_tabs_titles li a:hover {
    color: #fff;
    background-color: $line2;
  }
  div.esg-filter-wrapper .esg-filterbutton.selected > span, .mptt-navigation-tabs li.active a, .alpha_color_tabs .alpha_color_tabs_titles li.ui-state-active a {
    color: #fff;
    background-color: $line3;
  }
  .post_item {
    color: #787f86;
  }
  .post_meta {
    color: #919497;
  }
  .post_meta_item {
    color: #919497;
    a, &:before, &:after {
      color: #919497;
    }
    &:hover {
      &:before, &:after {
        color: #919497;
      }
    }
  }
  .post_date {
    a, &:before, &:after {
      color: #919497;
    }
  }
  .post_info .post_info_item {
    color: #919497;
    a {
      color: #919497;
    }
  }
  .post_info_counters .post_counters_item {
    color: #919497;
  }
  .post_counters .socials_share .socials_caption {
    &:before, &:hover:before {
      color: #919497;
    }
  }
  .post_date a:hover {
    color: #fff;
  }
  a.post_meta_item:hover, .post_meta_item a:hover, .post_info .post_info_item a:hover, .post_info_counters .post_counters_item:hover {
    color: #fff;
    &:before {
      color: #fff;
    }
  }
  .post_item .post_title a:hover {
    color: $line3;
  }
  .post_meta_item.post_author {
    color: #fff;
    &:hover {
      color: $line3;
    }
  }
  .post-bottom {
    border-color: #2e2c33;
  }
  .post_meta_item {
    .socials_share .social_items {
      background-color: #0e0d12;
    }
    .social_items {
      background-color: #0e0d12;
      border-color: #2e2c33;
      color: #919497;
      &:before {
        background-color: #0e0d12;
        border-color: #2e2c33;
        color: #919497;
      }
    }
  }
  .post_layout_excerpt:not(.sticky) + .post_layout_excerpt:not(.sticky), .post_layout_classic {
    border-color: #2e2c33;
  }
  &.gallery_preview {
    &:before {
      background-color: #0e0d12;
    }
    color: #787f86;
  }
  .trx_addons_audio_player .audio_author {
    color: $line2;
  }
  .format-audio .post_featured {
    .post_audio_author {
      color: $line2;
    }
    &.without_thumb {
      .post_audio {
        border-color: #2e2c33;
      }
      .post_audio_title {
        color: #fff;
      }
    }
  }
  .without_thumb .mejs-controls {
    .mejs-currenttime, .mejs-duration {
      color: #fff;
    }
  }
  .trx_addons_audio_player {
    &.without_cover {
      border-color: #2c3c4a;
      background-color: #2c3c4a;
    }
    &.with_cover .audio_caption {
      color: #fff;
    }
    &.without_cover .audio_author {
      color: $line3;
    }
    .mejs-container .mejs-controls .mejs-time, &.with_cover .mejs-container .mejs-controls .mejs-time {
      color: #fff;
    }
  }
  .mejs-container .mejs-controls {
    background: rgba(255, 255, 255, 0.7);
  }
  .mejs-embed {
    background: rgba(255, 255, 255, 0.7);
    body {
      background: rgba(255, 255, 255, 0.7);
    }
  }
  .mejs-controls {
    .mejs-button, .mejs-time-rail .mejs-time-current, .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
      color: #fff;
      background: $line2;
    }
    .mejs-button:hover {
      color: #1d1d1d;
      background: $line3;
    }
    .mejs-time-rail {
      .mejs-time-total, .mejs-time-loaded {
        background: rgba(253, 230, 66, 0.2);
      }
    }
  }
  .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
    background: rgba(253, 230, 66, 0.2);
  }
  .format-aside .post_content_inner {
    color: #fff;
    background-color: #2c3c4a;
  }
  .format-link .post_content_inner, .format-status .post_content_inner {
    color: #fff;
  }
  .format-chat p > {
    b, strong {
      color: #fff;
    }
  }
  .trx_addons_video_player.with_cover .video_hover, .format-video .post_featured.with_thumb .post_video_hover {
    color: #fff;
    background-color: $line3;
  }
  .trx_addons_video_player.with_cover .video_hover:hover, .format-video .post_featured.with_thumb .post_video_hover:hover {
    color: #fff;
    background-color: $line2;
  }
  .sidebar_inner .trx_addons_video_player.with_cover .video_hover {
    color: $line3;
    &:hover {
      color: #1d1d1d;
      background-color: $line3;
    }
  }
  .post_layout_chess .post_content_inner:after {
    background: linear-gradient(to top, #0e0d12 0%, rgba(14, 13, 18, 0) 100%) no-repeat scroll right (top / 100%) 100% rgba(14, 13, 18, 0);
  }
  .post_layout_chess_1 .post_meta:before {
    background-color: #2e2c33;
  }
  .nav-links-old {
    color: #fff;
    a:hover {
      color: #fff;
      border-color: #fff;
    }
  }
  div.esg-pagination .esg-pagination-button, .woocommerce nav.woocommerce-pagination ul li a, .page_links > a, .comments_pagination .page-numbers, .nav-links .page-numbers {
    color: #fff;
    background-color: $line2;
  }
  div.esg-pagination .esg-pagination-button {
    &:hover, &.selected {
      color: #fff;
      background-color: $line3;
    }
  }
  .woocommerce nav.woocommerce-pagination ul li {
    a:hover, span.current {
      color: #fff;
      background-color: $line3;
    }
  }
  .page_links > {
    a:hover, span:not(.page_links_title) {
      color: #fff;
      background-color: $line3;
    }
  }
  .comments_pagination {
    a.page-numbers:hover, .page-numbers.current {
      color: #fff;
      background-color: $line3;
    }
  }
  .nav-links {
    a.page-numbers:hover, .page-numbers.current {
      color: #fff;
      background-color: $line3;
    }
  }
  .post_item_single {
    .post_header {
      .post_date {
        color: #919497;
      }
      .post_categories {
        color: $line2;
        a {
          color: $line2;
        }
      }
      .post_meta_item {
        color: #919497;
        &:before, &:hover:before {
          color: #919497;
        }
        a {
          color: #919497;
          &:before, &:hover:before {
            color: #919497;
          }
        }
        .socials_caption {
          color: #919497;
          &:before {
            color: #919497;
          }
        }
      }
      .post_edit a {
        color: #919497;
      }
    }
    .post_meta_item {
      &:hover, > a:hover, .socials_caption:hover {
        color: $line3;
      }
    }
    .post_edit a:hover {
      color: $line3;
    }
    .post_content {
      .post_meta_label, .post_meta_item:hover .post_meta_label {
        color: #fff;
      }
      .post_tags {
        color: #919497;
        a {
          color: #919497;
          &:hover {
            color: $line3;
          }
        }
      }
      .post_meta .post_share .social_item {
        .social_icon {
          color: #fff !important;
          background-color: $line2;
        }
        &:hover .social_icon {
          color: #1d1d1d !important;
          background-color: $line3;
        }
      }
    }
  }
  .post-password-form input[type="submit"] {
    border-color: #fff;
    &:hover, &:focus {
      color: #0e0d12;
    }
  }
  .nav-links-single .nav-links {
    border-color: #2e2c33;
    a {
      .meta-nav, .post_date {
        color: #919497;
      }
      &:hover {
        .meta-nav, .post_date {
          color: #fff;
        }
        .post-title {
          color: $line2;
        }
      }
    }
  }
  &.author_info {
    color: #787f86;
    background-color: #2c3c4a;
    .author_title {
      color: #fff;
    }
    a {
      color: #fff;
      &:hover {
        color: $line2;
      }
    }
    .socials_wrap .social_item {
      .social_icon {
        color: #fff;
        background-color: $line2;
      }
      &:hover .social_icon {
        color: #1d1d1d;
        background-color: $line3;
      }
    }
  }
  .related_wrap {
    border-color: #2e2c33;
    .related_item_style_1 {
      .post_header {
        background-color: rgba(14, 13, 18, 0.7);
      }
      &:hover .post_header {
        background-color: #0e0d12;
      }
      .post_date a {
        color: #787f86;
      }
      &:hover .post_date a {
        color: #919497;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .comments_list_wrap {
    border-color: #2e2c33;
    > ul {
      border-color: #2e2c33;
    }
    li {
      + li, ul {
        border-color: #2e2c33;
      }
    }
    .bypostauthor > .comment_body .comment_author_avatar:after {
      border-color: $line1;
    }
    .comment_info {
      color: #fff;
    }
    .comment_counters a {
      color: $line2;
      &:before {
        color: $line2;
      }
      &:hover {
        &:before {
          color: $line3;
        }
        color: $line3;
      }
    }
    .comment_text {
      color: #787f86;
    }
    .comment_reply a {
      color: #fff;
      &:hover {
        color: $line3;
      }
    }
  }
  .comments_form_wrap {
    border-color: #2e2c33;
  }
  .comments_wrap .comments_notes {
    color: #919497;
  }
  .post_item_404 {
    .page_title {
      color: #919497;
    }
    .page_description {
      color: $line3;
    }
    .go_home {
      color: #fff !important;
    }
  }
  &.sidebar .sidebar_inner .widget {
    background-color: #0e0d12;
    color: #787f86;
    border-color: #2e2c33;
  }
  .sidebar[class*="scheme_"] .widget {
    &:nth-child(4n+1):before {
      border-top-color: $line1;
    }
    &:nth-child(4n+2) {
      border-top-color: $line2;
      &:before {
        border-top-color: $line2;
      }
    }
    &:nth-child(4n+3):before {
      border-top-color: $line3;
    }
    &:nth-child(4n+4):before {
      border-top-color: #fff;
    }
  }
  &.sidebar {
    h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
      color: #fff;
    }
    h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
      color: $line3;
    }
  }
  .widget ul > li:before {
    color: $line3;
  }
  &.sidebar {
    a {
      color: $line3;
      &:hover {
        color: #fe7259;
      }
    }
    li > a {
      color: #919497;
    }
    .post_title > a {
      color: #fff;
    }
    li > a:hover, .post_title > a:hover {
      color: $line3;
    }
    .widget_archive li {
      color: #fff;
    }
  }
  .widget_calendar {
    caption, tbody td a, th {
      color: #fff;
    }
  }
  &.sidebar .widget_calendar {
    caption {
      color: $line3;
      border-color: #2e2c33;
    }
    tbody td a, th {
      color: #fff;
    }
  }
  .widget_calendar tbody td {
    color: #787f86 !important;
  }
  &.sidebar .widget_calendar tbody td {
    color: #969ea5 !important;
  }
  .widget_calendar tbody td a:hover {
    color: $line2;
  }
  &.sidebar .widget_calendar tbody td a:hover {
    color: $line3;
  }
  .widget_calendar tbody td a:after {
    background-color: $line2;
  }
  &.sidebar .widget_calendar tbody td a:after {
    background-color: $line3;
  }
  .widget_calendar td#today {
    color: #1d1d1d !important;
    a {
      color: #fff;
      &:hover {
        color: #1d1d1d;
      }
    }
    &:before {
      background-color: $line2;
    }
  }
  &.sidebar .widget_calendar td#today:before {
  }
  .widget_calendar {
    td#today a {
      &:after {
        background-color: #fff;
      }
      &:hover:after {
        background-color: #1d1d1d;
      }
    }
    #prev a, #next a {
      color: $line2;
    }
  }
  &.sidebar .widget_calendar {
    #prev a, #next a {
      color: $line3;
    }
  }
  .widget_calendar {
    #prev a:hover, #next a:hover {
      color: $line3;
    }
  }
  &.sidebar .widget_calendar {
    #prev a:hover, #next a:hover {
      color: #fff;
    }
  }
  .widget_calendar td {
    &#prev a:before, &#next a:before {
      background-color: #0e0d12;
    }
  }
  .widget.widget_recent_comments ul li {
    color: #919497;
    border-color: #2e2c33;
    a {
      color: #fff;
      &:hover {
        color: $line3;
      }
    }
  }
  .widget_categories li, &.sidebar .widget_categories li {
    color: #fff;
  }
  .sc_edd_details .downloads_page_tags .downloads_page_data > a, .widget_product_tag_cloud a, .widget_tag_cloud a {
    color: #fff;
    background-color: $line3;
  }
  .sc_edd_details .downloads_page_tags .downloads_page_data > a:hover, .widget_product_tag_cloud a:hover, .widget_tag_cloud a:hover {
    color: #fff !important;
    background-color: $line2;
  }
  .widget_product_tag_cloud, .widget_tag_cloud {
    border-top-color: $line2;
  }
  .widget_rss .widget_title a:first-child {
    color: $line2;
  }
  &.sidebar .widget_rss .widget_title a:first-child, .widget_rss .widget_title a:first-child:hover {
    color: $line3;
  }
  &.sidebar .widget_rss .widget_title a:first-child:hover {
    color: #fe7259;
  }
  .widget_rss .rss-date {
    color: #919497;
  }
  &.sidebar .widget_rss .rss-date {
    color: #5f5f5f;
  }
  &.footer_wrap {
    background-color: #2c3c4a;
    color: #919497;
  }
}

/* Row type:Narrow */

/* Row type:Compact */

/* Row type:Normal */

.footer_wrap .scheme_dark.vc_row {
  background-color: #2c3c4a;
  color: #919497;
}

.scheme_dark.footer_wrap {
  .widget, .sc_content .wpb_column {
    border-color: #464646;
  }
}

.footer_wrap .scheme_dark.vc_row {
  .widget, .sc_content .wpb_column {
    border-color: #464646;
  }
}

.scheme_dark.footer_wrap {
  h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #fff;
  }
}

.footer_wrap .scheme_dark.vc_row {
  h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #fff;
  }
}

.scheme_dark.footer_wrap {
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
    color: $line3;
  }
}

.footer_wrap .scheme_dark.vc_row {
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
    color: $line3;
  }
}

.scheme_dark.footer_wrap .widget li:before, .footer_wrap .scheme_dark.vc_row .widget li:before {
  background-color: $line3;
}

.scheme_dark.footer_wrap a, .footer_wrap .scheme_dark.vc_row a {
  color: #919497;
}

.scheme_dark.footer_wrap a:hover, .footer_wrap .scheme_dark.vc_row a:hover {
  color: $line3;
}

.scheme_dark {
  .footer_logo_inner {
    border-color: #464646;
    &:after {
      background-color: #919497;
    }
  }
  .footer_socials_inner .social_item {
    .social_icon {
      color: #919497;
    }
    &:hover .social_icon {
      color: #fff;
    }
  }
  .menu_footer_nav_area {
    ul li {
      a {
        color: #fff;
        &:hover {
          color: $line3;
        }
      }
      + li:before {
        border-color: #5f5f5f;
      }
    }
    > ul > li ul {
      border-color: #464646;
    }
  }
  .footer_wrap .sc_layouts_menu > ul > li ul {
    border-color: #464646;
  }
  .footer_copyright_inner {
    background-color: #0e0d12;
    border-color: #2e2c33;
    color: #fff;
    a {
      color: #fff;
      &:hover {
        color: $line2;
      }
    }
    .copyright_text {
      color: #787f86;
    }
  }
  .mfp-bg {
    background-color: rgba(14, 13, 18, 0.7);
  }
  .mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close, .mfp-close-btn-in .mfp-close {
    color: #fff;
    background-color: transparent;
  }
  .mfp-image-holder .mfp-close:hover, .mfp-iframe-holder .mfp-close:hover, .mfp-close-btn-in .mfp-close:hover {
    color: $line2;
  }
  button[disabled] {
    background-color: #919497 !important;
    color: #787f86 !important;
  }
  input {
    &[type="submit"][disabled], &[type="button"][disabled] {
      background-color: #919497 !important;
      color: #787f86 !important;
    }
  }
  .trx_addons_accent {
    color: $line2;
    > {
      a, * {
        color: $line2;
      }
      a:hover {
        color: #fff;
      }
    }
  }
  .sidebar .trx_addons_accent, &.sidebar .trx_addons_accent, .sidebar .trx_addons_accent > a, &.sidebar .trx_addons_accent > a, .sidebar .trx_addons_accent > *, &.sidebar .trx_addons_accent > *, .footer_wrap .trx_addons_accent, &.footer_wrap .trx_addons_accent, .footer_wrap .trx_addons_accent > a, &.footer_wrap .trx_addons_accent > a, .footer_wrap .trx_addons_accent > *, &.footer_wrap .trx_addons_accent > * {
    color: $line3;
  }
  .sidebar .trx_addons_accent > a:hover, &.sidebar .trx_addons_accent > a:hover, .footer_wrap .trx_addons_accent > a:hover, &.footer_wrap .trx_addons_accent > a:hover {
    color: #fff;
  }
  .trx_addons_hover {
    color: $line3;
    > * {
      color: $line3;
    }
  }
  .trx_addons_accent_bg {
    background-color: $line2;
    color: #1d1d1d;
  }
  .trx_addons_inverse {
    color: #0e0d12;
    background-color: #fff;
  }
  .trx_addons_dark {
    color: #fff;
    > a {
      color: #fff;
      &:hover {
        color: $line2;
      }
    }
  }
  .trx_addons_inverse {
    color: #0e0d12;
    background-color: #fff;
    > a {
      color: #0e0d12;
      background-color: #fff;
      &:hover {
        color: #1d1d1d;
      }
    }
  }
  .trx_addons_dropcap_style_1 {
    color: #fff;
    background-color: $line2;
  }
  .trx_addons_dropcap_style_2 {
    color: #1d1d1d;
    background-color: $line3;
  }
  ul[class*="trx_addons_list"] {
    > li:before {
      color: $line2;
    }
    &[class*="_circled"] > li:before {
      color: #fff;
      background-color: $line2;
    }
  }
  .trx_addons_list_parameters > li + li {
    border-color: #2e2c33;
  }
  .trx_addons_tooltip {
    color: #fff;
    border-color: #fff;
    &:before {
      color: #0e0d12;
      background-color: #fff;
    }
    &:after {
      border-top-color: #fff;
    }
  }
  blockquote {
    &.trx_addons_blockquote_style_1 {
      &:before {
        color: #0e0d12;
        background-color: #fff;
      }
      color: #0e0d12;
      background-color: #fff;
      b {
        color: #0e0d12;
      }
      a, cite {
        color: $line2;
      }
      a:hover {
        color: #0e0d12;
      }
    }
    &.trx_addons_blockquote_style_2 {
      color: #1d1d1d;
      background-color: $line2;
      &:before, a, cite {
        color: #fff;
      }
      a:hover {
        color: #1d1d1d;
      }
    }
  }
  .trx_addons_hover_mask {
    background-color: rgba(30, 29, 34, 0.7);
  }
  .trx_addons_hover_title {
    color: #fff;
  }
  .trx_addons_hover_text {
    color: #a6a6a6;
  }
  .trx_addons_hover_icon, .trx_addons_hover_links a {
    color: #fff;
    background-color: #ffaa5f;
  }
  .trx_addons_hover_icon:hover, .trx_addons_hover_links a:hover {
    color: #1d1d1d !important;
    background-color: #fe7259;
  }
  .widget .trx_addons_tabs .trx_addons_tabs_titles li {
    a {
      color: #919497;
      background-color: #464646;
    }
    &.ui-state-active a, a:hover {
      color: #fff;
      background-color: $line3;
    }
  }
  .slider_container {
    .slide_info.slide_info_large {
      background-color: rgba(14, 13, 18, 0.7);
      &:hover {
        background-color: #0e0d12;
      }
      .slide_cats a {
        color: $line2;
      }
      .slide_title a {
        color: #fff;
      }
      .slide_date {
        color: #787f86;
      }
      &:hover .slide_date {
        color: #919497;
      }
      .slide_cats a:hover, .slide_title a:hover {
        color: $line3;
      }
    }
    &.slider_multi {
      .slide_cats a:hover, .slide_title a:hover, a:hover .slide_title {
        color: $line3;
      }
    }
  }
  .sc_slider_controls .slider_controls_wrap > a, .slider_container.slider_controls_side .slider_controls_wrap > a, .slider_outer_controls_side .slider_controls_wrap > a {
    color: $line2;
    background-color: #fff;
    border-color: #fff;
  }
  .sc_slider_controls .slider_controls_wrap > a:hover, .slider_container.slider_controls_side .slider_controls_wrap > a:hover, .slider_outer_controls_side .slider_controls_wrap > a:hover {
    color: #fff;
    background-color: $line2;
    border-color: $line2;
  }
  .sc_slider_controls {
    .slider_progress {
      background-color: #2e2c33;
    }
    .slider_progress_bar {
      background-color: $line2;
    }
  }
  .slider_container {
    &.slider_controls_top .slider_controls_wrap > a, &.slider_controls_bottom .slider_controls_wrap > a {
      color: #0e0d12;
      background-color: #fff;
      border-color: #fff;
    }
  }
  .slider_outer_controls_top .slider_controls_wrap > a, .slider_outer_controls_bottom .slider_controls_wrap > a {
    color: #0e0d12;
    background-color: #fff;
    border-color: #fff;
  }
  .slider_container {
    &.slider_controls_top .slider_controls_wrap > a:hover, &.slider_controls_bottom .slider_controls_wrap > a:hover {
      color: #0e0d12;
      border-color: $line2;
      background-color: #1d1d1d;
    }
  }
  .slider_outer_controls_top .slider_controls_wrap > a:hover, .slider_outer_controls_bottom .slider_controls_wrap > a:hover {
    color: #0e0d12;
    border-color: $line2;
    background-color: #1d1d1d;
  }
  .slider_container .slider_pagination_wrap .swiper-pagination-bullet, .slider_outer .slider_pagination_wrap .swiper-pagination-bullet {
    border-color: #464646;
    background-color: #2c3c4a;
  }
  .swiper-pagination-custom .swiper-pagination-button {
    border-color: #464646;
    background-color: #2c3c4a;
    &.swiper-pagination-button-active {
      border-color: $line2;
      background-color: $line2;
    }
  }
  .slider_container .slider_pagination_wrap .swiper-pagination-bullet.swiper-pagination-bullet-active, .slider_outer .slider_pagination_wrap .swiper-pagination-bullet.swiper-pagination-bullet-active, .slider_container .slider_pagination_wrap .swiper-pagination-bullet:hover, .slider_outer .slider_pagination_wrap .swiper-pagination-bullet:hover {
    border-color: $line2;
    background-color: $line2;
  }
  .slider_container .swiper-pagination-progress .swiper-pagination-progressbar {
    background-color: $line2;
  }
  .slider_outer {
    .swiper-pagination-progress .swiper-pagination-progressbar {
      background-color: $line2;
    }
    > .swiper-pagination-fraction {
      color: #fff;
    }
  }
  .slider_titles_outside_wrap {
    .slide_title a {
      color: #fff;
      &:hover {
        color: $line2;
      }
    }
    .slide_cats, .slide_subtitle {
      color: $line2;
    }
  }
  .slider_style_modern {
    .slider_controls_label {
      color: #0e0d12;
    }
    .slider_pagination_wrap {
      color: #919497;
    }
    .swiper-pagination-current {
      color: #fff;
    }
  }
  .sc_slider_controller .slider-slide.swiper-slide-active {
    border-color: $line2;
  }
  .sc_slider_controller_titles {
    .slider-slide {
      background-color: #2c3c4a;
      &:after {
        background-color: #464646;
      }
      &.swiper-slide-active {
        background-color: #0e0d12;
      }
    }
    .sc_slider_controller_info_title {
      color: #fff;
    }
    .sc_slider_controller_info_number {
      color: #5f5f5f;
    }
    .slider_controls_wrap > a {
      color: #fff;
      background-color: $line2;
      &:hover {
        color: #0e0d12;
        background-color: #fff;
      }
    }
  }
  .widget_categories_list {
    .categories_list_style_1 .categories_list_item:hover .categories_list_title, .categories_list_style_3 .categories_list_item:hover .categories_list_title {
      color: $line2;
    }
    .categories_list_style_2 .categories_list_title a:hover {
      color: #fff;
    }
  }
  .widget_contacts .contacts_info {
    color: #919497;
    span {
      &:before {
        color: $line3;
      }
      a {
        color: #fff;
        &:hover {
          color: $line3;
        }
      }
    }
    > {
      div > a:before, a:before {
        color: $line3;
      }
    }
    > {
      div > a, a {
        color: #fff;
      }
    }
    > {
      div > a:hover, a:hover {
        color: $line3;
      }
    }
  }
  .sc_recent_news_header {
    border-color: #fff;
  }
  .sc_recent_news_header_category_item_more {
    color: $line2;
  }
  .sc_recent_news_header_more_categories {
    border-color: #464646;
    background-color: #1e1d22;
    > a {
      color: #ffaa5f;
      &:hover {
        color: #fe7259;
        background-color: #28272e;
      }
    }
  }
  .sc_recent_news {
    .post_counters_item, .post_counters .post_counters_edit a {
      color: #fff;
      background-color: $line2;
    }
    .post_counters_item:hover, .post_counters .post_counters_edit a:hover {
      color: #0e0d12;
      background-color: #fff;
    }
  }
  .sidebar_inner .sc_recent_news {
    .post_counters_item:hover, .post_counters .post_counters_edit a:hover {
      color: #fff;
      background-color: #2c3c4a;
    }
  }
  .sc_recent_news_style_news-magazine .post_accented_border, .sc_recent_news_style_news-excerpt .post_item {
    border-color: #2e2c33;
  }
  .widget_twitter {
    .widget_content {
      .sc_twitter_item, li {
        color: #787f86;
      }
      .sc_twitter_item .sc_twitter_item_icon {
        color: $line2 !important;
      }
    }
    .swiper-pagination-bullet {
      background-color: #919497;
    }
    .swiper-pagination-bullet-active {
      background-color: $line2;
    }
    .widget_content .sc_twitter_list li {
      color: #919497;
      &:before {
        color: $line3 !important;
      }
    }
  }
  .sc_item_subtitle {
    color: $line2;
  }
  .color_style_link2 .sc_item_subtitle {
    color: $line1;
  }
  .color_style_link3 .sc_item_subtitle {
    color: $line3;
  }
  .sc_item_subtitle.sc_item_title_style_shadow {
    color: #919497;
  }
  .theme_scroll_down:hover {
    color: $line2;
  }
  .sc_action_item {
    .sc_action_item_subtitle {
      color: $line2;
    }
    &.color_style_link2 .sc_action_item_subtitle {
      color: $line1;
    }
    &.color_style_link3 .sc_action_item_subtitle {
      color: $line3;
    }
    &.color_style_dark .sc_action_item_subtitle {
      color: #fff;
    }
  }
  .sc_action_item_event {
    .sc_action_item_date, .sc_action_item_info {
      color: #fff;
      border-color: #787f86;
    }
    .sc_action_item_description {
      color: #787f86;
    }
    &.with_image .sc_action_item_inner {
      background-color: #0e0d12;
    }
  }
  &.menu_side_icons .toc_menu_item .toc_menu_icon, .menu_side_inner > .toc_menu_item .toc_menu_icon {
    background-color: #0e0d12;
    border-color: #2e2c33;
    color: $line2;
  }
  &.menu_side_icons {
    .toc_menu_item:hover .toc_menu_icon, .toc_menu_item_active .toc_menu_icon {
      background-color: $line2;
      color: #fff;
    }
  }
  .menu_side_inner > {
    .toc_menu_item:hover .toc_menu_icon, .toc_menu_item_active .toc_menu_icon {
      background-color: $line2;
      color: #fff;
    }
  }
  &.menu_side_icons .toc_menu_icon_default:before, .menu_side_inner > .toc_menu_icon_default:before {
    background-color: $line2;
  }
  &.menu_side_icons {
    .toc_menu_item:hover .toc_menu_icon_default:before, .toc_menu_item_active .toc_menu_icon_default:before {
      background-color: #fff;
    }
  }
  .menu_side_inner > {
    .toc_menu_item:hover .toc_menu_icon_default:before, .toc_menu_item_active .toc_menu_icon_default:before {
      background-color: #fff;
    }
  }
  &.menu_side_icons .toc_menu_item .toc_menu_description, .menu_side_inner > .toc_menu_item .toc_menu_description {
    color: #fff;
    background-color: $line2;
  }
  &.menu_side_dots #toc_menu {
    .toc_menu_item {
      .toc_menu_icon {
        background-color: #2c3c4a;
        color: #919497;
      }
      &:hover .toc_menu_icon {
        color: $line3;
      }
    }
    .toc_menu_item_active .toc_menu_icon {
      color: $line3;
    }
    .toc_menu_item {
      .toc_menu_icon:before {
        background-color: $line3;
      }
      &:hover .toc_menu_icon:before {
        background-color: #fe7259;
      }
    }
  }
  .sc_blogger.slider_container .swiper-pagination-bullet {
    border-color: #919497;
  }
  .sc_blogger_item {
    background-color: #2c3c4a;
  }
  .sc_blogger_post_meta {
    color: #5f5f5f;
  }
  .sc_blogger_item_title a {
    color: #fff;
    &:hover {
      color: $line3;
    }
  }
  .sc_blogger_post_meta {
    color: #5f5f5f;
  }
  .sc_blogger_item_content {
    color: #919497;
  }
  .sc_blogger_item .more-link {
    color: $line3;
    &:hover {
      color: #fff;
    }
  }
  .sc_cars_columns_1 .sc_cars_item {
    background-color: #2c3c4a;
  }
  .sc_cars_item_status > a, .sc_cars_item_type > a {
    color: #919497;
  }
  .sc_cars_item_compare {
    color: #919497;
    &.in_compare_list {
      color: $line2;
    }
  }
  .sc_cars_item_status > a:hover, .sc_cars_item_type > a:hover {
    color: #fff;
  }
  .sc_cars_item_compare {
    &:hover, &.in_compare_list:hover {
      color: #fff;
    }
  }
  .sc_cars_item_options {
    .sc_cars_item_row_address, .sc_cars_item_row_meta {
      color: #919497;
    }
  }
  .cars_page_title .cars_page_status > a {
    color: #fff;
    background-color: $line2;
    &:hover {
      color: #1d1d1d;
      background-color: #fff04f;
    }
  }
  .cars_page_title_address, .cars_price {
    color: #919497;
  }
  .cars_page_attachments_list > a:before, .cars_page_features_list > a:before {
    color: $line2;
  }
  .cars_page_tabs.trx_addons_tabs .trx_addons_tabs_titles {
    border-color: #464646;
    li {
      > a {
        background-color: #2c3c4a;
        border-color: #2c3c4a;
        border-bottom-color: #464646;
      }
      &.ui-state-active > a {
        border-color: #464646;
        background-color: #0e0d12;
        border-bottom-color: #0e0d12;
      }
      &:not(.ui-state-active) > a:hover {
        background-color: #333;
        border-color: #333 #333 #464646;
      }
    }
  }
  .cars_page_agent_info_position {
    color: #919497;
  }
  .cars_page_agent_info_phones > {
    span, a {
      color: #787f86;
    }
  }
  .cars_page_agent_info_address:before, .cars_page_agent_info_phones > :before {
    color: #fff;
  }
  .cars_page_agent_info_profiles.socials_wrap .social_item .social_icon {
    color: #787f86;
  }
  .cars_search_form .cars_search_basic .cars_search_show_advanced {
    color: #969ea5;
    background-color: #2c3c4a;
    &:hover {
      color: #fff;
    }
  }
  .sc_cars_compare_data .cars_feature_present {
    color: $line2;
  }
  .sc_content_number {
    color: #333;
  }
  .sc_countdown_default .sc_countdown_digits span {
    color: #fff;
    border-color: $line3;
    background-color: $line2;
  }
  .sc_countdown_circle .sc_countdown_digits {
    color: $line3;
    border-color: #464646;
    background-color: #2c3c4a;
  }
  .sc_courses.slider_container .swiper-pagination-bullet {
    border-color: #919497;
  }
  .sc_courses_default {
    .sc_courses_item {
      background-color: #2c3c4a;
    }
    .sc_courses_item_categories {
      background-color: #fff;
      a {
        color: #0e0d12;
        &:hover {
          color: $line3;
        }
      }
    }
    .sc_courses_item_meta {
      color: #5f5f5f;
    }
    .sc_courses_item_date {
      color: #fff;
    }
    .sc_courses_item_price {
      color: $line3;
    }
    .sc_courses_item_period {
      color: #5f5f5f;
    }
  }
  .courses_single {
    .courses_page_meta {
      color: #919497;
    }
    .courses_page_meta_item_date {
      color: #fff;
    }
    .courses_page_period {
      color: #919497;
    }
  }
  .sc_dishes_default {
    .sc_dishes_item {
      color: #919497;
      background-color: #2c3c4a;
    }
    .sc_dishes_item_subtitle {
      color: $line3;
      a {
        color: $line3;
        &:hover {
          color: #fe7259;
        }
      }
    }
    &.color_style_link2 .sc_dishes_item_subtitle {
      color: #8be77c;
      a {
        color: #8be77c;
        &:hover {
          color: #80d572;
        }
      }
    }
    &.color_style_link3 .sc_dishes_item_subtitle {
      color: #eec432;
      a {
        color: #eec432;
        &:hover {
          color: #ddb837;
        }
      }
    }
    &.color_style_dark .sc_dishes_item_subtitle {
      color: #fff;
      a {
        color: #fff;
        &:hover {
          color: $line3;
        }
      }
    }
    .sc_dishes_item_featured_left, .sc_dishes_item_featured_right {
      color: #787f86;
      background-color: transparent;
    }
    .sc_dishes_item_featured_left .sc_dishes_item_subtitle, .sc_dishes_item_featured_right .sc_dishes_item_subtitle {
      color: $line2;
      a {
        color: $line2;
      }
    }
    .sc_dishes_item_featured_left .sc_dishes_item_subtitle a:hover, .sc_dishes_item_featured_right .sc_dishes_item_subtitle a:hover {
      color: $line3;
    }
    &.color_style_link2 {
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle, .sc_dishes_item_featured_right .sc_dishes_item_subtitle {
        color: $line1;
        a {
          color: $line1;
        }
      }
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle a:hover, .sc_dishes_item_featured_right .sc_dishes_item_subtitle a:hover {
        color: #8be77c;
      }
    }
    &.color_style_link3 {
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle, .sc_dishes_item_featured_right .sc_dishes_item_subtitle {
        color: $line3;
        a {
          color: $line3;
        }
      }
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle a:hover, .sc_dishes_item_featured_right .sc_dishes_item_subtitle a:hover {
        color: $line2;
      }
    }
    &.color_style_dark {
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle, .sc_dishes_item_featured_right .sc_dishes_item_subtitle {
        color: #fff;
        a {
          color: #fff;
        }
      }
      .sc_dishes_item_featured_left .sc_dishes_item_subtitle a:hover, .sc_dishes_item_featured_right .sc_dishes_item_subtitle a:hover {
        color: $line2;
      }
    }
  }
  .sc_dishes_compact {
    .sc_dishes_item {
      color: #919497;
      background-color: #2c3c4a;
    }
    .sc_dishes_item_header {
      color: #0e0d12;
      background-color: #fff;
    }
    .sc_dishes_item_price, .sc_dishes_item_subtitle a {
      color: #0e0d12;
    }
    .sc_dishes_item_price:hover, .sc_dishes_item:hover .sc_dishes_item_price, .sc_dishes_item_subtitle a:hover, .sc_dishes_item:hover .sc_dishes_item_subtitle a {
      color: $line2;
    }
    &.color_style_link2 {
      .sc_dishes_item_price:hover, .sc_dishes_item:hover .sc_dishes_item_price, .sc_dishes_item_subtitle a:hover, .sc_dishes_item:hover .sc_dishes_item_subtitle a {
        color: $line1;
      }
    }
    &.color_style_link3 {
      .sc_dishes_item_price:hover, .sc_dishes_item:hover .sc_dishes_item_price, .sc_dishes_item_subtitle a:hover, .sc_dishes_item:hover .sc_dishes_item_subtitle a {
        color: $line3;
      }
    }
    .sc_dishes_item_title a {
      color: $line2;
    }
    &.color_style_link2 .sc_dishes_item_title a {
      color: $line1;
    }
    &.color_style_link3 .sc_dishes_item_title a {
      color: $line3;
    }
    .sc_dishes_item_title a:hover, .sc_dishes_item:hover .sc_dishes_item_title a {
      color: #0e0d12;
    }
  }
  .sc_dishes.slider_container .swiper-pagination-bullet, .sc_events.slider_container .swiper-pagination-bullet {
    border-color: #919497;
  }
  .sc_events_default {
    .sc_events_item {
      background-color: #2c3c4a;
    }
    .sc_events_item_date {
      background-color: $line3;
      color: #fff;
    }
    .sc_events_item:hover .sc_events_item_date {
      background-color: #fff;
    }
    .sc_events_item_title {
      color: #fff;
    }
    .sc_events_item:hover .sc_events_item_title, .sc_events_item_button {
      color: $line3;
    }
    .sc_events_item:hover .sc_events_item_button {
      color: #fff;
    }
  }
  .sc_events_detailed {
    .sc_events_item, .sc_events_item_date_wrap, .sc_events_item_time_wrap:before, .sc_events_item_button_wrap:before {
      border-color: $line2;
    }
    .sc_events_item_date, .sc_events_item_button {
      color: $line2;
    }
    .sc_events_item_title {
      color: #fff;
    }
    .sc_events_item_time {
      color: #787f86;
    }
    .sc_events_item:hover {
      background-color: $line2;
      color: #fff;
      color: #1d1d1d;
      .sc_events_item_date, .sc_events_item_button, .sc_events_item_title, .sc_events_item_time {
        color: #1d1d1d;
      }
      border-color: #1d1d1d;
      .sc_events_item_date_wrap, .sc_events_item_time_wrap:before, .sc_events_item_button_wrap:before {
        border-color: #1d1d1d;
      }
    }
  }
  &.sc_form {
    background-color: #0e0d12;
  }
  span.sc_form_field_title {
    color: #fff;
  }
  .sc_form {
    .sc_form_info_icon {
      color: $line2;
    }
    .sc_form_info_data > {
      a, span {
        color: #fff;
      }
      a:hover {
        color: $line2;
      }
    }
  }
  [class*="sc_input_hover_"] .sc_form_field_content {
    color: #fff;
  }
  .sc_input_hover_accent {
    input {
      &[type="text"]:focus, &[type="number"]:focus, &[type="email"]:focus, &[type="password"]:focus, &[type="search"]:focus {
        border-color: $line2 !important;
      }
    }
    select:focus {
      border-color: $line2 !important;
    }
    .select2-container {
      &.select2-container--focus span.select2-selection, &.select2-container--open span.select2-selection {
        border-color: $line2 !important;
      }
    }
    textarea:focus {
      border-color: $line2 !important;
    }
    .sc_form_field_hover:before {
      color: rgba(253, 230, 66, 0.2);
    }
  }
  .sc_input_hover_path .sc_form_field_graphic {
    stroke: #2e2d32;
  }
  .sc_input_hover_jump {
    .sc_form_field_content {
      color: #fff;
      &:before {
        color: $line2;
      }
    }
    input {
      &[type="text"], &[type="number"], &[type="email"], &[type="password"], &[type="search"] {
        border-color: #2e2d32;
      }
    }
    textarea {
      border-color: #2e2d32;
    }
    input {
      &[type="text"]:focus, &[type="number"]:focus, &[type="email"]:focus, &[type="password"]:focus, &[type="search"]:focus {
        border-color: $line2 !important;
      }
    }
    textarea:focus {
      border-color: $line2 !important;
    }
  }
  .sc_input_hover_underline {
    .sc_form_field_hover:before {
      background-color: #2e2d32;
    }
    input:focus + .sc_form_field_hover:before, textarea:focus + .sc_form_field_hover:before, input.filled + .sc_form_field_hover:before, textarea.filled + .sc_form_field_hover:before {
      background-color: $line2;
    }
    .sc_form_field_content {
      color: #fff;
    }
    input:focus, textarea:focus, input.filled, textarea.filled, input:focus + .sc_form_field_hover > .sc_form_field_content, textarea:focus + .sc_form_field_hover > .sc_form_field_content, input.filled + .sc_form_field_hover > .sc_form_field_content, textarea.filled + .sc_form_field_hover > .sc_form_field_content {
      color: $line2 !important;
    }
  }
  .sc_input_hover_iconed {
    .sc_form_field_hover {
      color: #969ea5;
    }
    input:focus + .sc_form_field_hover, textarea:focus + .sc_form_field_hover, input.filled + .sc_form_field_hover, textarea.filled + .sc_form_field_hover {
      color: #fff;
    }
  }
  .sc_googlemap_content, &.sc_googlemap_content {
    color: #787f86;
    background-color: #0e0d12;
  }
  .sc_googlemap_content {
    b, strong {
      color: #fff;
    }
  }
  &.sc_googlemap_content {
    b, strong {
      color: #fff;
    }
  }
  .sc_googlemap_content_detailed:before {
    color: $line2;
  }
  .sc_icons {
    .sc_icons_icon {
      color: $line2;
    }
    .sc_icons_item_linked:hover .sc_icons_icon {
      color: #fff;
    }
    .sc_icons_item_title {
      color: $line2;
    }
  }
  &.footer_wrap .sc_icons {
    .sc_icons_item_title {
      color: #fff;
    }
    .sc_icons_item_description {
      color: #787f86;
    }
  }
  .sc_icons_item_description, .sc_icons_modern .sc_icons_item_description {
    color: #fff;
  }
  .sc_sport_default .sc_sport_item_subtitle .sc_sport_item_date {
    color: #919497;
  }
  .sc_matches_main {
    .swiper-pagination .swiper-pagination-bullet {
      border-color: #2e2c33;
    }
    .sc_matches_item_score a {
      color: #fff;
      &:hover {
        color: $line2;
      }
    }
  }
  .color_style_link2 .sc_matches_main .sc_matches_item_score a:hover {
    color: $line1;
  }
  .color_style_link3 .sc_matches_main .sc_matches_item_score a:hover {
    color: $line3;
  }
  .color_style_dark .sc_matches_main .sc_matches_item_score a:hover {
    color: #fff;
  }
  .sc_matches_other {
    .sc_matches_item_link {
      color: #fff;
      background-color: #2c3c4a;
    }
    .sc_matches_item_club {
      color: #5f5f5f;
    }
    .sc_matches_item_date {
      color: #fff;
      background-color: #464646;
    }
    .sc_matches_item_link:hover {
      background-color: #333;
      .sc_matches_item_date {
        background-color: #4a4a4a;
      }
    }
  }
  .sc_points_table {
    td a {
      color: #fff;
    }
    tr:hover {
      td {
        background-color: #fe7259 !important;
      }
      a {
        color: #1d1d1d !important;
      }
    }
    td a:hover {
      color: #1d1d1d !important;
    }
    tr {
      &.sc_points_table_accented_top td {
        background-color: rgba(253, 230, 66, 0.7);
      }
      &.sc_points_table_accented_bottom td {
        background-color: #2c3c4a;
      }
    }
  }
  .sc_price_item {
    color: #a6a6a6;
    background-color: #1e1d22;
    border-color: #464646;
    &:hover {
      background-color: #28272e;
      border-color: #4a4a4a;
    }
    .sc_price_item_icon {
      color: #ffaa5f;
    }
    &:hover .sc_price_item_icon {
      color: #fe7259;
    }
    .sc_price_item_label {
      background-color: #ffaa5f;
      color: #1d1d1d;
    }
    &:hover .sc_price_item_label {
      background-color: #fe7259;
      color: #1d1d1d;
    }
    .sc_price_item_subtitle {
      color: #fff;
    }
    .sc_price_item_title {
      color: #ffaa5f;
      a {
        color: #ffaa5f;
      }
    }
    &:hover .sc_price_item_title {
      color: #fe7259;
      a {
        color: #fe7259;
      }
    }
    .sc_price_item_price, .sc_price_item_description, .sc_price_item_details {
      color: #fff;
    }
  }
  .sc_promo_icon {
    color: $line2;
  }
  .sc_promo {
    .sc_promo_title, .sc_promo_descr {
      color: #fff;
    }
    .sc_promo_content {
      color: #787f86;
    }
  }
  .sc_promo_modern .sc_promo_link2 {
    color: #fff;
    background-color: $line2 !important;
    &:hover {
      color: #0e0d12;
      background-color: #fff;
    }
  }
  &.sc_promo {
    .sc_promo_text.trx_addons_stretch_height, .sc_promo_text_inner {
      background-color: #2c3c4a;
    }
    .sc_promo_title {
      color: $line3;
    }
    .sc_promo_subtitle {
      color: #fe7259;
    }
    .sc_promo_descr {
      color: #fff;
    }
    .sc_promo_content {
      color: #919497;
    }
  }
  .sc_properties_columns_1 .sc_properties_item {
    background-color: #2c3c4a;
  }
  .sc_properties_item_status > a, .sc_properties_item_type > a {
    color: #919497;
  }
  .sc_properties_item_compare {
    color: #919497;
    &.in_compare_list {
      color: $line2;
    }
  }
  .sc_properties_item_status > a:hover, .sc_properties_item_type > a:hover {
    color: #fff;
  }
  .sc_properties_item_compare {
    &:hover, &.in_compare_list:hover {
      color: #fff;
    }
  }
  .sc_properties_item_options {
    .sc_properties_item_row_address, .sc_properties_item_row_meta {
      color: #919497;
    }
  }
  .properties_page_title .properties_page_status > a {
    color: #fff;
    background-color: $line2;
    &:hover {
      color: #1d1d1d;
      background-color: #fff04f;
    }
  }
  .properties_page_title_address, .properties_price {
    color: #919497;
  }
  .properties_page_attachments_list > a:before, .properties_page_features_list > a:before {
    color: $line2;
  }
  .properties_page_tabs.trx_addons_tabs .trx_addons_tabs_titles {
    border-color: #464646;
    li {
      > a {
        background-color: #2c3c4a;
        border-color: #2c3c4a;
        border-bottom-color: #464646;
      }
      &.ui-state-active > a {
        border-color: #464646;
        background-color: #0e0d12;
        border-bottom-color: #0e0d12;
      }
      &:not(.ui-state-active) > a:hover {
        background-color: #333;
        border-color: #333 #333 #464646;
      }
    }
  }
  .properties_page_agent_info_position {
    color: #919497;
  }
  .properties_page_agent_info_phones > {
    span, a {
      color: #787f86;
    }
  }
  .properties_page_agent_info_address:before, .properties_page_agent_info_phones > :before {
    color: #fff;
  }
  .properties_page_agent_info_profiles.socials_wrap .social_item .social_icon {
    color: #787f86;
  }
  .properties_search_form .properties_search_basic .properties_search_show_advanced {
    color: #969ea5;
    background-color: #2c3c4a;
    &:hover {
      color: #fff;
    }
  }
  .sc_properties_compare_data .properties_feature_present {
    color: $line2;
  }
  .sc_services .sc_services_item_number {
    color: #333;
  }
  .sc_services_default {
    .sc_services_item {
      color: #919497;
      background-color: #2c3c4a;
    }
    .sc_services_item_icon {
      color: $line3;
      border-color: $line3;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: #000;
      background-color: $line3;
      border-color: $line3;
    }
    &.color_style_link2 {
      .sc_services_item_icon {
        color: #8be77c;
        border-color: #8be77c;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: #000;
        background-color: #8be77c;
        border-color: #8be77c;
      }
    }
    &.color_style_link3 {
      .sc_services_item_icon {
        color: #eec432;
        border-color: #eec432;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: #000;
        background-color: #eec432;
        border-color: #eec432;
      }
    }
    &.color_style_dark {
      .sc_services_item_icon {
        color: #fff;
        border-color: #fff;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: #000;
        background-color: #fff;
        border-color: #fff;
      }
    }
    .sc_services_item_subtitle a {
      color: $line3;
      &:hover {
        color: #fe7259;
      }
    }
    &.color_style_link2 .sc_services_item_subtitle a {
      color: #8be77c;
      &:hover {
        color: #80d572;
      }
    }
    &.color_style_link3 .sc_services_item_subtitle a {
      color: #eec432;
      &:hover {
        color: #ddb837;
      }
    }
    &.color_style_dark .sc_services_item_subtitle a {
      color: #fff;
      &:hover {
        color: $line3;
      }
    }
    .sc_services_item_featured_left, .sc_services_item_featured_right {
      color: #787f86;
      background-color: transparent;
    }
  }
  .sc_services_list .sc_services_item {
    color: #787f86;
    background-color: transparent;
  }
  .sc_services_default {
    .sc_services_item_featured_left .sc_services_item_icon, .sc_services_item_featured_right .sc_services_item_icon {
      color: $line2;
      border-color: $line2;
    }
  }
  .sc_services_list {
    .sc_services_item_icon {
      color: $line2;
      border-color: $line2;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: $line3;
    }
  }
  .sc_services_default {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #000;
      background-color: $line2;
      border-color: $line2;
    }
  }
  .sc_services_list {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #000;
      background-color: $line2;
      border-color: $line2;
    }
  }
  .sc_services_default {
    .sc_services_item_featured_left .sc_services_item_subtitle a, .sc_services_item_featured_right .sc_services_item_subtitle a {
      color: $line2;
    }
    .sc_services_item_featured_left .sc_services_item_subtitle a:hover, .sc_services_item_featured_right .sc_services_item_subtitle a:hover {
      color: $line3;
    }
    &.color_style_link2 {
      .sc_services_item_featured_left .sc_services_item_icon, .sc_services_item_featured_right .sc_services_item_icon {
        color: $line1;
        border-color: $line1;
      }
    }
  }
  .sc_services_list.color_style_link2 {
    .sc_services_item_icon {
      color: $line1;
      border-color: $line1;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: #8be77c;
    }
  }
  .sc_services_default.color_style_link2 {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #000;
      background-color: $line1;
      border-color: $line1;
    }
  }
  .sc_services_list.color_style_link2 {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #000;
      background-color: $line1;
      border-color: $line1;
    }
  }
  .sc_services_default {
    &.color_style_link2 {
      .sc_services_item_featured_left .sc_services_item_subtitle a, .sc_services_item_featured_right .sc_services_item_subtitle a {
        color: $line1;
      }
      .sc_services_item_featured_left .sc_services_item_subtitle a:hover, .sc_services_item_featured_right .sc_services_item_subtitle a:hover {
        color: #8be77c;
      }
    }
    &.color_style_link3 {
      .sc_services_item_featured_left .sc_services_item_icon, .sc_services_item_featured_right .sc_services_item_icon {
        color: $line3;
        border-color: $line3;
      }
    }
  }
  .sc_services_list.color_style_link3 {
    .sc_services_item_icon {
      color: $line3;
      border-color: $line3;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: $line2;
    }
  }
  .sc_services_default.color_style_link3 {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #000;
      background-color: $line3;
      border-color: $line3;
    }
  }
  .sc_services_list.color_style_link3 {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #000;
      background-color: $line3;
      border-color: $line3;
    }
  }
  .sc_services_default {
    &.color_style_link3 {
      .sc_services_item_featured_left .sc_services_item_subtitle a, .sc_services_item_featured_right .sc_services_item_subtitle a {
        color: $line3;
      }
      .sc_services_item_featured_left .sc_services_item_subtitle a:hover, .sc_services_item_featured_right .sc_services_item_subtitle a:hover {
        color: $line2;
      }
    }
    &.color_style_dark {
      .sc_services_item_featured_left .sc_services_item_icon, .sc_services_item_featured_right .sc_services_item_icon {
        color: #fff;
        border-color: #fff;
      }
    }
  }
  .sc_services_list.color_style_dark {
    .sc_services_item_icon {
      color: #fff;
      border-color: #fff;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: $line2;
    }
  }
  .sc_services_default.color_style_dark {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #000;
      background-color: #fff;
      border-color: #fff;
    }
  }
  .sc_services_list.color_style_dark {
    .sc_services_item_featured_left:hover .sc_services_item_icon, .sc_services_item_featured_right:hover .sc_services_item_icon {
      color: #000;
      background-color: #fff;
      border-color: #fff;
    }
  }
  .sc_services_default.color_style_dark {
    .sc_services_item_featured_left .sc_services_item_subtitle a, .sc_services_item_featured_right .sc_services_item_subtitle a {
      color: #fff;
    }
    .sc_services_item_featured_left .sc_services_item_subtitle a:hover, .sc_services_item_featured_right .sc_services_item_subtitle a:hover {
      color: $line2;
    }
  }
  .sc_services_light {
    .sc_services_item_icon {
      color: $line2;
    }
    .sc_services_item:hover .sc_services_item_icon {
      color: $line3;
    }
    &.color_style_link2 {
      .sc_services_item_icon {
        color: $line1;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: #8be77c;
      }
    }
    &.color_style_link3 {
      .sc_services_item_icon {
        color: $line3;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: $line2;
      }
    }
    &.color_style_dark {
      .sc_services_item_icon {
        color: #fff;
      }
      .sc_services_item:hover .sc_services_item_icon {
        color: $line2;
      }
    }
  }
  .sc_services_callouts {
    .sc_services_item {
      background-color: #2c3c4a;
    }
    .sc_services_item_marker {
      border-color: #0e0d12;
      background-color: $line3;
      color: #fff;
    }
    .sc_services_item .sc_services_item_marker_back {
      border-color: #0e0d12;
      background-color: #fe7259;
      color: #1d1d1d;
    }
    &.color_style_link2 {
      .sc_services_item_marker {
        background-color: #8be77c;
      }
      .sc_services_item .sc_services_item_marker_back {
        background-color: #80d572;
      }
    }
    &.color_style_link3 {
      .sc_services_item_marker {
        background-color: #eec432;
      }
      .sc_services_item .sc_services_item_marker_back {
        background-color: #ddb837;
      }
    }
    &.color_style_dark {
      .sc_services_item_marker {
        background-color: #fff;
      }
      .sc_services_item .sc_services_item_marker_back {
        background-color: $line3;
      }
    }
    .sc_services_item_marker_bg {
      border-color: #0e0d12;
      background-color: #0e0d12;
    }
  }
  .sc_services_timeline {
    .sc_services_item_timeline {
      border-color: #2e2c33;
    }
    .sc_services_item_marker {
      border-color: $line2;
      background-color: $line2;
      color: #fff;
    }
    .sc_services_item:hover .sc_services_item_marker {
      border-color: $line3;
      background-color: $line3;
      color: #1d1d1d;
    }
    &.color_style_link2 {
      .sc_services_item_marker {
        border-color: $line1;
        background-color: $line1;
      }
      .sc_services_item:hover .sc_services_item_marker {
        border-color: #8be77c;
        background-color: #8be77c;
      }
    }
    &.color_style_link3 {
      .sc_services_item_marker {
        border-color: $line3;
        background-color: $line3;
      }
      .sc_services_item:hover .sc_services_item_marker {
        border-color: $line2;
        background-color: $line2;
      }
    }
    &.color_style_dark {
      .sc_services_item_marker {
        border-color: #fff;
        background-color: #fff;
      }
      .sc_services_item:hover .sc_services_item_marker {
        border-color: $line2;
        background-color: $line2;
      }
    }
  }
  .sc_services_iconed {
    .sc_services_item {
      color: #919497;
      background-color: #2c3c4a;
    }
    .sc_services_item_icon:hover, .sc_services_item:hover .sc_services_item_icon, .sc_services_item_header .sc_services_item_subtitle a:hover, .sc_services_item:hover .sc_services_item_header .sc_services_item_subtitle a {
      color: $line2;
    }
    &.color_style_link2 {
      .sc_services_item_icon:hover, .sc_services_item:hover .sc_services_item_icon, .sc_services_item_header .sc_services_item_subtitle a:hover, .sc_services_item:hover .sc_services_item_header .sc_services_item_subtitle a {
        color: $line1;
      }
    }
    &.color_style_link3 {
      .sc_services_item_icon:hover, .sc_services_item:hover .sc_services_item_icon, .sc_services_item_header .sc_services_item_subtitle a:hover, .sc_services_item:hover .sc_services_item_header .sc_services_item_subtitle a {
        color: $line3;
      }
    }
    .sc_services_item_header .sc_services_item_title a {
      color: $line2;
    }
    &.color_style_link2 .sc_services_item_header .sc_services_item_title a {
      color: $line1;
    }
    &.color_style_link3 .sc_services_item_header .sc_services_item_title a {
      color: $line3;
    }
    .sc_services_item_header .sc_services_item_title a:hover {
      color: #fff;
    }
    .sc_services_item {
      &:hover .sc_services_item_header .sc_services_item_title a, .sc_services_item_header .sc_services_item_subtitle a {
        color: #fff;
      }
      &:hover .sc_services_item_header .sc_services_item_subtitle a, .sc_services_item_header .sc_services_item_subtitle a:hover {
        color: $line2;
      }
    }
    &.color_style_link2 .sc_services_item {
      &:hover .sc_services_item_header .sc_services_item_subtitle a, .sc_services_item_header .sc_services_item_subtitle a:hover {
        color: $line1;
      }
    }
    &.color_style_link3 .sc_services_item {
      &:hover .sc_services_item_header .sc_services_item_subtitle a, .sc_services_item_header .sc_services_item_subtitle a:hover {
        color: $line3;
      }
    }
    .sc_services_item_content .sc_services_item_title a {
      color: #fff;
      &:hover {
        color: $line3;
      }
    }
    .sc_services_item:hover .sc_services_item_content .sc_services_item_title a {
      color: $line3;
    }
    &.color_style_link2 {
      .sc_services_item_content .sc_services_item_title a:hover, .sc_services_item:hover .sc_services_item_content .sc_services_item_title a {
        color: #8be77c;
      }
    }
    &.color_style_link3 {
      .sc_services_item_content .sc_services_item_title a:hover, .sc_services_item:hover .sc_services_item_content .sc_services_item_title a {
        color: #eec432;
      }
    }
    &.color_style_dark {
      .sc_services_item_content .sc_services_item_title a:hover, .sc_services_item:hover .sc_services_item_content .sc_services_item_title a {
        color: #fff;
      }
    }
  }
  .sc_services.slider_container .swiper-pagination-bullet {
    border-color: #919497;
  }
  .sc_services_list {
    .sc_services_item_featured_left .sc_services_item_number, .sc_services_item_featured_right .sc_services_item_number {
      color: #919497;
    }
  }
  .sc_services_hover {
    .sc_services_item_icon, .sc_services_item_title a:hover, .sc_services_item_subtitle a:hover {
      color: $line2;
    }
    &.color_style_link2 {
      .sc_services_item_icon, .sc_services_item_title a:hover, .sc_services_item_subtitle a:hover {
        color: $line1;
      }
    }
    &.color_style_link3 {
      .sc_services_item_icon, .sc_services_item_title a:hover, .sc_services_item_subtitle a:hover {
        color: $line3;
      }
    }
    [class*="column-"]:nth-child(2n) .sc_services_item.with_image .sc_services_item_header.without_image, .slider-slide:nth-child(2n) .sc_services_item.with_image .sc_services_item_header.without_image {
      background-color: #333;
    }
    [class*="column-"]:nth-child(2n+1) .sc_services_item.with_image .sc_services_item_header.without_image, .slider-slide:nth-child(2n+1) .sc_services_item.with_image .sc_services_item_header.without_image {
      background-color: #2c3c4a;
    }
    .sc_services_item.with_image {
      .sc_services_item_header.without_image {
        .sc_services_item_icon, .sc_services_item_number {
          color: #5f5f5f;
        }
        .sc_services_item_title a {
          color: #fff;
        }
      }
      &:hover .sc_services_item_header.without_image .sc_services_item_title a, .sc_services_item_header.without_image .sc_services_item_title a:hover {
        color: $line3;
      }
    }
    &.color_style_link2 .sc_services_item.with_image {
      &:hover .sc_services_item_header.without_image .sc_services_item_title a, .sc_services_item_header.without_image .sc_services_item_title a:hover {
        color: #8be77c;
      }
    }
    &.color_style_link3 .sc_services_item.with_image {
      &:hover .sc_services_item_header.without_image .sc_services_item_title a, .sc_services_item_header.without_image .sc_services_item_title a:hover {
        color: #eec432;
      }
    }
    .sc_services_item.with_image .sc_services_item_header.without_image .sc_services_item_subtitle a {
      color: $line3;
    }
    &.color_style_link2 .sc_services_item.with_image .sc_services_item_header.without_image .sc_services_item_subtitle a {
      color: #8be77c;
    }
    &.color_style_link3 .sc_services_item.with_image .sc_services_item_header.without_image .sc_services_item_subtitle a {
      color: #eec432;
    }
    .sc_services_item.with_image {
      &:hover .sc_services_item_header.without_image .sc_services_item_subtitle a, .sc_services_item_header.without_image .sc_services_item_subtitle a:hover {
        color: #fe7259;
      }
    }
    &.color_style_link2 .sc_services_item.with_image {
      &:hover .sc_services_item_header.without_image .sc_services_item_subtitle a, .sc_services_item_header.without_image .sc_services_item_subtitle a:hover {
        color: #80d572;
      }
    }
    &.color_style_link3 .sc_services_item.with_image {
      &:hover .sc_services_item_header.without_image .sc_services_item_subtitle a, .sc_services_item_header.without_image .sc_services_item_subtitle a:hover {
        color: #ddb837;
      }
    }
    .sc_services_item.with_image .sc_services_item_header.without_image .sc_services_item_text {
      color: #919497;
    }
  }
  .sc_services_chess {
    .sc_services_item {
      color: #919497;
      background-color: #2c3c4a;
    }
    .sc_services_item_title a {
      color: #fff;
      &:hover {
        color: $line3;
      }
    }
    &.color_style_link2 .sc_services_item_title a:hover {
      color: #8be77c;
    }
    &.color_style_link3 .sc_services_item_title a:hover {
      color: #eec432;
    }
    .sc_services_item:hover {
      color: #919497;
      background-color: #fff;
      .sc_services_item_title a {
        color: #0e0d12;
        &:hover {
          color: $line2;
        }
      }
    }
  }
  .sc_services_tabs_simple {
    .sc_services_item_icon {
      color: $line2;
    }
    &.color_style_link2 .sc_services_item_icon {
      color: $line1;
    }
    &.color_style_link3 .sc_services_item_icon {
      color: $line3;
    }
    &.color_style_dark .sc_services_item_icon {
      color: #fff;
    }
    .sc_services_item:hover {
      .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
        color: $line3;
      }
    }
    .sc_services_tabs_list_item_active {
      .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
        color: $line3;
      }
    }
    &.color_style_link2 {
      .sc_services_item:hover {
        .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
          color: #8be77c;
        }
      }
      .sc_services_tabs_list_item_active {
        .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
          color: #8be77c;
        }
      }
    }
    &.color_style_link3 {
      .sc_services_item:hover {
        .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
          color: $line2;
        }
      }
      .sc_services_tabs_list_item_active {
        .sc_services_item_icon, .sc_services_item_title, .sc_services_item_subtitle {
          color: $line2;
        }
      }
    }
  }
  .sc_services_tabs {
    .sc_services_item_content {
      color: #919497;
      background-color: #2c3c4a;
    }
    .sc_services_item_title a {
      color: #fff;
      &:hover {
        color: $line3;
      }
    }
    &.color_style_link2 .sc_services_item_title a:hover {
      color: #8be77c;
    }
    &.color_style_link3 .sc_services_item_title a:hover {
      color: #eec432;
    }
    .sc_services_tabs_list_item .sc_services_item_icon {
      color: $line3;
    }
    &.color_style_link2 .sc_services_tabs_list_item .sc_services_item_icon {
      color: #8be77c;
    }
    &.color_style_link3 .sc_services_tabs_list_item .sc_services_item_icon {
      color: #eec432;
    }
    .sc_services_tabs_list_item {
      .sc_services_item_number {
        color: #5f5f5f;
      }
      background-color: #2c3c4a;
      &:nth-child(2n+2) {
        background-color: #333;
      }
      &:hover, &:nth-child(2n+2):hover {
        background-color: #4a4a4a;
      }
      .sc_services_item_title {
        color: #fff;
      }
      &:hover .sc_services_item_title {
        color: $line3;
      }
    }
    &.color_style_link2 .sc_services_tabs_list_item:hover .sc_services_item_title {
      color: #8be77c;
    }
    &.color_style_link3 .sc_services_tabs_list_item:hover .sc_services_item_title {
      color: #eec432;
    }
    &.color_style_dark .sc_services_tabs_list_item:hover .sc_services_item_title {
      color: #fff;
    }
    .sc_services_tabs_list_item:hover .sc_services_item_icon {
      color: #fe7259;
    }
    &.color_style_link2 .sc_services_tabs_list_item:hover .sc_services_item_icon {
      color: #80d572;
    }
    &.color_style_link3 .sc_services_tabs_list_item:hover .sc_services_item_icon {
      color: #ddb837;
    }
    &.color_style_dark .sc_services_tabs_list_item:hover .sc_services_item_icon {
      color: #fff;
    }
    .sc_services_tabs_list_item {
      &:hover .sc_services_item_number {
        color: #919497;
      }
      &.sc_services_tabs_list_item_active {
        background-color: #fff !important;
        .sc_services_item_title {
          color: #0e0d12;
        }
        .sc_services_item_icon, .sc_services_item_number {
          color: $line3;
        }
      }
    }
    &.color_style_link2 .sc_services_tabs_list_item.sc_services_tabs_list_item_active {
      .sc_services_item_icon, .sc_services_item_number {
        color: #8be77c;
      }
    }
    &.color_style_link3 .sc_services_tabs_list_item.sc_services_tabs_list_item_active {
      .sc_services_item_icon, .sc_services_item_number {
        color: #eec432;
      }
    }
    &.color_style_dark .sc_services_tabs_list_item.sc_services_tabs_list_item_active {
      .sc_services_item_icon, .sc_services_item_number {
        color: #fff;
      }
    }
  }
  .sc_skills_counter .sc_skills_icon {
    color: #fff;
  }
  .sc_skills {
    .sc_skills_total {
      color: $line2;
    }
    &.color_style_link2 .sc_skills_total {
      color: $line1;
    }
    &.color_style_link3 .sc_skills_total {
      color: $line3;
    }
    &.color_style_dark .sc_skills_total, .sc_skills_item_title, .sc_skills_legend_title, .sc_skills_legend_value {
      color: #fff;
    }
  }
  .sc_skills_counter .sc_skills_column + .sc_skills_column:before {
    background-color: #2e2c33;
  }
  .socials_wrap .social_item {
    .social_icon {
      background-color: #2c3c4a;
      color: #fff;
      i {
        color: #fff;
      }
    }
    &:hover .social_icon {
      background-color: #333;
      color: $line3;
      i {
        color: $line3;
      }
    }
  }
  .sidebar_inner .socials_wrap .social_item {
    .social_icon {
      background-color: #333;
    }
    &:hover .social_icon {
      color: #fff;
      i {
        color: #fff;
      }
      background-color: #fe7259;
    }
  }
  .footer_wrap .socials_wrap .social_item .social_icon, &.footer_wrap .socials_wrap .social_item .social_icon {
    color: #fff;
    background-color: $line3;
  }
  .footer_wrap .socials_wrap .social_item:hover .social_icon, &.footer_wrap .socials_wrap .social_item:hover .social_icon {
    color: #1d1d1d;
    background-color: #fe7259;
  }
  .footer_wrap .sc_layouts_row_type_compact .socials_wrap .social_item .social_icon, &.footer_wrap .sc_layouts_row_type_compact .socials_wrap .social_item .social_icon {
    color: #fff;
    background-color: transparent;
  }
  .footer_wrap .sc_layouts_row_type_compact .socials_wrap .social_item:hover .social_icon, &.footer_wrap .sc_layouts_row_type_compact .socials_wrap .social_item:hover .social_icon {
    color: $line2;
    background-color: transparent;
  }
  .socials_share.socials_type_drop .social_item {
    > .social_icon > i {
      color: #919497;
    }
    &:hover > .social_icon > i {
      color: #fff;
    }
  }
  .sc_testimonials_item_content {
    color: #fff;
    &:before {
      color: $line2;
    }
  }
  .sc_testimonials_item_author_title {
    color: $line2;
  }
  .color_style_link2 {
    .sc_testimonials_item_content:before, .sc_testimonials_item_author_title {
      color: $line1;
    }
  }
  .color_style_link3 {
    .sc_testimonials_item_content:before, .sc_testimonials_item_author_title {
      color: $line3;
    }
  }
  .color_style_dark {
    .sc_testimonials_item_content:before, .sc_testimonials_item_author_title {
      color: #fff;
    }
  }
  .sc_testimonials_item_author_subtitle {
    color: #919497;
  }
  .sc_testimonials_simple {
    .sc_testimonials_item_author_data:before {
      background-color: #919497;
    }
    [class*="column"] .sc_testimonials_item_author_data {
      border-color: #919497;
    }
  }
  .sc_team_default .sc_team_item {
    color: #919497;
    background-color: #2c3c4a;
  }
  .sc_team {
    .sc_team_item_thumb .sc_team_item_title a:hover {
      color: $line3;
    }
    &.color_style_link2 .sc_team_item_thumb .sc_team_item_title a:hover {
      color: #8be77c;
    }
    &.color_style_link3 .sc_team_item_thumb .sc_team_item_title a:hover {
      color: #eec432;
    }
  }
  .sc_team_default {
    .sc_team_item_subtitle {
      color: $line3;
    }
    &.color_style_link2 .sc_team_item_subtitle {
      color: #8be77c;
    }
    &.color_style_link3 .sc_team_item_subtitle {
      color: #eec432;
    }
    &.color_style_dark .sc_team_item_subtitle {
      color: #fff;
    }
    .sc_team_item_socials .social_item .social_icon {
      color: #fff;
      background-color: $line3;
    }
  }
  .team_member_page .team_member_socials .social_item .social_icon {
    color: #fff;
    background-color: $line3;
  }
  .sc_team_default {
    &.color_style_link2 .sc_team_item_socials .social_item .social_icon {
      background-color: #8be77c;
    }
    &.color_style_link3 .sc_team_item_socials .social_item .social_icon {
      background-color: #eec432;
    }
    &.color_style_dark .sc_team_item_socials .social_item .social_icon {
      background-color: #fff;
    }
    .sc_team_item_socials .social_item:hover .social_icon {
      color: #2c3c4a;
      background-color: #fff;
    }
  }
  .team_member_page .team_member_socials .social_item:hover .social_icon {
    color: #2c3c4a;
    background-color: #fff;
  }
  .sc_team_default {
    &.color_style_link2 .sc_team_item_socials .social_item:hover .social_icon {
      background-color: #80d572;
    }
    &.color_style_link3 .sc_team_item_socials .social_item:hover .social_icon {
      background-color: #ddb837;
    }
    &.color_style_dark .sc_team_item_socials .social_item:hover .social_icon {
      background-color: $line3;
    }
  }
  .sc_team .sc_team_item_thumb .sc_team_item_socials .social_item {
    .social_icon {
      color: #fff;
      background-color: rgba(14, 13, 18, 0);
    }
    &:hover .social_icon {
      color: $line3;
      background-color: rgba(14, 13, 18, 0);
    }
  }
  .team_member_page .team_member_featured .team_member_avatar {
    border-color: #2e2c33;
  }
  .sc_team_short {
    .sc_team_item_thumb {
      border-color: $line2;
    }
    &.color_style_link2 .sc_team_item_thumb {
      border-color: $line1;
    }
    &.color_style_link3 .sc_team_item_thumb {
      border-color: $line3;
    }
    &.color_style_dark .sc_team_item_thumb {
      border-color: #fff;
    }
  }
  .sc_team.slider_container .swiper-pagination-bullet {
    border-color: #919497;
  }
  .sport_page_list {
    border-color: #2e2c33;
    li {
      + li {
        border-color: #2e2c33;
      }
      &:nth-child(2n+1) {
        background-color: #2c3c4a;
        color: #919497;
      }
    }
  }
  .trx_addons_scroll_to_top, .trx_addons_cv .trx_addons_scroll_to_top {
    color: #fff;
    border-color: $line2;
    background-color: $line2;
  }
  .trx_addons_scroll_to_top:hover, .trx_addons_cv .trx_addons_scroll_to_top:hover {
    color: #1d1d1d;
    border-color: #fff04f;
    background-color: #fff04f;
  }
  .trx_addons_popup {
    background-color: #2c3c4a;
    border-color: #464646;
    color: #919497;
    button.mfp-close {
      background-color: #333;
      border-color: #4a4a4a;
      color: #919497;
      &:hover {
        background-color: #fff;
        color: #2c3c4a;
      }
    }
    .trx_addons_tabs_titles li.trx_addons_tabs_title {
      background-color: #333;
      border-color: #4a4a4a;
      &.ui-tabs-active {
        background-color: #2c3c4a;
        border-bottom-color: transparent;
      }
      a {
        color: #919497;
        > i {
          color: #919497;
        }
      }
    }
    li.trx_addons_tabs_title a:hover {
      color: $line3;
    }
    .trx_addons_tabs_titles li.trx_addons_tabs_title {
      a:hover > i {
        color: $line3;
      }
      &[data-disabled="true"] a {
        color: #5f5f5f;
        > i {
          color: #5f5f5f;
        }
        &:hover {
          color: #5f5f5f;
          > i {
            color: #5f5f5f;
          }
        }
      }
      &.ui-tabs-active a {
        color: #fff;
        > i {
          color: #fff;
        }
        &:hover {
          color: #fff;
          > i {
            color: #fff;
          }
        }
      }
    }
  }
  .trx_addons_profiler {
    background-color: #2c3c4a;
    border-color: #4a4a4a;
  }
  .trx_addons_profiler_title {
    color: #fff;
  }
  .trx_addons_profiler table {
    td, th {
      border-color: #464646;
    }
    td {
      color: #919497;
    }
    th {
      background-color: #333;
      color: #fff;
    }
  }
  .trx_addons_cv, .trx_addons_cv_body_wrap {
    color: #919497;
    background-color: #2c3c4a;
  }
  .trx_addons_cv a {
    color: $line3;
    &:hover {
      color: #fe7259;
    }
  }
  .trx_addons_cv_header {
    background-color: #0e0d12;
  }
  .trx_addons_cv_header_image img {
    border-color: #fff;
  }
  .trx_addons_cv_header {
    .trx_addons_cv_header_letter, .trx_addons_cv_header_text {
      color: #fff;
    }
    .trx_addons_cv_header_socials .social_item {
      > .social_icon {
        color: rgba(255, 255, 255, 0.7);
      }
      &:hover > .social_icon {
        color: #fff;
      }
    }
  }
  .trx_addons_cv_header_letter, .trx_addons_cv_header_text, .trx_addons_cv_header_socials .social_item > .social_icon {
    text-shadow: 1px 1px 6px #0e0d12;
  }
  .trx_addons_cv_tint_dark {
    .trx_addons_cv_header_letter, .trx_addons_cv_header_text {
      color: #0e0d12;
      text-shadow: 1px 1px 3px #fff;
    }
    .trx_addons_cv_header_socials .social_item {
      > .social_icon {
        color: #0e0d12;
        text-shadow: 1px 1px 3px #fff;
      }
      &:hover > .social_icon {
        color: $line3;
      }
    }
  }
  .trx_addons_cv_navi_buttons .trx_addons_cv_navi_buttons_area {
    .trx_addons_cv_navi_buttons_item {
      color: #5f5f5f;
      background-color: #2c3c4a;
      border-color: #0e0d12;
    }
    .trx_addons_cv_navi_buttons_item_active, .trx_addons_cv_navi_buttons_item:hover {
      color: #fff;
      border-color: #2c3c4a;
    }
  }
  .trx_addons_cv .trx_addons_cv_section_title {
    color: #fff;
    a {
      color: #fff;
    }
  }
  .trx_addons_cv_section_title.ui-state-active {
    border-color: #fff;
  }
  .trx_addons_cv_section_content .trx_addons_tabs .trx_addons_tabs_titles li {
    > a {
      color: #5f5f5f;
    }
    &.ui-state-active > a, > a:hover {
      color: #fff;
    }
  }
  .trx_addons_cv_section .trx_addons_pagination > {
    * {
      color: #919497;
    }
    a:hover {
      color: #fff;
    }
  }
  .trx_addons_pagination > span.active {
    color: #fff;
    border-color: #fff;
  }
  .trx_addons_cv_breadcrumbs {
    .trx_addons_cv_breadcrumbs_item {
      color: #5f5f5f;
    }
    a.trx_addons_cv_breadcrumbs_item:hover {
      color: #fff;
    }
  }
  .trx_addons_cv_single {
    .trx_addons_cv_single_title {
      color: #fff;
    }
    .trx_addons_cv_single_subtitle {
      color: #5f5f5f;
    }
  }
  .trx_addons_tabs_content_delimiter {
    .trx_addons_cv_resume_columns .trx_addons_cv_resume_item, .trx_addons_cv_resume_columns_2 .trx_addons_cv_resume_column:nth-child(2n+2) .trx_addons_cv_resume_item {
      border-color: #464646;
    }
    .trx_addons_cv_resume_columns_3 .trx_addons_cv_resume_column {
      &:nth-child(3n+2) .trx_addons_cv_resume_item, &:nth-child(3n+3) .trx_addons_cv_resume_item {
        border-color: #464646;
      }
    }
    .trx_addons_cv_resume_columns_4 .trx_addons_cv_resume_column {
      &:nth-child(4n+2) .trx_addons_cv_resume_item, &:nth-child(4n+3) .trx_addons_cv_resume_item, &:nth-child(4n+4) .trx_addons_cv_resume_item {
        border-color: #464646;
      }
    }
    .trx_addons_cv_resume_columns_2 .trx_addons_cv_resume_column {
      &:nth-child(2n+3) .trx_addons_cv_resume_item, &:nth-child(2n+4) .trx_addons_cv_resume_item {
        border-color: #464646;
      }
    }
    .trx_addons_cv_resume_columns_3 .trx_addons_cv_resume_column {
      &:nth-child(3n+4) .trx_addons_cv_resume_item, &:nth-child(3n+5) .trx_addons_cv_resume_item, &:nth-child(3n+6) .trx_addons_cv_resume_item {
        border-color: #464646;
      }
    }
    .trx_addons_cv_resume_columns_4 .trx_addons_cv_resume_column {
      &:nth-child(4n+5) .trx_addons_cv_resume_item, &:nth-child(4n+6) .trx_addons_cv_resume_item, &:nth-child(4n+7) .trx_addons_cv_resume_item, &:nth-child(4n+8) .trx_addons_cv_resume_item {
        border-color: #464646;
      }
    }
  }
  .trx_addons_cv_resume_item_meta {
    color: #fff;
  }
  .trx_addons_cv_resume_item .trx_addons_cv_resume_item_title {
    color: #fff;
    a {
      color: #fff;
      &:hover {
        color: $line3;
      }
    }
  }
  .trx_addons_cv_resume_item_subtitle {
    color: #5f5f5f;
  }
  .trx_addons_cv_resume_style_skills {
    .trx_addons_cv_resume_item_skills {
      color: #fff;
    }
    .trx_addons_cv_resume_item_skill:after {
      border-color: #fff;
    }
  }
  .trx_addons_cv_resume_style_education .trx_addons_cv_resume_item_number {
    color: #5f5f5f;
  }
  .trx_addons_cv_resume_style_services {
    .trx_addons_cv_resume_item_icon {
      color: #fff;
      &:hover {
        color: $line3;
      }
    }
    .trx_addons_cv_resume_item_text a:hover {
      color: $line3;
    }
    .trx_addons_cv_resume_item_title > a {
      &:hover:after {
        border-color: $line3;
      }
      &:after {
        border-top-color: #fff;
      }
    }
    .trx_addons_cv_resume_item_text a {
      color: #fff;
    }
  }
  .trx_addons_cv_portfolio_item .trx_addons_cv_portfolio_item_title, .trx_addons_cv_testimonials_item .trx_addons_cv_testimonials_item_title, .trx_addons_cv_certificates_item .trx_addons_cv_certificates_item_title {
    color: #fff;
    a {
      color: #fff;
    }
  }
  .trx_addons_cv {
    .trx_addons_contact_form .trx_addons_contact_form_title, .trx_addons_contact_form_field_title {
      color: #fff;
    }
  }
  .trx_addons_contact_form .trx_addons_contact_form_field {
    input[type="text"], textarea {
      border-color: #464646;
      color: #919497;
    }
    input[type="text"]:focus, textarea:focus {
      background-color: #333;
      color: #fff;
    }
  }
  .trx_addons_contact_form_field button {
    background-color: #fff;
    border-color: #fff;
    color: #0e0d12;
    &:hover {
      color: #fff;
    }
  }
  .trx_addons_contact_form_info_icon {
    color: #5f5f5f;
  }
  .trx_addons_contact_form_info_area {
    color: #fff;
  }
  .trx_addons_contact_form_info_item_phone .trx_addons_contact_form_info_data {
    color: #fff !important;
  }
  .trx_addons_cv_about_page .trx_addons_cv_single_title {
    color: #fff;
  }
  .trx_addons_attrib_item {
    &.trx_addons_attrib_button, &.trx_addons_attrib_image, &.trx_addons_attrib_color {
      border-color: #464646;
      background-color: #2c3c4a;
    }
    &.trx_addons_attrib_button:hover, &.trx_addons_attrib_image:hover, &.trx_addons_attrib_color:hover {
      border-color: #4a4a4a;
      background-color: #333;
    }
    &.trx_addons_attrib_selected {
      border-color: $line3 !important;
      background-color: #333;
    }
    &.trx_addons_attrib_disabled span {
      &:before, &:after {
        background-color: #fe7259;
      }
    }
  }
  .trx_addons_range_slider_label_min, .trx_addons_range_slider_label_max {
    color: #919497;
  }
  div.ui-slider {
    background-color: #2c3c4a;
    border-color: #464646;
    .ui-slider-handle {
      border-color: #4a4a4a;
      background-color: #333;
    }
    .ui-slider-range {
      background-color: #333;
    }
  }
  .trx_addons_accent, .trx_addons_accent_big {
    color: #fff;
  }
  .trx_addons_accent > {
    a, * {
      color: #fff;
    }
  }
  .trx_addons_accent_hovered {
    color: $line3;
  }
  .trx_addons_accent_bg {
    background-color: $line2;
    color: #fff;
  }
  .trx_addons_tooltip {
    color: #fff;
    border-color: #fff;
    &:before {
      background-color: $line1;
      color: #fff;
    }
    &:after {
      border-top-color: $line1;
    }
  }
  .trx_addons_dropcap_style_1 {
    background: $line3;
    color: #fff;
  }
  .trx_addons_dropcap_style_2 {
    background: $line2;
    color: #fff;
  }
  blockquote {
    color: rgba(255, 255, 255, 0.8);
    > {
      a, p > a, cite, p > cite {
        color: #fff;
      }
      a, p > a:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  figure figcaption {
    color: #fff;
    background-color: rgba(84, 185, 253, 0.85);
  }
  .wp-caption {
    .wp-caption-text, .wp-caption-dd {
      color: #fff;
      background-color: rgba(84, 185, 253, 0.85);
    }
  }
  .wp-caption-overlay .wp-caption {
    .wp-caption-text, .wp-caption-dd {
      color: #fff;
      background-color: rgba(84, 185, 253, 0.85);
    }
  }
  ul {
    &[class*="trx_addons_list_custom"] > li:before, &[class*="trx_addons_list"] > li:before {
      color: $line3;
    }
    &[class*="trx_addons_list_dot"] > li:before {
      color: $line1;
    }
  }
  .sc_layouts_menu_nav > li {
    > a {
      color: #fff !important;
      &:hover {
        color: $line3 !important;
      }
    }
    &.sfHover > a, &.current-menu-item > a, &.current-menu-parent > a, &.current-menu-ancestor > a {
      color: $line3 !important;
    }
    ul {
      background-color: #fff;
      border-bottom-color: $line2 !important;
    }
  }
  .sc_layouts_menu_popup .sc_layouts_menu_nav > li > a, .sc_layouts_menu_nav > li li > a {
    color: #fff !important;
  }
  .sc_layouts_menu_popup .sc_layouts_menu_nav > li {
    > a:hover, &.sfHover > a {
      color: $line3 !important;
      background-color: rgba(14, 13, 18, 0);
    }
  }
  .sc_layouts_menu_nav > li li {
    > a:hover, &.sfHover > a, &.current-menu-item > a, &.current-menu-parent > a, &.current-menu-ancestor > a {
      color: $line3 !important;
      background-color: rgba(14, 13, 18, 0);
    }
  }
  .sc_layouts_title_caption {
    color: #fff;
  }
  .sc_layouts_title_breadcrumbs a, .breadcrumbs_item.current {
    color: #fff !important;
  }
  .sc_layouts_title_breadcrumbs a:hover {
    color: $line2 !important;
  }
  .sc_slider_controls .slider_controls_wrap > a, .slider_container.slider_controls_side .slider_controls_wrap > a, .slider_outer_controls_side .slider_controls_wrap > a {
    color: #fff;
    background-color: #2c3c4a;
  }
  .sc_slider_controls .slider_controls_wrap > a:hover, .slider_container.slider_controls_side .slider_controls_wrap > a:hover, .slider_outer_controls_side .slider_controls_wrap > a:hover {
    color: #fff;
    background-color: #fff;
  }
  .sc_price .trx_addons_columns_wrap > div:last-child .sc_price_item {
    color: #787f86;
    background-color: #0e0d12;
    border-color: #2e2c33;
  }
  .sc_price_item {
    color: #787f86;
    background-color: #0e0d12;
    border-color: #2e2c33;
    &:hover {
      color: #787f86;
      background-color: #0e0d12;
      border-color: #2e2c33;
    }
    .sc_price_item_icon {
      color: $line2;
    }
    &:hover .sc_price_item_icon {
      color: $line3;
    }
    .sc_price_item_label, &:hover .sc_price_item_label {
      background-color: $line2;
      color: #fff;
    }
    .sc_price_item_subtitle {
      color: #fff;
    }
    .sc_price_item_title, &:hover .sc_price_item_title {
      color: #fff;
      a {
        color: #fff;
      }
    }
    .sc_price_item_price {
      color: $line3;
    }
    .sc_price_item_description, .sc_price_item_details {
      color: #787f86;
    }
  }
  .price-header:after {
    background: $line3;
  }
  .sc_price .trx_addons_columns_wrap > div {
    &:nth-child(3n+2) .sc_price_item .sc_price_item_link {
      color: #fff;
    }
    &:nth-child(3n+1) .sc_price_item .sc_price_item_link {
      color: #fff;
      &:hover {
        color: #fff !important;
      }
    }
    &:nth-child(3n+2) .sc_price_item .price-header:after {
      background: $line1;
    }
    &:nth-child(3n+3) .sc_price_item .price-header:after {
      background: $line2;
    }
    &:nth-child(3n+2) .sc_price_item .sc_price_item_price {
      color: $line1;
    }
    &:nth-child(3n+3) .sc_price_item .sc_price_item_price {
      color: #fff;
    }
  }
  .author_info:after, .sc_content_bordered:after {
    background: linear-gradient(to bottom, $line1 0%, $line1 33.33%, $line2 33.33%, $line2 66.66%, $line3 66.66%, $line3 100%);
  }
  .sc_layouts_logo .logo_text, .trx_addons_scroll_to_top {
    color: #fff;
  }
  .sc_layouts_row_type_normal .sc_layouts_item a:hover .sc_layouts_item_icon, .sc_layouts_item_icon, .sc_layouts_item_details_line1 {
    color: $line3;
  }
  .sc_layouts_row_type_normal {
    .sc_layouts_item a:hover .sc_layouts_item_details_line2 {
      color: $line3;
    }
    .sc_layouts_column .search_style_fullscreen:not(.search_opened) .search_submit {
      background: #fff;
      &:hover {
        background: $line3;
      }
      &:before {
        color: #fff;
      }
    }
  }
  .sc_skills_pie.sc_skills_compact_off {
    .sc_skills_total {
      color: #fff;
    }
    .sc_skills_item_title {
      color: #787f86;
    }
  }
  .format-video .post_featured.with_thumb .mask, .trx_addons_video_player.with_cover .video_mask {
    background: linear-gradient(to top, rgba(255, 255, 255, 0.7) 0%, rgba(14, 13, 18, 0) 50%, rgba(14, 13, 18, 0) 100%);
  }
  .format-audio .post_featured.without_thumb .post_audio {
    background: #2c3c4a;
  }
  .mejs-controls {
    .mejs-horizontal-volume-slider .mejs-horizontal-volume-current, .mejs-time-rail .mejs-time-current {
      background: $line3;
    }
    .mejs-button {
      background: rgba(14, 13, 18, 0);
      color: $line3;
      &:hover {
        background: rgba(14, 13, 18, 0);
        color: #fff;
      }
    }
  }
  .trx_addons_audio_player .mejs-controls {
    .mejs-horizontal-volume-slider .mejs-horizontal-volume-total:before, .mejs-time-rail .mejs-time-total:before {
      background: #2e2c33;
    }
  }
  .mejs-controls .mejs-time-rail {
    .mejs-time-total, .mejs-time-loaded {
      background: #2e2c33;
    }
  }
  .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
    background: #2e2c33;
  }
  .without_thumb .mejs-controls {
    .mejs-currenttime, .mejs-duration {
      color: #787f86;
    }
  }
  .trx_addons_audio_player.without_cover .audio_author, .format-audio .post_featured .post_audio_author, .trx_addons_audio_player .mejs-container .mejs-controls .mejs-time {
    color: #787f86;
  }
  .trx_addons_label_text {
    color: #fff;
  }
  .sc_content_bordered a {
    color: $line3;
    &:hover {
      color: #fff;
    }
  }
  .post_item_single {
    &.post_type_post.post_format_audio .mejs-controls .mejs-button:hover {
      color: #fff;
    }
    .post_content .post_share .socials_wrap .social_item {
      &:nth-child(4n+1) .social_icon {
        color: $line3;
      }
      &:nth-child(4n+2) .social_icon {
        color: $line1;
      }
      &:nth-child(4n+3) .social_icon {
        color: $line2;
      }
      &:nth-child(4n+4) .social_icon {
        color: #fff;
      }
      &:hover .social_icon {
        color: #787f86 !important;
      }
    }
  }
  .comments_list_wrap .comment_info {
    color: #919497;
  }
  .comment_author {
    color: $line3;
  }
  .comments_list_wrap .comment_author:after {
    color: #919497;
  }
  .sc_item_subtitle, .sc_services_light .sc_services_item_title a:hover, .single-cpt_services ul[class*="trx_addons_list_dot"] > li:before {
    color: $line3;
  }
  .gyges .tp-tab {
    background-color: rgba(255, 255, 255, 0.3);
    &:hover, &.selected {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
  .sc_team_short {
    .sc_team_item_title a {
      color: #787f86;
      &:hover {
        color: #fff;
      }
    }
    .sc_team_item_subtitle {
      color: $line3;
    }
    .sc_team_columns_wrap > div {
      &:nth-child(3n+2) .sc_team_item_subtitle {
        color: $line1;
      }
      &:nth-child(3n+3) .sc_team_item_subtitle {
        color: #fff;
      }
    }
    .sc_team_item_thumb {
      border-color: $line3;
    }
    .sc_team_columns_wrap > div {
      &:nth-child(3n+2) .sc_team_item_thumb {
        border-color: $line1;
      }
      &:nth-child(3n+3) .sc_team_item_thumb {
        border-color: $line2;
      }
    }
    .trx_addons_hover_mask {
      background-color: rgba(14, 13, 18, 0.7);
    }
  }
  .scheme_dark {
    .sc_item_subtitle {
      color: $line2;
    }
    .sc_testimonials_item_content {
      color: rgba(14, 13, 18, 0.4);
    }
  }
  .sc_testimonials_item_author_title {
    color: #fff;
  }
  .slider_container {
    &.slider_controls_top .slider_controls_wrap > a, &.slider_controls_bottom .slider_controls_wrap > a {
      color: #000;
      background-color: $line2;
      border-color: $line2;
    }
  }
  .slider_outer_controls_top .slider_controls_wrap > a, .slider_outer_controls_bottom .slider_controls_wrap > a {
    color: $linea;
    background-color: $line2;
    border-color: $line2;
  }
  .slider_container {
    &.slider_controls_top .slider_controls_wrap > a:hover, &.slider_controls_bottom .slider_controls_wrap > a:hover {
      color: #fff;
      border-color: $line3;
      background-color: $line3;
    }
  }
  .slider_outer_controls_top .slider_controls_wrap > a:hover, .slider_outer_controls_bottom .slider_controls_wrap > a:hover {
    color: #fff;
    border-color: $line3;
    background-color: $line3;
  }
  .sc_content_tbordered:after {
    background: linear-gradient(to right, $line3 0%, $line3 17%, #fff 17%, #fff 34%, $line1 34%, $line1 50.5%, $line2 50.5%, $line2 67%, $line3 67%, $line3 83.5%, $line1 83.5%, $line1 100%);
  }
  .bullet-bar .tp-bullet {
    &:hover, &.selected {
      background-color: rgba(14, 13, 18, 0.7);
    }
    background-color: rgba(14, 13, 18, 0.3);
  }
  .sc_services_default {
    .sc_services_item {
      background-color: #0e0d12;
      &:hover {
        background-color: #2c3c4a;
      }
    }
    .sc_services_columns_wrap > div {
      + div {
        border-color: #2c3c4a;
      }
      &:nth-child(6n+1) .sc_services_item:after {
        background-color: $line3;
      }
      &:nth-child(6n+2) .sc_services_item:after {
        background-color: #fff;
      }
      &:nth-child(6n+3) .sc_services_item:after {
        background-color: $line1;
      }
      &:nth-child(6n+4) .sc_services_item:after {
        background-color: $line2;
      }
      &:nth-child(6n+5) .sc_services_item:after {
        background-color: $line3;
      }
      &:nth-child(6n+6) .sc_services_item:after {
        background-color: $line1;
      }
    }
  }
  .sc_button.sc_button_size_large.sc_button_simple:not(.sc_button_bg_image) {
    color: #fff;
    color: #fff;
    &:hover {
      color: $line3 !important;
    }
  }
  .scheme_dark {
    .sc_layouts_item_icon, .logo_slogan {
      color: #fff;
    }
  }
  .menu_mobile .search_mobile .search_field {
    border-color: rgba(14, 13, 18, 0.4);
  }
  .team_member_page .team_member_socials .social_item {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .mc4wp-form {
    input[type="email"] {
      background-color: #2c3c4a;
      border-color: #2c3c4a;
      color: #969ea5;
    }
    .mc4wp-alert {
      background-color: $line2;
      border-color: $line3;
      color: #1d1d1d;
    }
  }
  .woocommerce {
    .woocommerce-breadcrumb {
      color: #787f86;
      a {
        color: $line2;
        &:hover {
          color: $line3;
        }
      }
    }
    .widget_price_filter .ui-slider {
      .ui-slider-range {
        background-color: $line3;
      }
      .ui-slider-handle {
        background-color: #0e0d12;
        border-color: $line3;
      }
    }
    .woocommerce-ordering select {
      border-color: #2e2c33;
    }
    span.onsale {
      color: #fff;
      background-color: $line2;
    }
    ul.products li.product {
      .post_header {
        a {
          color: #fff;
          &:hover {
            color: $line3;
          }
        }
        .post_tags {
          color: $line3;
          a {
            color: $line3;
            &:hover {
              color: #fe7259;
            }
          }
        }
      }
      .price {
        color: #787f86;
      }
    }
  }
  .woocommerce-page ul.products li.product .price, .woocommerce ul.products li.product .price ins, .woocommerce-page ul.products li.product .price ins {
    color: #787f86;
  }
  .woocommerce ul.products li.product .price del, .woocommerce-page ul.products li.product .price del {
    color: #5f5f5f;
  }
  .woocommerce {
    div.product form.cart .variations {
      td {
        border-color: #2e2c33;
        + td {
          border-color: #2e2c33;
        }
      }
      border-color: #2e2c33;
    }
    &.widget_shopping_cart ul.cart_list li {
      border-color: #2e2c33;
    }
    div.product {
      p.price span.amount, span.price span.amount, p.price, span.price {
        color: $line3;
      }
    }
    span.amount {
      color: #787f86;
    }
  }
  .woocommerce-page span.amount {
    color: #787f86;
  }
  .woocommerce table.shop_table td span.amount {
    color: #fff;
  }
  aside.woocommerce del {
    color: #919497 !important;
  }
  .woocommerce del, .woocommerce-page del {
    color: #919497 !important;
    > span.amount {
      color: #919497 !important;
    }
  }
  .woocommerce {
    &.widget_shopping_cart .total .amount, .widget_shopping_cart .total .amount {
      color: $line3;
    }
  }
  .woocommerce-page {
    &.widget_shopping_cart .total .amount, .widget_shopping_cart .total .amount {
      color: $line3;
    }
  }
  .woocommerce {
    .price del:before {
      background-color: #919497;
    }
    div.product form.cart div.quantity span {
      color: #fff;
      background-color: #2c3c4a;
    }
  }
  .woocommerce-page div.product form.cart div.quantity span, .woocommerce .shop_table.cart div.quantity span, .woocommerce-page .shop_table.cart div.quantity span {
    color: #fff;
    background-color: #2c3c4a;
  }
  .woocommerce div.product form.cart div.quantity span:hover, .woocommerce-page div.product form.cart div.quantity span:hover, .woocommerce .shop_table.cart div.quantity span:hover, .woocommerce-page .shop_table.cart div.quantity span:hover {
    color: $line3;
    background-color: #2c3c4a;
  }
  .woocommerce div.product form.cart div.quantity input[type="number"], .woocommerce-page div.product form.cart div.quantity input[type="number"], .woocommerce .shop_table.cart input[type="number"], .woocommerce-page .shop_table.cart div.quantity input[type="number"] {
    background-color: #2c3c4a;
  }
  .woocommerce div.product {
    .product_meta {
      span > {
        a, span {
          color: #787f86;
        }
      }
      a:hover {
        color: $line3;
      }
    }
    div.images {
      .flex-viewport, img {
        border-color: #2e2c33;
      }
      a:hover img {
        border-color: $line2;
      }
    }
  }
  .single-product div.product .trx-stretch-width .woocommerce-tabs {
    border-color: #2c3c4a;
    .wc-tabs {
      background-color: #2c3c4a;
    }
  }
  .woocommerce {
    div.product .woocommerce-tabs .panel, #content div.product .woocommerce-tabs .panel {
      border-color: #2e2c33;
    }
  }
  .woocommerce-page {
    div.product .woocommerce-tabs .panel, #content div.product .woocommerce-tabs .panel {
      border-color: #2e2c33;
    }
  }
  .single-product div.product {
    .woocommerce-tabs .wc-tabs li {
      a {
        color: #fff;
        background-color: #2c3c4a;
      }
      &.active a {
        color: #fff;
        background-color: #0e0d12;
      }
    }
    .trx-stretch-width .woocommerce-tabs .wc-tabs li:before {
      background-color: #2e2c33;
    }
    .woocommerce-tabs {
      .wc-tabs li:not(.active) a:hover {
        color: #fff;
        background-color: #0e0d12;
      }
      .panel {
        color: #787f86;
      }
    }
  }
  .woocommerce table.shop_attributes {
    tr {
      &:nth-child(2n+1) > * {
        background-color: rgba(44, 60, 74, 0.4);
      }
      &:nth-child(2n) > *, &.alt > * {
        background-color: rgba(44, 60, 74, 0.2);
      }
    }
    th {
      color: #fff;
    }
  }
  .single-product .related {
    border-color: #2e2c33;
  }
  .star-rating {
    span, &:before {
      color: $line2;
    }
  }
  .alpha_color_shop_mode_buttons a {
    color: #2c3c4a;
    &:hover {
      color: $line3;
    }
  }
  .shop_mode_thumbs .alpha_color_shop_mode_buttons a.woocommerce_thumbs, .shop_mode_list .alpha_color_shop_mode_buttons a.woocommerce_list {
    color: $line3;
  }
  .woocommerce {
    .woocommerce-message, .woocommerce-info {
      background-color: #2c3c4a;
      border-top-color: #fff;
    }
    .woocommerce-error {
      background-color: #2c3c4a;
      border-top-color: $line3;
    }
    .woocommerce-message:before, .woocommerce-info:before {
      color: #fff;
    }
    .woocommerce-error:before {
      color: $line3;
    }
    table.shop_table {
      td {
        border-color: #464646 !important;
        background-color: #0e0d12;
      }
      th {
        border-color: rgba(70, 70, 70, 0.2) !important;
      }
      tfoot th {
        color: #fff;
        border-color: transparent !important;
        background-color: transparent;
      }
    }
  }
  .woocommerce-page table.shop_table tfoot th {
    color: #fff;
    border-color: transparent !important;
    background-color: transparent;
  }
  .woocommerce {
    .quantity input.qty, #content .quantity input.qty {
      color: #fff;
    }
  }
  .woocommerce-page {
    .quantity input.qty, #content .quantity input.qty {
      color: #fff;
    }
  }
  .woocommerce .cart-collaterals .cart_totals table select, .woocommerce-page .cart-collaterals .cart_totals table select {
    color: #969ea5;
    background-color: #2c3c4a;
  }
  .woocommerce .cart-collaterals .cart_totals table select:focus, .woocommerce-page .cart-collaterals .cart_totals table select:focus {
    color: #fff;
    background-color: #2e2d32;
  }
  .woocommerce .cart-collaterals .shipping_calculator .shipping-calculator-button:after, .woocommerce-page .cart-collaterals .shipping_calculator .shipping-calculator-button:after, .woocommerce table.shop_table .cart-subtotal .amount, .woocommerce-page table.shop_table .cart-subtotal .amount, .woocommerce table.shop_table .shipping td, .woocommerce-page table.shop_table .shipping td {
    color: #fff;
  }
  .woocommerce {
    table.cart td + td a, #content table.cart td + td a {
      color: #fff;
    }
  }
  .woocommerce-page {
    table.cart td + td a, #content table.cart td + td a {
      color: #fff;
    }
  }
  .woocommerce {
    table.cart td + td span, #content table.cart td + td span {
      color: #fff;
    }
  }
  .woocommerce-page {
    table.cart td + td span, #content table.cart td + td span {
      color: #fff;
    }
  }
  .woocommerce {
    table.cart td + td a:hover, #content table.cart td + td a:hover {
      color: $line2;
    }
  }
  .woocommerce-page {
    table.cart td + td a:hover, #content table.cart td + td a:hover {
      color: $line2;
    }
  }
  #add_payment_method table.cart td.actions .coupon .input-text, .woocommerce-cart table.cart td.actions .coupon .input-text, .woocommerce-checkout table.cart td.actions .coupon .input-text {
    border-color: #2e2d32;
  }
  #add_payment_method #payment ul.payment_methods, .woocommerce-cart #payment ul.payment_methods, .woocommerce-checkout #payment ul.payment_methods {
    border-color: #2e2c33;
  }
  #add_payment_method #payment div.payment_box, .woocommerce-cart #payment div.payment_box, .woocommerce-checkout #payment div.payment_box {
    color: #fff;
    background-color: #2e2d32;
  }
  #add_payment_method #payment div.payment_box:before, .woocommerce-cart #payment div.payment_box:before, .woocommerce-checkout #payment div.payment_box:before {
    border-color: transparent transparent #2e2d32;
  }
  .woocommerce .order_details li strong, .woocommerce-page .order_details li strong {
    color: #fff;
  }
  .woocommerce .order_details.woocommerce-thankyou-order-details {
    color: #919497;
    background-color: #2c3c4a;
    strong {
      color: #fff;
    }
  }
  .woocommerce-account .woocommerce-MyAccount-navigation {
    border-color: #2e2c33;
  }
  .woocommerce-MyAccount-navigation {
    ul li {
      border-color: #2e2c33;
    }
    li {
      + li {
        border-color: #2e2c33;
      }
      &.is-active a {
        color: $line2;
      }
    }
  }
  .woocommerce-MyAccount-content .my_account_orders .button {
    color: $line2;
    &:hover {
      color: $line3;
    }
  }
  .widget_product_search form {
    &:after {
      color: #969ea5;
    }
    &:hover:after {
      color: #fff;
    }
  }
  .widget_shopping_cart .total {
    color: #fff;
    border-color: #2e2c33;
  }
  .woocommerce ul.cart_list li dl, .woocommerce-page ul.cart_list li dl, .woocommerce ul.product_list_widget li dl, .woocommerce-page ul.product_list_widget li dl {
    border-color: #2e2c33;
  }
  .widget_layered_nav ul li.chosen a {
    color: #fff;
  }
  .widget_price_filter {
    .price_slider_wrapper .ui-widget-content {
      background: #919497;
    }
    .price_label span {
      color: #787f86;
    }
  }
  .woocommerce .shop_mode_list ul.products li.product .price, .woocommerce-page .shop_mode_list ul.products li.product .price {
    color: $line3;
  }
  .yith_magnifier_zoom_wrap .yith_magnifier_zoom_magnifier {
    border-color: #2e2c33;
  }
  .yith-woocompare-widget a.clear-all {
    color: #fff;
    background-color: $line3;
    &:hover {
      color: #1d1d1d;
      background-color: #fe7259;
    }
  }
  .widget.WOOCS_SELECTOR .woocommerce-currency-switcher-form {
    .chosen-container-single .chosen-single {
      color: #969ea5;
      background: #2c3c4a;
      &:hover {
        color: #fff;
        background: #2e2d32;
      }
    }
    .chosen-container {
      .chosen-drop {
        color: #fff;
        background: #2e2d32;
        border-color: #353535;
      }
      .chosen-results li {
        color: #fff;
        &:hover, &.highlighted, &.result-selected {
          color: $line3 !important;
        }
      }
    }
  }
  .woocommerce ul.products li.product .post_data:before {
    background: linear-gradient(to right, $line1 0%, $line1 33.33%, $line2 33.33%, $line2 66.66%, $line3 66.66%, $line3 100%);
  }
  &.vc_section, &.wpb_row, &.wpb_column > .vc_column-inner > .wpb_wrapper, &.wpb_text_column {
    color: #787f86;
  }
  .wpb-js-composer {
    .vc_tta.vc_tta-accordion .vc_tta-panel-heading .vc_tta-controls-icon {
      color: $line3;
    }
    .vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel {
      .vc_tta-panel-title > a {
        color: #fff;
      }
      &.vc_active .vc_tta-panel-title > a, .vc_tta-panel-title > a:hover {
        color: $line3;
      }
    }
  }
  .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tabs-list .vc_tta-tab {
    > a {
      color: #fff;
      background-color: #2c3c4a;
      &:hover {
        color: #fff;
        background-color: #0e0d12;
      }
    }
    &.vc_active > a {
      color: #fff;
      background-color: #0e0d12;
    }
  }
  .vc_general.vc_tta.vc_tta-tabs.vc_tta-color-grey.vc_tta-style-classic.vc_tta-shape-square {
    border-color: #2c3c4a;
  }
  .wpb-js-composer .vc_tta-tab:not(.vc_active) + .vc_tta-tab:before {
    background-color: #2e2c33;
  }
  .vc_separator.vc_sep_color_grey .vc_sep_line {
    border-color: #2e2c33;
  }
  .vc_progress_bar.vc_progress_bar_narrow {
    .vc_single_bar {
      background-color: #2c3c4a;
    }
    &.vc_progress-bar-color-bar_red .vc_single_bar .vc_bar {
      background-color: $line3;
    }
    .vc_single_bar .vc_label {
      color: #fff;
      .vc_label_units {
        color: #919497;
      }
    }
  }
  .sc_button_hover_slide_left {
    background: linear-gradient(to right, $line3 50%, $line2 50%) no-repeat scroll right (bottom / 210%) 100% rgba(14, 13, 18, 0) !important;
  }
  .sc_button_hover_slide_right {
    background: linear-gradient(to left, $line3 50%, $line2 50%) no-repeat scroll left (bottom / 210%) 100% rgba(14, 13, 18, 0) !important;
  }
  .sc_button_hover_slide_top {
    background: linear-gradient(to bottom, $line3 50%, $line2 50%) no-repeat scroll right (bottom / 100%) 210% rgba(14, 13, 18, 0) !important;
  }
  .sc_button_hover_slide_bottom {
    background: linear-gradient(to top, $line3 50%, $line2 50%) no-repeat scroll right (top / 100%) 210% rgba(14, 13, 18, 0) !important;
  }
  .sc_button_hover_style_link2 {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, #8be77c 50%, $line1 50%) no-repeat scroll right (bottom / 210%) 100% $line1 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, #8be77c 50%, $line1 50%) no-repeat scroll left (bottom / 210%) 100% $line1 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, #8be77c 50%, $line1 50%) no-repeat scroll right (bottom / 100%) 210% $line1 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, #8be77c 50%, $line1 50%) no-repeat scroll right (top / 100%) 210% $line1 !important;
    }
  }
  .sc_button_hover_style_link3 {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, $line2 50%, $line3 50%) no-repeat scroll right (bottom / 210%) 100% rgba(14, 13, 18, 0) !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, $line2 50%, $line3 50%) no-repeat scroll left (bottom / 210%) 100% rgba(14, 13, 18, 0) !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, $line2 50%, $line3 50%) no-repeat scroll right (bottom / 100%) 210% rgba(14, 13, 18, 0) !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, $line2 50%, $line3 50%) no-repeat scroll right (top / 100%) 210% rgba(14, 13, 18, 0) !important;
    }
  }
  .sc_button_hover_style_dark {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, $line2 50%, #fff 50%) no-repeat scroll right (bottom / 210%) 100% #fff !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, $line2 50%, #fff 50%) no-repeat scroll left (bottom / 210%) 100% #fff !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, $line2 50%, #fff 50%) no-repeat scroll right (bottom / 100%) 210% #fff !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, $line2 50%, #fff 50%) no-repeat scroll right (top / 100%) 210% #fff !important;
    }
  }
  .sc_button_hover_style_inverse {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, #fff 50%, $line2 50%) no-repeat scroll right (bottom / 210%) 100% $line2 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, #fff 50%, $line2 50%) no-repeat scroll left (bottom / 210%) 100% $line2 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, #fff 50%, $line2 50%) no-repeat scroll right (bottom / 100%) 210% $line2 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, #fff 50%, $line2 50%) no-repeat scroll right (top / 100%) 210% $line2 !important;
    }
  }
  .sc_button_hover_style_hover {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, $line3 50%, $line2 50%) no-repeat scroll right (bottom / 210%) 100% $line2 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, $line3 50%, $line2 50%) no-repeat scroll left (bottom / 210%) 100% $line2 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, $line3 50%, $line2 50%) no-repeat scroll right (bottom / 100%) 210% $line2 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, $line3 50%, $line2 50%) no-repeat scroll right (top / 100%) 210% $line2 !important;
    }
  }
  .sc_button_hover_style_alter {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, #fff 50%, $line3 50%) no-repeat scroll right (bottom / 210%) 100% $line3 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, #fff 50%, $line3 50%) no-repeat scroll left (bottom / 210%) 100% $line3 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, #fff 50%, $line3 50%) no-repeat scroll right (bottom / 100%) 210% $line3 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, #fff 50%, $line3 50%) no-repeat scroll right (top / 100%) 210% $line3 !important;
    }
  }
  .sc_button_hover_style_alterbd {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, $line3 50%, #464646 50%) no-repeat scroll right (bottom / 210%) 100% #464646 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, $line3 50%, #464646 50%) no-repeat scroll left (bottom / 210%) 100% #464646 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, $line3 50%, #464646 50%) no-repeat scroll right (bottom / 100%) 210% #464646 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, $line3 50%, #464646 50%) no-repeat scroll right (top / 100%) 210% #464646 !important;
    }
  }
  .sc_button_hover_style_extra {
    &.sc_button_hover_slide_left {
      background: linear-gradient(to right, #ffaa5f 50%, #1e1d22 50%) no-repeat scroll right (bottom / 210%) 100% #1e1d22 !important;
    }
    &.sc_button_hover_slide_right {
      background: linear-gradient(to left, #ffaa5f 50%, #1e1d22 50%) no-repeat scroll left (bottom / 210%) 100% #1e1d22 !important;
    }
    &.sc_button_hover_slide_top {
      background: linear-gradient(to bottom, #ffaa5f 50%, #1e1d22 50%) no-repeat scroll right (bottom / 100%) 210% #1e1d22 !important;
    }
    &.sc_button_hover_slide_bottom {
      background: linear-gradient(to top, #ffaa5f 50%, #1e1d22 50%) no-repeat scroll right (top / 100%) 210% #1e1d22 !important;
    }
  }
  .sc_button_hover_style_alter {
    &.sc_button_hover_slide_left:hover, &.sc_button_hover_slide_right:hover, &.sc_button_hover_slide_top:hover, &.sc_button_hover_slide_bottom:hover {
      color: #0e0d12 !important;
    }
  }
  .sc_button_hover_style_extra {
    &.sc_button_hover_slide_left:hover, &.sc_button_hover_slide_right:hover, &.sc_button_hover_slide_top:hover, &.sc_button_hover_slide_bottom:hover {
      color: #fff !important;
    }
  }
  .sc_button_hover_slide_left {
    &:hover, &.active {
      background-position: left bottom !important;
      color: #fff !important;
    }
  }
  .ui-state-active .sc_button_hover_slide_left, .vc_active .sc_button_hover_slide_left, .vc_tta-accordion .vc_tta-panel-title:hover .sc_button_hover_slide_left, li.active .sc_button_hover_slide_left {
    background-position: left bottom !important;
    color: #fff !important;
  }
  .sc_button_hover_slide_right {
    &:hover, &.active {
      background-position: right bottom !important;
      color: #0e0d12 !important;
    }
  }
  .ui-state-active .sc_button_hover_slide_right, .vc_active .sc_button_hover_slide_right, .vc_tta-accordion .vc_tta-panel-title:hover .sc_button_hover_slide_right, li.active .sc_button_hover_slide_right {
    background-position: right bottom !important;
    color: #0e0d12 !important;
  }
  .sc_button_hover_slide_top {
    &:hover, &.active {
      background-position: right top !important;
      color: #0e0d12 !important;
    }
  }
  .ui-state-active .sc_button_hover_slide_top, .vc_active .sc_button_hover_slide_top, .vc_tta-accordion .vc_tta-panel-title:hover .sc_button_hover_slide_top, li.active .sc_button_hover_slide_top {
    background-position: right top !important;
    color: #0e0d12 !important;
  }
  .sc_button_hover_slide_bottom {
    &:hover, &.active {
      background-position: right bottom !important;
      color: #0e0d12 !important;
    }
  }
  .ui-state-active .sc_button_hover_slide_bottom, .vc_active .sc_button_hover_slide_bottom, .vc_tta-accordion .vc_tta-panel-title:hover .sc_button_hover_slide_bottom, li.active .sc_button_hover_slide_bottom {
    background-position: right bottom !important;
    color: #0e0d12 !important;
  }
  .post_featured {
    .mask {
      background-color: rgba(255, 255, 255, 0.7);
    }
    &.hover_dots {
      &:hover .mask {
        background-color: rgba(255, 255, 255, 0.7);
      }
      .icons span {
        background-color: $line2;
      }
      .post_info {
        color: #0e0d12;
      }
    }
    &.hover_icon, &.hover_icons {
      .icons a {
        color: #fff;
        background-color: $line2;
      }
      a:hover {
        color: #fff;
        background-color: $line3;
      }
    }
    &.hover_fade .post_info {
      color: #fff;
      a, .post_meta_item {
        color: #fff;
      }
      .post_meta .post_meta_item {
        &:before, &:hover:before {
          color: #fff;
        }
      }
      a:hover {
        color: $line2;
      }
    }
    &.hover_slide .post_info {
      color: #fff;
      a, .post_meta_item {
        color: #fff;
      }
      .post_meta .post_meta_item {
        &:before, &:hover:before {
          color: #fff;
        }
      }
      a:hover {
        color: $line2;
      }
      .post_title:after {
        background-color: #fff;
      }
    }
    &.hover_pull {
      background-color: #1e1d22;
      .post_info {
        color: #fff;
        a {
          color: #fff;
          &:hover {
            color: #ffaa5f;
            &:before {
              color: #ffaa5f;
            }
          }
        }
      }
    }
    &.hover_border .post_info {
      color: #fff;
      a, .post_meta_item {
        color: #fff;
      }
      .post_meta .post_meta_item {
        &:before, &:hover:before {
          color: #fff;
        }
      }
      a:hover {
        color: $line2;
      }
      &:before, &:after {
        border-color: #fff;
      }
    }
    &.hover_shop .icons a {
      color: #fff;
      border-color: $line2 !important;
      background-color: $line2;
      &:hover {
        color: #fff;
        border-color: $line3 !important;
        background-color: $line3;
      }
    }
    &.hover_shop_buttons .icons {
      .shop_link {
        color: #0e0d12;
        background-color: #fff;
      }
      a:hover {
        color: #1d1d1d;
        background-color: $line3;
      }
    }
  }
}

h2.sc_item_title.sc_item_title_style_default:not(.sc_item_title_tag).sc_title_welcome {
  font-size: 2.424em;
  line-height: 1.27;
  @media screen and (max-width: 479px) {
    font-size: 1.524em;
    line-height: 1.27;
  }
}

.post_featured.hover_shop .icons a.shop_cart.product_featured:before {
  content: '\e839' !important;
}

.phonering-alo-phone {
  position: fixed;
  visibility: hidden;
  background-color: transparent;
  width: 200px;
  height: 200px;
  cursor: pointer;
  z-index: 200000 !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transition: visibility .5s;
  left: 0px !important;
  bottom: 21px !important;
  display: block;
  @media screen and (max-width: 768px) {
    left: -30px !important;
    bottom: -30px !important;
  }
}

.phonering-alo-phone.phonering-alo-show {
  visibility: visible
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0)
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight
}

.phonering-alo-phone.phonering-alo-static {
  opacity: .6
}

.phonering-alo-phone.phonering-alo-hover, .phonering-alo-phone:hover {
  opacity: 1
}

.phonering-alo-ph-circle {
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  position: absolute;
  background-color: transparent;
  border-radius: 100% !important;
  border: 2px solid rgba(30, 30, 30, 0.4);
  border: 2px solid #bfebfc 9;
  opacity: .1;
  -webkit-animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
  animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
  transition: all .5s;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.phonering-alo-phone.phonering-alo-active .phonering-alo-ph-circle {
  -webkit-animation: phonering-alo-circle-anim 1.1s infinite ease-in-out !important;
  animation: phonering-alo-circle-anim 1.1s infinite ease-in-out !important
}

.phonering-alo-phone.phonering-alo-static .phonering-alo-ph-circle {
  -webkit-animation: phonering-alo-circle-anim 2.2s infinite ease-in-out !important;
  animation: phonering-alo-circle-anim 2.2s infinite ease-in-out !important
}

.phonering-alo-phone.phonering-alo-hover .phonering-alo-ph-circle, .phonering-alo-phone:hover .phonering-alo-ph-circle {
  border-color: #00aff2;
  opacity: .5
}

.phonering-alo-phone.phonering-alo-green.phonering-alo-hover .phonering-alo-ph-circle, .phonering-alo-phone.phonering-alo-green:hover .phonering-alo-ph-circle {
  border-color: #75eb50;
  border-color: #baf5a7;
  opacity: .5
}

.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-circle {
  border-color: #e67e22;
  border-color: #e67e22;
  opacity: .5
}

.phonering-alo-phone.phonering-alo-gray.phonering-alo-hover .phonering-alo-ph-circle, .phonering-alo-phone.phonering-alo-gray:hover .phonering-alo-ph-circle {
  border-color: #ccc;
  opacity: .5
}

.phonering-alo-phone.phonering-alo-gray .phonering-alo-ph-circle {
  border-color: #75eb50;
  opacity: .5
}

.phonering-alo-ph-circle-fill {
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50px;
  position: absolute;
  background-color: #000;
  border-radius: 100% !important;
  border: 2px solid transparent;
  -webkit-animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
  transition: all .5s;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

.phonering-alo-phone.phonering-alo-active .phonering-alo-ph-circle-fill {
  -webkit-animation: phonering-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
  animation: phonering-alo-circle-fill-anim 1.7s infinite ease-in-out !important
}

.phonering-alo-phone.phonering-alo-static .phonering-alo-ph-circle-fill {
  -webkit-animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  opacity: 0 !important
}

.phonering-alo-phone.phonering-alo-hover .phonering-alo-ph-circle-fill, .phonering-alo-phone:hover .phonering-alo-ph-circle-fill {
  background-color: rgba(0, 175, 242, 0.5);
  background-color: #00aff2 9;
  opacity: .75 !important
}

.phonering-alo-phone.phonering-alo-green.phonering-alo-hover .phonering-alo-ph-circle-fill, .phonering-alo-phone.phonering-alo-green:hover .phonering-alo-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  background-color: #baf5a7 9;
  opacity: .75 !important
}

.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-circle-fill {
  background-color: #e67e22;
  background-color: #e67e22;
}

.phonering-alo-phone.phonering-alo-gray.phonering-alo-hover .phonering-alo-ph-circle-fill, .phonering-alo-phone.phonering-alo-gray:hover .phonering-alo-ph-circle-fill {
  background-color: rgba(204, 204, 204, 0.5);
  background-color: #ccc 9;
  opacity: .75 !important
}

.phonering-alo-phone.phonering-alo-gray .phonering-alo-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  opacity: .75 !important
}

.phonering-alo-ph-img-circle {
  width: 60px;
  height: 60px;
  top: 70px;
  left: 70px;
  position: absolute;
  background: rgba(30, 30, 30, 0.1) url("../img/phonering-icon.png") no-repeat center center;
  border-radius: 100% !important;
  border: 2px solid transparent;
  -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

.phonering-alo-phone.phonering-alo-active .phonering-alo-ph-img-circle {
  -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out !important;
  animation: phonering-alo-circle-img-anim 1s infinite ease-in-out !important
}

.phonering-alo-phone.phonering-alo-static .phonering-alo-ph-img-circle {
  -webkit-animation: phonering-alo-circle-img-anim 0 infinite ease-in-out !important;
  animation: phonering-alo-circle-img-anim 0 infinite ease-in-out !important
}

.phonering-alo-phone.phonering-alo-hover .phonering-alo-ph-img-circle, .phonering-alo-phone:hover .phonering-alo-ph-img-circle {
  background-color: #00aff2
}

.phonering-alo-phone.phonering-alo-green.phonering-alo-hover .phonering-alo-ph-img-circle, .phonering-alo-phone.phonering-alo-green:hover .phonering-alo-ph-img-circle {
  background-color: #75eb50;
  background-color: #75eb50 9
}

.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-img-circle {
  background-color: #e67e22;
  background-color: #e67e22
}

.phonering-alo-phone.phonering-alo-gray.phonering-alo-hover .phonering-alo-ph-img-circle, .phonering-alo-phone.phonering-alo-gray:hover .phonering-alo-ph-img-circle {
  background-color: #ccc
}

.phonering-alo-phone.phonering-alo-gray .phonering-alo-ph-img-circle {
  background-color: #75eb50
}

@-webkit-keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(.5) skew(1deg);
    -webkit-opacity: .1
  }

  30% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    -webkit-opacity: .5
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    -webkit-opacity: .1
  }
}

@-webkit-keyframes phonering-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    opacity: .2
  }

  100% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }
}

@-webkit-keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg)
  }

  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg)
  }

  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
  }

  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg)
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg)
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg)
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
  }
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
}

@-webkit-keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(.5) skew(1deg);
    transform: rotate(0) scale(.5) skew(1deg);
    opacity: .1
  }

  30% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    transform: rotate(0) scale(.7) skew(1deg);
    opacity: .5
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: .1
  }
}

@keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(.5) skew(1deg);
    transform: rotate(0) scale(.5) skew(1deg);
    opacity: .1
  }

  30% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    transform: rotate(0) scale(.7) skew(1deg);
    opacity: .5
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: .1
  }
}

@-webkit-keyframes phonering-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: .2
  }

  100% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }
}

@keyframes phonering-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
    opacity: .2
  }

  100% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }
}

@-webkit-keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg)
  }

  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg)
  }

  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg)
  }

  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg)
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg)
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg)
  }
}

@keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg)
  }

  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg)
  }

  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
    transform: rotate(-25deg) scale(1) skew(1deg)
  }

  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
    transform: rotate(25deg) scale(1) skew(1deg)
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg)
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg)
  }
}

.vc_page {
  background-image: url(../img/title_bg.jpg) !important;
  background-position: top left !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: #fff;
}

.content_wrap.blog {
  margin-bottom: 10.7rem;
}

.news-related,
.products-related {
  .sc_item_title {
    margin: 50px auto;
  }
}